<template>
	<div>
		<el-button v-if="showDetailedButton" type="primary" class="btn" v-on:click="detailedClick(scope.row)"
			>詳細
		</el-button>
		<el-button v-if="showEditButton" type="primary" class="btn" v-on:click="editClick(scope.row)">编辑</el-button>
		<el-button v-if="showDeleteButton" type="primary" class="btn" v-on:click="deleteClick(scope.row)">刪除</el-button>
		<el-button v-if="showAddButton" type="primary" class="btn" v-on:click="addClick(scope.row)">新增工單</el-button>
		<!--    <el-button type="primary" class="btn" v-on:click="editClick(scope.row)">编辑</el-button>-->
		<!--    <el-button type="primary" class="btn" v-on:click="deleteClick(scope.row)">刪除</el-button>-->
	</div>
</template>

<!-- 表格擴充欄位-->
<script>
export default {
	name: 'Expand2',
	props: {
		scope: Object,
		inDetailedButton: null,
		inEditButton: null,
		inDeleteButton: null,
		inAddButton: null,
	},
	data() {
		return {
			showDetailedButton: null,
			showEditButton: null,
			showDeleteButton: null,
			showAddButton: null,
		}
	},
	mounted() {
		this.init()
	},
	methods: {
		init() {
			this.showDetailedButton = this.inDetailedButton
			this.showEditButton = this.inEditButton
			this.showDeleteButton = this.inDeleteButton
			this.showAddButton = this.inAddButton
			console.log('Expand2 inAddButton=' + this.inAddButton)
		},
		editClick(row) {
			console.log('Expand2 row=', row)
			this.$emit('editClick', row)
		},
		deleteClick(row) {
			console.log('Expand2 row=', row)
			this.$emit('deleteClick', row)
		},
		detailedClick(row) {
			console.log('Expand2 row=', row)
			this.$emit('detailedClick', row)
		},
		addClick(row) {
			console.log('row=', row)
			this.$emit('addClick', row)
		},
	},
}
</script>

<style scoped>
.btn {
        background-color: #015BB3;
        color: #fff;
    }

    .btn:hover {
        /*color: #1E90FF;*/
        color: #333;
        background-color: #1890FF;
    }
</style>
