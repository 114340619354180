export default [
	{
		prop: 'companyName',
		label: '公司名稱',
		minWidth: '150', //列寬
		showOverflowTooltip: false, //設置表格文字過長顯示省略號
		align: 'center', //對齊方式
		sortable: true,
	},
	{
		prop: 'projectGroup',
		label: '項目群組名稱',
		minWidth: '250', //列寬
		showOverflowTooltip: false, //設置表格文字過長顯示省略號
		align: 'center', //對齊方式
		sortable: true,
	},
	{
		prop: 'projectGroupCode',
		label: '項目群組代號',
		minWidth: '250', //列寬
		showOverflowTooltip: false, //設置表格文字過長顯示省略號
		align: 'center', //對齊方式
		sortable: true,
	},
	{
		prop: 'projectType',
		label: '服務項目',
		minWidth: '150', //列寬
		showOverflowTooltip: false, //設置表格文字過長顯示省略號
		align: 'center', //對齊方式
		sortable: true,
	},
	{
		prop: 'amount',
		label: '項目金額',
		minWidth: '150', //列寬
		showOverflowTooltip: false, //設置表格文字過長顯示省略號
		align: 'center', //對齊方式
		sortable: true,
	},
	{
		prop: 'workingHours',
		label: '預估工時(分鐘數)',
		minWidth: '250', //列寬
		showOverflowTooltip: false, //設置表格文字過長顯示省略號
		align: 'center', //對齊方式
		sortable: true,
	},
	{
		prop: 'assetsModel',
		label: '資產類型',
		minWidth: '150',
		showOverflowTooltip: false,
		align: 'center',
		sortable: true,
	},
	{
		prop: 'isActiveString',
		label: '是否啟用',
		minWidth: '150',
		showOverflowTooltip: false,
		align: 'center',
		sortable: true,
	},
	{
		prop: 'isMainProjectString',
		label: '是否為主項目',
		minWidth: '150',
		showOverflowTooltip: false,
		align: 'center',
		sortable: true,
	},
	// {
	// 	prop: 'commissionRate',
	// 	label: '項目抽成比例',
	// 	minWidth: '250',
	// 	showOverflowTooltip: false,
	// 	align: 'center',
	// 	sortable: true,
	// },
	// {
	// 	prop: 'commissionAmount',
	// 	label: '項目抽成金額',
	// 	minWidth: '250',
	// 	showOverflowTooltip: false,
	// 	align: 'center',
	// 	sortable: true,
	// },
	// {
	// 	prop: 'maximumCommissionAmount',
	// 	label: '項目最大抽成傭金',
	// 	minWidth: '250',
	// 	showOverflowTooltip: false,
	// 	align: 'center',
	// 	sortable: true,
	// },
	{
		prop: 'action',
		label: '操作',
		minWidth: '250',
		showOverflowTooltip: true,
		align: 'center',
		render: 'action',
		sortable: false,
	},
]
