import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import locale from 'element-ui/lib/locale/lang/zh-TW' // 導入繁體中文語言包
import 'element-ui/lib/theme-chalk/index.css'
import axios from 'axios'
// import CommonCs from './assets/css/common.css'
import '@/assets/css/common.css'
// import MenuData from './assets/js/menu.js'
import 'bootstrap'
import $ from 'jquery'
// import 'bootstrap/dist/css/bootstrap.min.css'
import '@/assets/css/main.css'
import 'element-theme-default'
import Global from '@/components/element/Global.vue'
import Api from '@/assets/js/utils.js'
import responsiveSpan from '@/assets/js/responsiveSpan.js'
import {EventBus} from '@/assets/js/eventBus.js' // 导入 EventBus

import { log, error } from './logger.js';

log('This is a log message');
error('This is an error message');
Vue.prototype.$eventBus = EventBus // 将 EventBus 添加到 Vue 原型中
// 全局注册 mixin
Vue.mixin(responsiveSpan)
Vue.prototype.$api = Api
// file
window.$ = $
Vue.config.productionTip = false
Vue.prototype.axios = axios
Vue.prototype.$GLOBAL = Global
Vue.use(ElementUI, {locale}) // 使用 Element UI 並設置語言包
Vue.prototype.$version = process.env.VUE_APP_VERSION
Vue.prototype.$version2 = process.env.VUE_APP_VERSION2
// 定義 setLocale 方法來設置自定義的語言包
const setLocale = () => {
	ElementUI.locale(locale) // 使用 Element UI 的方法來設置語言包
}
// 在應用程式初始化之前設置語言包
setLocale()
//依據不同執行環境取得對應的env資料內容
let isBuild = process.env.VUE_APP_BUILD === 'true'
if (isBuild) {
	fetch('./config/config.json', {
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
		},
	})
		.then((res) => res.json())
		.then((data) => {
			axios.defaults.baseURL = data.path
			Vue.prototype.$companyId = data.companyId
			new Vue({
				el: '#app',
				router,
				template: '<App/>',
				render: (h) => h(App),
			}).$mount('#app')
		})
} else {
	axios.defaults.baseURL = process.env.VUE_APP_PATH
	Vue.prototype.$companyId = process.env.VUE_APP_COMPANYID
	new Vue({
		el: '#app',
		router,
		template: '<App/>',
		render: (h) => h(App),
	}).$mount('#app')
}
