<template>
	<div class="vue-tempalte login">
		<div class="vertical-center">
			<div class="inner-block">
				<div class="vue-tempalte">
					<form>
						<h3>請洽詢系統管理員</h3>
						<!--                        <div class="form-group">-->
						<!--                            <label>Email address</label>-->
						<!--                            <input type="email" class="form-control form-control-lg"/>-->
						<!--                        </div>-->
						<!--                        <button type="button" class="btn btn-dark btn-lg btn-block" @click="reset">-->
						<!--                            Reset password-->
						<!--                        </button>-->
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<!-- 忘記密碼頁面-->
<script>
import 'bootstrap/dist/css/bootstrap.min.css'

export default {
	data() {
		return {}
	},
	mounted() {
		console.log('畫面載入執行.....ok')
	},
	methods: {
		reset() {
			console.log('密碼重置!')
		},
	},
}
</script>

<style>
.login {
        /*background: #2554FF !important;*/
        background-color: #015bb3 !important;
    }
</style>
