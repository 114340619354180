export default [
	{
		prop: 'orderId',
		label: '工單號',
		minWidth: '150', //列寬
		showOverflowTooltip: false, //設置表格文字過長顯示省略號
		align: 'center', //對齊方式
		sortable: true,
	},
	{
		prop: 'companyName',
		label: '公司名稱',
		minWidth: '150', //列寬
		showOverflowTooltip: false, //設置表格文字過長顯示省略號
		align: 'center', //對齊方式
		sortable: true,
	},
	{
		prop: 'branchName',
		label: '分店名稱',
		minWidth: '150',
		showOverflowTooltip: false,
		align: 'center',
		sortable: true,
	},
	{
		prop: 'nickName',
		label: '服務人員',
		minWidth: '150',
		showOverflowTooltip: false,
		align: 'center',
		sortable: true,
	},
	{
		prop: 'identifier',
		label: '資產識別碼',
		minWidth: '150',
		showOverflowTooltip: false,
		align: 'center',
		sortable: true,
	},
	{
		prop: 'projectType',
		label: '服務項目',
		minWidth: '150',
		showOverflowTooltip: false,
		align: 'center',
		sortable: true,
	},
	{
		prop: 'amount',
		label: '項目金額',
		minWidth: '150',
		showOverflowTooltip: false,
		align: 'center',
		sortable: true,
	},
	{
		prop: 'commissionRate',
		label: '角色抽成比例',
		minWidth: '200',
		showOverflowTooltip: false,
		align: 'center',
		sortable: true,
	},
	{
		prop: 'commissionAmount',
		label: '角色抽成金額',
		minWidth: '200',
		showOverflowTooltip: false,
		align: 'center',
		sortable: true,
	},
	// {
	// 	prop: 'maximumCommissionAmount',
	// 	label: '項目最大抽成傭金',
	// 	minWidth: '200',
	// 	showOverflowTooltip: false,
	// 	align: 'center',
	// 	sortable: true,
	// },
	{
		prop: 'commission',
		label: '員工抽成佣金小計',
		minWidth: '200',
		showOverflowTooltip: false,
		align: 'center',
		sortable: true,
	},
	{
		prop: 'createUser',
		label: '建單人員',
		minWidth: '150',
		showOverflowTooltip: false,
		align: 'center',
		sortable: true,
	},
	{
		prop: 'createDate',
		label: '建單日期',
		minWidth: '200',
		showOverflowTooltip: false,
		align: 'center',
		sortable: true,
	},
]
