<template>
    <div class="popDialog">
        <el-dialog
                v-if="editDialog"
                width="95%"
                top="30px"
                :visible.sync="editDialog"
                :before-close="closeDialog"
                :fullscreen="dialogFull"
                custom-class="custom-dialog"
        >
            <template v-slot:title>
                <el-row>
                    <el-col :span="24" class="selectTitleMax">
                        <span>編輯服務項目資料</span>
                    </el-col>
                </el-row>
            </template>
            <el-row>
                <el-col :span="24">
                    <div class="containerDiv">
                        <el-row>
                            <el-col :span="selectTitleSpan" class="selectTitle">
                                <span>公司<span class="red">*</span>:</span>
                            </el-col>
                            <el-col :span="selectInpudSpan" class="selectInpud">
                                <el-select v-model="companyId" placeholder="" @change="handleCompanyChange"
                                           :disabled="isCompanyid">
                                    <el-option :key="'placeholder'" :label="'請選擇'" :value="null"></el-option>
                                    <el-option
                                            v-for="item in companyOptions"
                                            :key="item.companyId"
                                            :label="item.companyName"
                                            :value="item.companyId"
                                            :disabled="item.disabled"
                                    >
                                    </el-option>
                                </el-select>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="selectTitleSpan" class="selectTitle">
                                <span>服務項目<span class="red">*</span>:</span>
                            </el-col>
                            <el-col :span="selectInpudSpan" class="selectInpud">
                                <el-input v-model="projectType" placeholder="請輸入服務項目" :maxlength="50"></el-input>
                            </el-col>
                            <el-col :span="selectTitleSpan" class="selectTitle">
                                <span>項目群組名稱:</span>
                            </el-col>
                            <el-col :span="selectInpudSpan" class="selectInpud">
                                <el-input v-model="projectGroup" placeholder="請輸入項目群組名稱" :maxlength="50"></el-input>
                            </el-col>
                            <el-col :span="selectTitleSpan" class="selectTitle">
                                <span>項目群組代號:</span>
                            </el-col>
                            <el-col :span="selectInpudSpan" class="selectInpud">
                                <el-input
                                        v-model="projectGroupCode"
                                        placeholder="請輸入項目群組代號"
                                        @input="validateInput"
                                        @blur="convertToUppercase"
                                        :maxlength="50"
                                ></el-input>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="selectTitleSpan" class="selectTitle">
                                <span>項目金額<span class="red">*</span>:</span>
                            </el-col>
                            <el-col :span="selectInpudSpan" class="selectInpud">
                                <el-input v-model="amount" placeholder="請輸入項目金額" type="number"></el-input>
                            </el-col>
                            <el-col :span="selectTitleSpan" class="selectTitle">
                                <span>資產類型<span class="red">*</span>:</span>
                            </el-col>
                            <el-col :span="selectInpudSpan" class="selectInpud">
                                <el-select v-model="assetsModelTypeId" placeholder="">
                                    <el-option :key="'placeholder'" :label="'請選擇'" :value="null"></el-option>
                                    <el-option
                                            v-for="item in assetsOptions"
                                            :key="item.assetsModelTypeId"
                                            :label="item.assetsModel"
                                            :value="item.assetsModelTypeId"
                                            :disabled="item.disabled"
                                    >
                                    </el-option>
                                </el-select>
                            </el-col>
                            <el-col :span="selectTitleSpan" class="selectTitle">
                                <span>是否為主項目<span class="red">*</span>:</span>
                            </el-col>
                            <el-col :span="selectInpudSpan" class="selectInpud">
                                <el-radio-group v-model="isMainProject">
                                    <el-radio label="1">是</el-radio>
                                    <el-radio label="0">不是</el-radio>
                                </el-radio-group>
                            </el-col>
                        </el-row>
<!--                        <el-row>-->
<!--                            <el-col :span="selectTitleSpan" class="selectTitle">-->
<!--                                <span>抽成比例:</span>-->
<!--                            </el-col>-->
<!--                            <el-col :span="selectInpudSpan" class="selectInpud">-->
<!--                                <el-input v-model="commissionRate" placeholder="請輸入項目抽成比例" type="number"></el-input>-->
<!--                            </el-col>-->
<!--                            <el-col :span="selectTitleSpan" class="selectTitle">-->
<!--                                <span>抽成金額:</span>-->
<!--                            </el-col>-->
<!--                            <el-col :span="selectInpudSpan" class="selectInpud">-->
<!--                                <el-input v-model="commissionAmount" placeholder="請輸入項目抽成金額" type="number"></el-input>-->
<!--                            </el-col>-->
<!--                            <el-col :span="selectTitleSpan" class="selectTitle">-->
<!--                                <span>最大抽成傭金:</span>-->
<!--                            </el-col>-->
<!--                            <el-col :span="selectInpudSpan" class="selectInpud">-->
<!--                                <el-input-->
<!--                                        v-model="maximumCommissionAmount"-->
<!--                                        placeholder="請輸入項目最大抽成傭金"-->
<!--                                        type="number"-->
<!--                                ></el-input>-->
<!--                            </el-col>-->
<!--                        </el-row>-->
                        <el-row>
                            <el-col :span="selectTitleSpan" class="selectTitle">
                                <span>預估工時<span class="red">*</span>:</span>
                            </el-col>
                            <el-col :span="selectInpudSpan" class="selectInpud">
                                <el-input v-model="workingHours" placeholder="請輸入預估工時(分鐘數)" type="number"></el-input>
                            </el-col>
                            <el-col :span="selectTitleSpan" class="selectTitle">
                                <span>是否啟用<span class="red">*</span>:</span>
                            </el-col>
                            <el-col :span="selectInpudSpan" class="selectInpud">
                                <el-radio-group v-model="isActive">
                                    <el-radio label="1">啟用</el-radio>
                                    <el-radio label="0">不啟用</el-radio>
                                </el-radio-group>
                            </el-col>
                            <el-col :span="selectTitleSpan" class="selectTitle">
                                <span>備註:</span>
                            </el-col>
                            <el-col :span="selectInpudSpan" class="selectInpud">
                                <el-input v-model="remark" placeholder="請輸入備註"></el-input>
                            </el-col>
                        </el-row>
                    </div>
                </el-col>
            </el-row>
            <div slot="footer" class="popBatton">
                <el-button @click="editSave" type="primary">儲存</el-button>
                <el-button @click="closeDialog">關閉</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: 'editDialog',
        components: {},
        props: {
            editDialog: null,
        },
        data() {
            return {
                dialogFull: false,
                loginReturnDto: null,
                token: null,
                row: null,
                companyOptions: [],
                assetsOptions: [],
                reload: false,
                companyType: null,
                //是否需要公司ID
                isCompanyid: true,
                //是否需要分店ID
                isBranchid: true,
                workId: null,
                companyId: null,
                projectGroup: null,
                projectGroupCode: null,
                projectType: null,
                amount: null,
                workingHours: null,
                assetsModelTypeId: null,
                isActive: '1',
                isMainProject: '1',
                commissionRate: null,
                commissionAmount: null,
                maximumCommissionAmount: null,
                remark: null,
            }
        },
        mounted() {
        },
        methods: {
            init(row) {
                this.row = row
                if (row != null && row != '') {
                    this.workId = row.workId
                    this.companyId = row.companyId
                    this.projectGroup = row.projectGroup
                    this.projectGroupCode = row.projectGroupCode
                    this.isActive = row.isActive == true ? '1' : '0'
                    this.isMainProject = row.isMainProject == true ? '1' : '0'
                    this.projectType = row.projectType
                    this.amount = row.amount
                    this.workingHours = row.workingHours
                    this.assetsModelTypeId = row.assetsModelTypeId
                    this.commissionRate = row.commissionRate
                    this.commissionAmount = row.commissionAmount
                    this.maximumCommissionAmount = row.maximumCommissionAmount
                    this.remark = row.remark
                }
                this.isCompanyid = JSON.parse(localStorage.getItem('isCompanyid'))
                this.isBranchid = JSON.parse(localStorage.getItem('isBranchid'))
                const loginReturnDto = localStorage.getItem('loginReturnDto')
                if (loginReturnDto != null && JSON.parse(loginReturnDto) != null) {
                    this.loginReturnDto = JSON.parse(loginReturnDto)
                    this.token = this.loginReturnDto.token
                    if (this.isCompanyid) {
                        this.companyId = this.loginReturnDto.companyId
                        this.companyType = this.loginReturnDto.companyType
                        console.log('addCompanyDialog取得loginReturnDto.companyId=' + this.companyId)
                    }
                }
                //查詢全部公司下拉選單資料
                this.searchCompanyList()
                //依據條件查詢全部資產類型資料
                this.searchAssetsModelTypeList()
            },
            closeDialog() {
                console.log('關閉Pop')
                this.$emit('update:reload', this.reload)
                this.$emit('closeDialog')
            },
            async editSave() {
                //編輯資料
                let tmp = this
                let url = 'work/updated/work'
                try {
                    let parameter = {
                        workId: this.workId,
                        companyId: this.companyId,
                        projectGroup: this.projectGroup,
                        projectGroupCode: this.projectGroupCode,
                        projectType: this.projectType,
                        amount: this.amount,
                        workingHours: this.workingHours,
                        assetsModelTypeId: this.assetsModelTypeId,
                        isActive: this.isActive == null ? null : this.isActive == '0' ? false : true,
                        isMainProject: this.isMainProject == null ? null : this.isMainProject == '0' ? false : true,
                        commissionRate: this.commissionRate,
                        commissionAmount: this.commissionAmount,
                        maximumCommissionAmount: this.maximumCommissionAmount,
                        remark: this.remark,
                    }
                    if (!this.checkAddWork(parameter)) {
                        console.log('編輯驗證資料失敗!')
                        return
                    }
                    tmp.reload = true
                    tmp.loadingView()
                    let responseData = await tmp.$api.doPostByToken(parameter, url, this.token)
                    if (responseData) {
                        tmp.$api.openMsg('編輯資料完成!', 'success')
                        // 在延迟 2000 毫秒（2 秒）后执行代码
                        setTimeout(function () {
                            tmp.closeDialog()
                            //  tmp.$emit('init');
                        }, 1000)
                    } else {
                        tmp.$api.openMsg('編輯資料失敗!', 'error')
                        console.log('編輯資料失敗!')
                    }
                } catch (error) {
                    console.log('編輯資料發生異常! error=' + error)
                } finally {
                    tmp.closeLoading()
                }
                tmp.closeLoading()
            },
            checkAddWork(parameter) {
                var ststus = true
                if (parameter != null && parameter != '') {
                    if (parameter.projectType == null || !parameter.projectType) {
                        console.log('服務項目不可為空!')
                        this.$api.openMsg('服務項目不可為空!', 'error')
                        return false
                    }
                    if (parameter.amount == null || !parameter.amount) {
                        console.log('項目金額不可為空!')
                        this.$api.openMsg('項目金額不可為空!', 'error')
                        return false
                    }
                    if (parameter.workingHours == null || !parameter.workingHours) {
                        console.log('預估工時(分鐘數)不可為空!')
                        this.$api.openMsg('預估工時(分鐘數)不可為空!', 'error')
                        return false
                    }
                    if (parameter.isActive == null || !parameter.isActive) {
                        console.log('是否啟用不可為空!')
                        this.$api.openMsg('是否啟用不可為空!', 'error')
                        return false
                    }
					if (parameter.isMainProject == null) {
						console.log('是否為主項目不可為空!')
						this.$api.openMsg('是否為主項目不可為空!', 'error')
						return false
					}
                } else {
                    console.log('必填資料不可為空!')
                    this.$api.openMsg('必填資料不可為空!', 'error')
                    return false
                }
                return ststus
            },
            loadingView() {
                //開啟載入特效
                this.loadingStatus = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)',
                })
            },
            closeLoading() {
                //關閉載入特效
                if (this.loadingStatus != null) {
                    this.loadingStatus.close()
                }
            },
            async searchCompanyList() {
                //查詢全部公司下拉選單資料
                let tmp = this
                let url = 'login/search/searchCompanyOptions'
                let parameter = {
                    companyId: this.companyId,
                    companyName: '',
                    isActive: true,
                }
                //取得列表資料
                let responseData = await tmp.$api.doPost(parameter, url)
                if (responseData != null) {
                    tmp.companyOptions = responseData
                    console.log('取得下拉選單資料 companyOptions=' + tmp.companyOptions)
                }
            },
            handleCompanyChange(value) {
                this.assetsModelTypeId = null
                this.assetsOptions = []
                //選擇公司下拉觸發
                console.log('Selected company ID:', value)
                if (!this.isCompanyid) {
                    this.companyId = value
                }
                this.searchAssetsModelTypeList()
            },
            async searchAssetsModelTypeList() {
                //依據條件查詢全部資產類型資料
                let tmp = this
                let url = 'company/search/assetsModel'
                let parameter = {
                    companyId: this.companyId,
                    assetsModel: this.assetsModel,
                }
                //取得列表資料
                let responseData = await tmp.$api.doPostByToken(parameter, url, this.token)
                if (responseData != null) {
                    tmp.assetsOptions = responseData
                    console.log('取得下拉選單資料 assetsOptions=' + tmp.assetsOptions)
                }
            },
            validateInput() {
                // 只允许输入英文字符
                this.projectGroupCode = this.projectGroupCode.replace(/[^a-zA-Z]/g, '')
            },
            convertToUppercase() {
                // 将输入的字符转换为大写
                this.projectGroupCode = this.projectGroupCode.toUpperCase()
            },
        },
    }
</script>

<style scoped></style>
