export default [
	{
		prop: 'companyName',
		label: '公司名稱',
		minWidth: '150',
		showOverflowTooltip: false,
		align: 'center',
		sortable: true,
	},
	{
		prop: 'branchName',
		label: '分店名稱',
		minWidth: '150',
		showOverflowTooltip: false,
		align: 'center',
		sortable: true,
	},
	{
		prop: 'nickName',
		label: '暱稱',
		minWidth: '150', //列寬
		showOverflowTooltip: false, //設置表格文字過長顯示省略號
		align: 'center', //對齊方式
		sortable: true,
	},
	{
		prop: 'userName',
		label: '帳號',
		minWidth: '150', //列寬
		showOverflowTooltip: false, //設置表格文字過長顯示省略號
		align: 'center', //對齊方式
		sortable: true,
	},
	{
		prop: 'errorCount',
		label: '錯誤次數',
		minWidth: '150', //列寬
		showOverflowTooltip: false, //設置表格文字過長顯示省略號
		align: 'center', //對齊方式
		sortable: true,
	},
	{
		prop: 'isActiveString',
		label: '是否啟用',
		minWidth: '150', //列寬
		showOverflowTooltip: false, //設置表格文字過長顯示省略號
		align: 'center', //對齊方式
		sortable: true,
	},
	{
		prop: 'roleName',
		label: '角色類別',
		minWidth: '150',
		showOverflowTooltip: false,
		align: 'center',
		sortable: true,
	},
	{
		prop: 'stateString',
		label: '帳號狀態',
		minWidth: '150',
		showOverflowTooltip: false,
		align: 'center',
		sortable: true,
	},
	{
		prop: 'unlockDate',
		label: '解除鎖定時間',
		minWidth: '250',
		showOverflowTooltip: false,
		align: 'center',
		sortable: true,
	},
	{
		prop: 'mail',
		label: '信箱',
		minWidth: '150',
		showOverflowTooltip: false,
		align: 'center',
		sortable: true,
	},
	{
		prop: 'action',
		label: '操作',
		minWidth: '250',
		showOverflowTooltip: true,
		align: 'center',
		render: 'action',
		sortable: false,
	},
]
