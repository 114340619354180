<template>
	<div>
		<el-row>
			<el-col :span="24">
				<MenuPath></MenuPath>
			</el-col>
		</el-row>
		<div class="p1Div" :hidden="isCompanyid">
			<el-row>
				<div class="containerDiv">
					<el-row>
						<el-col :span="selectTitleSpan" class="selectTitle">
							<span>公司名稱:</span>
						</el-col>
						<el-col :span="selectInpudSpan" class="selectInpud">
							<el-input v-model="companyName" placeholder="請輸入公司名稱"></el-input>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="selectTitleSpan" class="selectTitle">
							<span>是否啟用:</span>
						</el-col>
						<el-col :span="selectInpudRadioSpan" class="selectInpud">
							<el-radio-group v-model="isActive">
								<el-radio label="0">全部</el-radio>
								<el-radio label="1">啟用</el-radio>
								<el-radio label="2">不啟用</el-radio>
							</el-radio-group>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="selectBattonSpan" class="selectBatton">
							<div class="grid-content bg-purple-light">
								<el-button @click="init()" type="primary" size="medium">查詢 </el-button>
							</div>
						</el-col>
						<el-col :span="selectBattonSpan" class="selectBatton">
							<div class="grid-content bg-purple-light">
								<el-button @click="clearData()" type="primary" size="medium">清除 </el-button>
							</div>
						</el-col>
					</el-row>
				</div>
				<el-row>
					<el-col :span="8" class="addBatton">
						<div class="grid-content bg-purple-light">
							<el-button v-if="addButton" @click="add()" type="primary" size="medium">新增公司 </el-button>
						</div>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24">
						<TableTemplate
							ref="tableTemplate"
							:tableData="tableData"
							:columns="columns"
							:total="total"
							:loading="loading"
							:defaultsort="defaultsort"
							:currentPage="currentPage"
							:pageSize="pageSize"
							:inDetailedButton="inDetailedButton"
							:inEditButton="inEditButton"
							:inDeleteButton="inDeleteButton"
							:direction="direction"
							:orderByName="orderByName"
							:checkBoxShow="checkBoxShow"
							@getTableDataList="findEntityList"
							@editClick="editClick"
							@deleteClick="deleteClick"
						></TableTemplate>
					</el-col>
				</el-row>
			</el-row>
		</div>
		<div class="p1Div" :hidden="!isCompanyid">
			<div class="containerDiv">
				<div v-for="(item, index) in tableData" :key="index">
					<el-row>
						<el-col :span="24" class="addBatton">
							<div class="grid-content bg-purple-light">
								<el-button v-if="inEditButton" @click="editClick(item)" type="primary" size="medium">
									編輯公司
								</el-button>
							</div>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="selectTitleSpan" class="detailedTitle">
							<span>公司名稱:</span>
						</el-col>
						<el-col :span="selectInpudSpan" class="detailed">
							<el-input
								:value="`${item.companyName || ''}`"
								:disabled="true"
								:style="{
									visibility: item.companyName !== null ? 'visible' : 'hidden',
								}"
							></el-input>
							<!--                            <span>{{item.companyName}}</span>-->
						</el-col>
						<el-col :span="selectTitleSpan" class="detailedTitle">
							<span>公司地址:</span>
						</el-col>
						<el-col :span="selectInpudSpan" class="detailed">
							<el-input
								:value="`${item.address || ''}`"
								:disabled="true"
								:style="{
									visibility: item.address !== null ? 'visible' : 'hidden',
								}"
							></el-input>
							<!--                            <span>{{item.address}}</span>-->
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="selectTitleSpan" class="detailedTitle">
							<span>是否啟用:</span>
						</el-col>
						<el-col :span="selectInpudSpan" class="detailed">
							<el-input
								:value="`${item.isActiveString || ''}`"
								:disabled="true"
								:style="{
									visibility: item.isActiveString !== null ? 'visible' : 'hidden',
								}"
							></el-input>
							<!--                            <span>{{item.isActiveString}}</span>-->
						</el-col>
						<el-col :span="selectTitleSpan" class="detailedTitle">
							<span>產業類型:</span>
						</el-col>
						<el-col :span="selectInpudSpan" class="detailed">
							<el-input
								:value="`${item.companyTypeString || ''}`"
								:disabled="true"
								:style="{
									visibility: item.companyTypeString !== null ? 'visible' : 'hidden',
								}"
							></el-input>
							<!--                            <span>{{item.companyTypeString}}</span>-->
						</el-col>
					</el-row>
				</div>
			</div>
		</div>
		<EditDialog
			:editDialog="editDialog"
			@closeDialog="closeDialog"
			@init="init"
			ref="editDialog"
			@update:reload="handleReloadUpdate"
		></EditDialog>
		<AddDialog
			:addDialog="addDialog"
			@closeDialog="closeDialog"
			@init="init"
			ref="addDialog"
			@update:reload="handleReloadUpdate"
		></AddDialog>
	</div>
</template>

<script>
import TableTemplate from '@/components/element/TableTemplate.vue'
import MenuPath from '@/components/element/MenuPath.vue'
import tableColumns from '@/assets/js/companyHomeTable.js'
import EditDialog from '@/views/company/EditCompanyDialog.vue'
import AddDialog from '@/views/company/AddCompanyDialog.vue'

export default {
	name: 'companyHome',
	components: {
		MenuPath,
		TableTemplate,
		EditDialog,
		AddDialog,
	},
	data() {
		return {
			isButtonDisabled: false, // 根据需要初始化按钮是否禁用的状态
			//表頭設定
			columns: [],
			//table數據
			tableData: [],
			//總筆數
			total: 0,
			//是否啟用載入動畫效果
			loading: false,
			//預設排序
			defaultsort: {prop: 'companyName', order: 'ascending'},
			// 当前页
			currentPage: 1,
			//每页多少条
			pageSize: 10,
			//排序:ASC,DESC
			direction: 'ASC',
			//依據屬性欄位排序
			orderByName: 'companyName',
			checkBoxShow: false,
			companyName: null,
			editDialog: false,
			token: null,
			addDialog: false,
			companyId: null,
			role: null,
			addButton: null,
			qryButton: null,
			inDetailedButton: null,
			inEditButton: null,
			inDeleteButton: null,
			isActive: '1',
			branchId: null,
			reload: false,
			permissionControlList: null,
			page: 'COMPANY',
			functionCodes: ['ADD', 'QRY', 'EDIT', 'DEL', 'VIEW'],
			//是否需要公司ID
			isCompanyid: true,
			//是否需要分店ID
			isBranchid: true,
		}
	},
	mounted() {
		this.columns = tableColumns
		this.init()
	},
	methods: {
		init() {
			this.isCompanyid = JSON.parse(localStorage.getItem('isCompanyid'))
			this.isBranchid = JSON.parse(localStorage.getItem('isBranchid'))
			console.log('companyHome取得isCompanyid=' + this.isCompanyid)
			console.log('companyHome取得isBranchid=' + this.isBranchid)
			// 從 Local Storage 獲取 loginReturnDto
			const loginReturnDto = localStorage.getItem('loginReturnDto')
			if (loginReturnDto != null && JSON.parse(loginReturnDto) != null) {
				this.loginReturnDto = JSON.parse(loginReturnDto)
				this.token = this.loginReturnDto.token
				this.role = this.loginReturnDto.role
				if (this.isCompanyid) {
					this.companyId = this.loginReturnDto.companyId
					console.log('companyHome取得loginReturnDto.companyId=' + this.companyId)
				}
				if (this.isBranchid) {
					this.branchId = this.loginReturnDto.branchId
					console.log('companyHome取得loginReturnDto.branchId=' + this.branchId)
				}
				console.log('companyHome取得role=' + this.role)
				//console.log("companyHome取得token=" + this.token);
			} else {
				console.log('companyHome未取得token資料，回到登入頁面!')
				this.$api.openMsg('尚未登入完成，請重新登入!', 'error')
				this.$router.push({path: '/login'})
				return
			}
			//權限判斷
			this.checkPermission()
			this.currentPage = 1
			this.pageSize = 10
			this.findEntityList(this.currentPage, this.pageSize, this.orderByName, this.direction)
		},
		checkPermission() {
			//權限判斷
			const permissionControl = localStorage.getItem('permissionControl')
			if (permissionControl != null && JSON.parse(permissionControl) != null) {
				this.permissionControlList = JSON.parse(permissionControl)
				console.log('companyHome取得permissionControlList=' + this.permissionControlList.length)
			}
			if (this.permissionControlList != null && this.permissionControlList.length > 0) {
				const results = this.$api.findAndCategorizePermissions(
					this.permissionControlList,
					this.page,
					this.functionCodes
				)
				this.addButton = this.$api.hasDataForSpecificFunctionCode(results, 'ADD') ? true : false
				this.qryButton = this.$api.hasDataForSpecificFunctionCode(results, 'QRY') ? true : false
				this.inEditButton = this.$api.hasDataForSpecificFunctionCode(results, 'EDIT') ? true : false
				this.inDeleteButton = this.$api.hasDataForSpecificFunctionCode(results, 'DEL') ? true : false
				this.inDetailedButton = this.$api.hasDataForSpecificFunctionCode(results, 'VIEW') ? true : false
			}
			console.log('companyHome取得addButton=' + this.addButton)
			console.log('companyHome取得qryButton=' + this.qryButton)
			console.log('companyHome取得inEditButton=' + this.inEditButton)
			console.log('companyHome取得inDeleteButton=' + this.inDeleteButton)
			if (!this.inEditButton && !this.inDetailedButton && !this.inDeleteButton) {
				//按鈕都隱藏時，不顯示操作欄位
				this.columns = this.columns.filter((column) => column.prop !== 'action')
			}
		},
		/**
		 * 依據條件查詢全部公司資料
		 * **/
		async findEntityList(currentPage, pageSize, orderByName, direction) {
			console.log('findEntityList in .....')
			console.log('currentPage=' + currentPage)
			console.log('pageSize=' + pageSize)
			console.log('orderByName=' + orderByName)
			console.log('direction=' + direction)
			if (!this.qryButton) {
				console.log('登入者無查詢權限!')
				this.$api.openMsg('登入者無查詢權限!', 'error')
				return
			}
			this.currentPage = currentPage
			this.pageSize = pageSize
			this.orderByName = orderByName
			this.direction = direction
			this.loading = true
			let tmp = this
			let url = 'company/search/company'
			let parameter = {
				companyId: tmp.companyId,
				branchId: tmp.branchId,
				companyName: tmp.companyName,
				isActive: this.isActive == null || this.isActive == '0' ? null : this.isActive == '1' ? true : false,
				pageNo: tmp.currentPage,
				dataSize: tmp.pageSize,
				orderByName: tmp.orderByName,
				direction: tmp.direction,
			}
			//取得列表資料
			let responseData = await tmp.$api.doPostByToken(parameter, url, this.token)
			if (responseData != null) {
				tmp.tableData = responseData
				if (tmp.tableData != null && tmp.tableData.length > 0) {
					tmp.total = tmp.tableData[0].totalRecords
					console.log('total=' + tmp.total)
				} else {
					tmp.total = 0
					console.log('total=' + tmp.total)
				}
			}
			this.loading = false
			console.log('asyncTest----end')
		},
		clearData() {
			//清除查詢資料
			this.companyName = ''
		},
		closeDialog() {
			this.editDialog = false
			this.addDialog = false
			console.log('reload=' + this.reload)
			//判斷是否需要重載畫面
			if (this.reload) {
				window.location.reload()
			}
		},
		editClick(row) {
			//編輯
			console.log('編輯 row=', row)
			if (!this.inEditButton) {
				this.$api.openMsg('未具有權限!', 'error')
				return
			}
			this.$refs.editDialog.init(row)
			this.editDialog = true
		},
		async deleteClick(row) {
			//刪除
			console.log('刪除 row=' + row)
			if (this.inDeleteButton) {
				this.$api.openMsg('未具有權限!', 'error')
				return
			}
			let tmp = this
			let status = false
			await this.$confirm('是否確定刪除該筆資料?', '提示', {
				confirmButtonText: '確定',
				cancelButtonText: '取消',
				type: 'warning',
			})
				.then(() => {
					status = true
				})
				.catch(() => {
					status = false
					return
				})
			if (status) {
				let url = 'company/deleteCompany/' + row.companyId
				let responseData = await tmp.$api.doDeleteByToken(url, this.token)
				if (responseData) {
					tmp.$api.openMsg('刪除資料成功!', 'success')
					// 在延迟 2000 毫秒（2 秒）后执行代码
					setTimeout(function () {
						tmp.init()
						console.log('刪除資料成功!')
					}, 1000)
				} else {
					tmp.$api.openMsg('刪除資料失敗!', 'error')
					console.log('刪除資料失敗!')
				}
			}
		},
		add() {
			//新增
			console.log('新增...')
			if (!this.addButton) {
				this.$api.openMsg('未具有權限!', 'error')
				return
			}
			this.$refs.addDialog.init()
			this.addDialog = true
		},
		handleReloadUpdate(newValue) {
			this.reload = newValue
		},
	},
}
</script>

<style>
.p1Div {
        width: 99%;
    }
</style>
