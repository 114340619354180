import { render, staticRenderFns } from "./EditWorkDialog.vue?vue&type=template&id=8fe49552&scoped=true&"
import script from "./EditWorkDialog.vue?vue&type=script&lang=js&"
export * from "./EditWorkDialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8fe49552",
  null
  
)

export default component.exports