<template>
	<div>
		<template v-for="menu in menuData">
			<el-submenu
				:key="menu.id"
				:index="menu.index"
				v-if="menu.children"
				:disabled="menu.disabled"
				:hidden="menu.hidden"
				:show-timeout="300"
				:hide-timeout="300"
			>
				<template slot="title">
					<span slot="title" class="spanText">{{ menu.title }}</span>
				</template>
				<menu-tree :menuData="menu.children"></menu-tree>
			</el-submenu>
			<el-menu-item :key="menu.id" :index="menu.index" v-else :disabled="menu.disabled" :hidden="menu.hidden">
				<span slot="title">{{ menu.title }}</span>
			</el-menu-item>
		</template>
	</div>
</template>

<!-- menu元件-->
<script>
export default {
	name: 'MenuTree',
	props: ['menuData'],
	data() {
		return {}
	},
	mounted() {
		this.init()
	},
	methods: {
		init() {},
	},
}
</script>

<style scoped>
/**选中时颜色**/
  .el-menu-item.is-active {
    /*background: #1E90FF !important;*/
    background: #F1F3F8 !important;
    color: #2D69EB!important;
  }

  .el-submenu.is-active.is-opened >.el-submenu__title i,.el-submenu.is-active.is-opened >.el-submenu__title span {
    /*background: #F1F3F8 !important;*/
    color: #2D69EB!important;
  }

  /**icon顏色**/
  [class ^= "el-icon-"] {
    /*color: #333;*/
    color: #909399;
  }

  /**縮小時隱藏title**/
  .el-menu--collapse .el-submenu__title span {
    height: 0;
    width: 0;
    overflow: hidden;
    visibility: hidden;
    display: inline-block;
  }

  /**縮小時隱藏title顏色**/
  .el-menu--collapse .el-submenu.is-active .el-submenu__title i,.el-menu--collapse .el-submenu.is-active .el-submenu__title span{
    background: #F1F3F8 !important;
    color: #2D69EB!important;
  }
</style>
