export default [
	{
		prop: 'orderId',
		label: '工單號',
		minWidth: '150', //列寬
		showOverflowTooltip: false, //設置表格文字過長顯示省略號
		align: 'center', //對齊方式
		sortable: true,
	},
	{
		prop: 'workStatusString',
		label: '項目執行狀態',
		minWidth: '200', //列寬
		showOverflowTooltip: false, //設置表格文字過長顯示省略號
		align: 'center', //對齊方式
		sortable: true,
	},
	{
		prop: 'projectType',
		label: '服務項目',
		minWidth: '200', //列寬
		showOverflowTooltip: false, //設置表格文字過長顯示省略號
		align: 'center', //對齊方式
		sortable: true,
	},
	{
		prop: 'amount',
		label: '項目金額',
		minWidth: '200', //列寬
		showOverflowTooltip: false, //設置表格文字過長顯示省略號
		align: 'center', //對齊方式
		sortable: true,
	},
	{
		prop: 'workingHours',
		label: '預估工時',
		minWidth: '200', //列寬
		showOverflowTooltip: false, //設置表格文字過長顯示省略號
		align: 'center', //對齊方式
		sortable: true,
	},
	{
		prop: 'isMainProjectString',
		label: '是否為主項目',
		minWidth: '200', //列寬
		showOverflowTooltip: false, //設置表格文字過長顯示省略號
		align: 'center', //對齊方式
		sortable: true,
	},
	{
		prop: 'remark',
		label: '備註',
		minWidth: '150',
		showOverflowTooltip: false,
		align: 'center',
		sortable: true,
	},
	{
		prop: 'createUser',
		label: '建單人員',
		minWidth: '200',
		showOverflowTooltip: false,
		align: 'center',
		sortable: true,
	},
	{
		prop: 'createDate',
		label: '建單日期',
		minWidth: '200',
		showOverflowTooltip: false,
		align: 'center',
		sortable: true,
	},
	{
		prop: 'editUser',
		label: '修改人員',
		minWidth: '200',
		showOverflowTooltip: false,
		align: 'center',
		sortable: true,
	},
	{
		prop: 'editDate',
		label: '修改日期',
		minWidth: '200',
		showOverflowTooltip: false,
		align: 'center',
		sortable: true,
	},
]
