<template>
    <div>
        <table v-if="data.length" border="1">
            <thead>
            <tr>
                <th
                        v-for="(header, index) in headers"
                        :key="index"
                        :colspan="header.colspan"
                        :rowspan="header.rowspan"
                        :style="getCellStyle(header.styles)"
                >
                    {{ header.value }}
                </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(row, rowIndex) in data" :key="rowIndex">
                <td
                        v-for="(cell, cellIndex) in row"
                        :key="cellIndex"
                        :style="getCellStyle(cell.styles)"
                        :colspan="cell.colspan"
                        :rowspan="cell.rowspan"
                >
                    {{ cell.value }}
                </td>
            </tr>
            </tbody>
        </table>
        <p v-else>No data to display</p>
    </div>
</template>

<script>
    import * as XLSX from 'xlsx';
    //import {base64ExcelData} from '@/assets/js/data.js'

    export default {
        props: {
            base64ExcelData: null,
        },
        data() {
            return {
                headers: [], // 表头
                data: [], // 表格数据
                defaultColumnWidth: 100, // 默认列宽
                defaultFontSize: 16 // 默认字体大小
            };
        },
        mounted() {
            if(this.base64ExcelData != null && this.base64ExcelData != ''){
                // 使用从 data.js 导入的 Base64 编码数据
                this.parseExcelFromBase64(this.base64ExcelData);
            }
        },
        methods: {
            // 将 Base64 编码转换为 Blob
            base64ToBlob(base64, mime) {
                const sliceSize = 512;
                const byteCharacters = atob(base64);
                const byteArrays = [];

                for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                    const slice = byteCharacters.slice(offset, offset + sliceSize);
                    const byteNumbers = new Array(slice.length);
                    for (let i = 0; i < slice.length; i++) {
                        byteNumbers[i] = slice.charCodeAt(i);
                    }
                    const byteArray = new Uint8Array(byteNumbers);
                    byteArrays.push(byteArray);
                }

                return new Blob(byteArrays, {type: mime});
            },

            // 解析 Base64 编码的 Excel 数据
            async parseExcelFromBase64(base64Data) {
                const blob = this.base64ToBlob(base64Data, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
                const arrayBuffer = await blob.arrayBuffer();
                const workbook = XLSX.read(arrayBuffer, {type: 'array'});
                const sheetNames = workbook.SheetNames;
                const firstSheet = sheetNames[0];
                const worksheet = workbook.Sheets[firstSheet];
                const jsonData = XLSX.utils.sheet_to_json(worksheet, {header: 1, raw: true});
                const mergeInfo = worksheet['!merges'] || [];
                const colWidths = worksheet['!cols'] || [];

                const data = [];
                jsonData.forEach((row, rowIndex) => {
                    const rowData = [];
                    row.forEach((cell, cellIndex) => {
                        if (cell !== null && cell !== undefined && cell !== "") { // 过滤掉空白单元格
                            const cellRef = XLSX.utils.encode_cell({r: rowIndex, c: cellIndex});
                            const cellStyle = worksheet[cellRef] ? worksheet[cellRef].s : {};
                            const mergeCell = mergeInfo.find(merge => merge.s.r === rowIndex && merge.s.c === cellIndex);
                            const cellData = {
                                value: cell,
                                styles: cellStyle,
                                colspan: mergeCell ? mergeCell.e.c - mergeCell.s.c + 1 : 1,
                                rowspan: mergeCell ? mergeCell.e.r - mergeCell.s.r + 1 : 1
                            };
                            rowData.push(cellData);
                        }
                    });
                    if (rowData.length > 0) { // 仅添加包含数据的行
                        data.push(rowData);
                    }
                });

                if (data.length > 0) {
                    this.headers = data[0].map((cell, index) => ({
                        value: cell.value,
                        colspan: cell.colspan,
                        rowspan: cell.rowspan,
                        styles: {
                            ...cell.styles,
                            width: colWidths[index] ? `${colWidths[index].wpx || this.defaultColumnWidth}px` : `${this.defaultColumnWidth}px`
                        }
                    }));
                    this.data = data.slice(1).map(row =>
                        row.map((cell, index) => ({
                            ...cell,
                            styles: {
                                ...cell.styles,
                                width: colWidths[index] ? `${colWidths[index].wpx || this.defaultColumnWidth}px` : `${this.defaultColumnWidth}px`
                            }
                        }))
                    );
                }
            },

            // 根据 Excel 样式生成 CSS 样式
            getCellStyle(styles) {
                const css = {
                    textAlign: 'center', // 默认水平居中对齐
                    verticalAlign: 'middle', // 默认垂直居中对齐
                    width: styles.width || `${this.defaultColumnWidth}px`, // 使用默认列宽
                    fontSize: styles.font && styles.font.sz ? `${styles.font.sz}px` : `${this.defaultFontSize}px`, // 使用默认字体大小
                };
                if (styles && styles.font) {
                    if (styles.font.bold) css.fontWeight = 'bold';
                    if (styles.font.color && styles.font.color.rgb) css.color = `#${styles.font.color.rgb.slice(2)}`;
                }
                if (styles && styles.fill) {
                    const bgColor = styles.fill.fgColor ? styles.fill.fgColor.rgb : styles.fill.bgColor.rgb;
                    css.backgroundColor = `#${bgColor.slice(2)}`;
                }
                if (styles && styles.alignment) {
                    if (styles.alignment.horizontal) css.textAlign = styles.alignment.horizontal;
                    if (styles.alignment.vertical) css.verticalAlign = styles.alignment.vertical;
                }
                return css;
            }
        },
    };
</script>

<style scoped>
    table {
        border-collapse: collapse;
        width: 100%;
    }

    th, td {
        border: 2px solid black;
    }
</style>