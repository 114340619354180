<template>
    <div>
        <!--        :disabled-date="disabledDate"-->
        <date-picker
                v-model="value"
                type="datetime"
                format="YYYY-MM-DD HH:mm"
                :disabled-date="disabledDate"
                :disabled-time="disabledTime"
                lang="zh-TW"
                @change="setInput"
                :append-to-body="true"
                class="custom-date-picker"
                style="width: 100%"
                :disabled="isDisabled"
        ></date-picker>
    </div>
</template>

<script>
    import DatePicker from 'vue2-datepicker'
    import 'vue2-datepicker/index.css'
    import 'vue2-datepicker/locale/zh-tw' // 引入繁體中文語言包
    export default {
        name: 'Datepicker',
        props: {
            inDate: null,
            companyId: null,
            branchId: null,
            isDisabled: null,
        },
        components: {DatePicker},
        data() {
            return {
                value: null,
                disabledDateTimes: [
                    // {start: new Date(2024, 6, 3, 10, 0), end: new Date(2024, 6, 4, 18, 0)},
                    // {start: new Date(2024, 6, 10, 10, 0), end: new Date(2024, 6, 10, 15, 0)},
                ],
                loginReturnDto: null,
                token: null,
                //是否需要公司ID
                isCompanyid: true,
                //是否需要分店ID
                isBranchid: true,
            }
        },
        mounted() {
            console.log('畫面載入執行.....ok')
            this.init()
            // this.$nextTick(() => {
            //     const calendar = document.querySelector('.mx-datepicker-main');
            //     if (calendar) {
            //         calendar.style.zIndex = 9999;
            //     }
            // });
        },
        methods: {
            init() {
                this.isCompanyid = JSON.parse(localStorage.getItem('isCompanyid'))
                this.isBranchid = JSON.parse(localStorage.getItem('isBranchid'))
                // 從 Local Storage 獲取 loginReturnDto
                this.loginReturnDto = localStorage.getItem('loginReturnDto')
                if (this.loginReturnDto != null && JSON.parse(this.loginReturnDto) != null) {
                    this.loginReturnDto = JSON.parse(this.loginReturnDto)
                    this.token = this.loginReturnDto.token
                }
                console.log('Datepicker inDate=' + this.inDate)
                if (this.inDate == null || this.inDate == '') {
                    let date = new Date()
                    //let currentHour = date.getHours(); // 獲取當前小時
                    //date.setHours(currentHour, 0, 0, 0); // 設置小時為當前小時，分鐘、秒和毫秒為 0
                    date.setHours(0, 0, 0, 0) // 設置時間為00:00:00
                    this.value = date
                } else {
                    this.value = new Date(this.inDate)
                }
                //查詢禁止選用的時間區間資料
                this.selectDisabledDate()
                console.log('查詢禁止選用的時間區間資料 disabledDateTimes=', this.disabledDateTimes)
            },
            setInput() {
                //將選擇的時間資料傳送到父層
                console.log('this.value=' + this.value)
                this.$emit('updateStartTimeInput', this.value)
            },
            disabledDate(date) {
                const currentDate = new Date()
                // 将时间部分设置为0，只比较日期部分
                date.setHours(0, 0, 0, 0)
                currentDate.setHours(0, 0, 0, 0)
                // 比较选定日期和当前日期
                if (date.getTime() < currentDate.getTime()) {
                    return true
                }
                return false
                // // 禁用指定的日期時間範圍
                // let isDisabled = false;
                // // 检查是否在指定的日期时间范围内
                // this.disabledDateTimes.forEach(range => {
                //     if (date.getTime() >= range.start.getTime() && date.getTime() <= range.end.getTime()) {
                //         isDisabled = true;
                //     }
                // });
                // return isDisabled;
            },
            disabledTime3(date) {
                // 禁用每天早上8点前及下午6点后的时段
                const hours = date.getHours()
                if (hours < 8 || hours >= 18) {
                    return true
                }
            },
            disabledTime(date) {
                return this.disabledDateTimes.some((range) => {
                    //禁用當前日期時間之前的所有時間
                    const currentDate = new Date()
                    // 比较选定日期和当前日期
                    if (date.getTime() < currentDate.getTime()) {
                        return true
                    }
                    // 禁用每天早上8点前及下午6点后的时段
                    // const hours1 = date.getHours();
                    // if (hours1 < 6 || hours1 >= 20) {
                    //     return true;
                    // }

                    //禁用日期時間範圍的時段
                    const startDateTime = range.start
                    const endDateTime = range.end
                    // 确定日期是否在禁用范围内
                    if (date >= startDateTime && date <= endDateTime) {
                        const hours = date.getHours()
                        const minutes = date.getMinutes()
                        // 如果日期是开始日期，检查时间是否在开始时间之前
                        if (date.getTime() === startDateTime.getTime()) {
                            return (
                                hours < startDateTime.getHours() ||
                                (hours === startDateTime.getHours() && minutes < startDateTime.getMinutes())
                            )
                        }
                        // 如果日期是结束日期，检查时间是否在结束时间之后
                        if (date.getTime() === endDateTime.getTime()) {
                            return (
                                hours < endDateTime.getHours() || (hours === endDateTime.getHours() && minutes < endDateTime.getMinutes())
                            )
                        }
                        // 如果日期在范围内但不是开始或结束日期，则禁用整个时间段
                        return true
                    }
                    return false
                })
            },
            async selectDisabledDate() {
                //查詢禁止選用的時間區間資料(排除工單狀態:3)
                let tmp = this
                let url = 'order/search/selectDisabledDate'
                let parameter = {
                    companyId: tmp.companyId,
                    branchId: tmp.branchId,
                    orderStatus: 3, //工單狀態:取消
                }
                //取得列表資料
                let responseData = await tmp.$api.doPostByToken(parameter, url, this.token)
                if (responseData != null) {
                    for (var i = 0; i < responseData.length; i++) {
                        console.log('startTime=', responseData[i].startTime)
                        console.log('endTime=', responseData[i].endTime)
                        let startTimeDate = new Date(responseData[i].startTime)
                        let endTimeDate = new Date(responseData[i].endTime)
                        let inDate = {
                            start: startTimeDate,
                            end: endTimeDate,
                        }
                        tmp.disabledDateTimes.push(inDate)
                    }
                    console.log('查詢禁止選用的時間區間資料 disabledDateTimes=', this.disabledDateTimes)
                }
            },
        },
    }
</script>

<style>
    .mx-datepicker-main {
        z-index: 9999 !important;
    }
</style>
