<template>
    <div>
        <el-row>
            <el-col :span="24">
                <el-col :span="23">
                    <span class="footer1">優來客 © Copyright 2024 FORULIKE Company. All rights reserved.</span>
                </el-col>
                <el-col :span="1">
                    <div>
                        <span class="container">{{ version2 }}</span>
                    </div>
                </el-col>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    export default {
        name: 'Footer',
        data() {
            return {
                version2: null,
            }
        },
        mounted() {
            this.version2 = this.$version2
        },
    }
</script>
<style scoped>
    .footer1 {
        font-size: 0.7rem;
        background-color: #015bb3;
        color: #fff;
        text-align: center;
    }

    .container {
        font-size: 0.7rem;
		justify-content: flex-end; /* 水平靠右 */
    }

</style>
