<template>
	<div id="myTable">
		<el-row>
			<el-col :span="24">
				<el-table
					ref="multipleTable"
					:data="tableData"
					v-loading="loading"
					highlight-current-row
					element-loading-text="檔案加载中..."
					element-loading-spinner="el-icon-loading"
					:header-cell-style="tableHeaderColor"
					element-loading-background="rgba(0, 0, 0, 0.8)"
					@sort-change="sortChange"
					:default-sort="defaultsort"
					:row-style="{height: '50px'}"
					@selection-change="handleSelectChange"
					:row-key="getRowKeys"
					@row-click="rowClick"
					@cell-click="cellClick"
					height="600"
				>
					<!-- 这是选择列，你可以根据需要自行调整 -->
					<el-table-column
						width="100"
						type="selection"
						align="center"
						:selectable="checkSelectable"
						disabled="true"
						:reserve-selection="true"
						v-if="checkBoxShow"
					>
					</el-table-column>
					<!-- sortable 預設排序 ,sortable="custom" 後臺排序 -->
					<el-table-column
						v-for="(item, index) in columns"
						:key="index"
						:prop="item.prop"
						:label="item.label"
						:align="item.align"
						:width="item.width || item.minWidth"
						:sortable="item.sortable"
						:show-overflow-tooltip="item.showOverflowTooltip"
					>
						<template slot-scope="scope">
							<Expand
								:scope="scope"
								v-if="item.render == 'action'"
								@editClick="editClick"
								:inDetailedButton="inDetailedButton"
								:inEditButton="inEditButton"
								:inDeleteButton="inDeleteButton"
								@deleteClick="deleteClick"
								@detailedClick="detailedClick"
								ref="expandData"
							></Expand>
							<span v-else>
								{{ scope.row[item.prop] }}
							</span>
						</template>
					</el-table-column>
				</el-table>
			</el-col>
		</el-row>
		<el-row>
			<el-col>
				<template>
					<el-pagination
						class="pagination"
						style="float: right; margin-top: 5px"
						@size-change="handleSizeChange"
						@current-change="handleCurrentChange"
						:current-page="currentPage"
						:page-sizes="[10, 20, 50, 100]"
						:page-size="pageSize"
						layout="total, sizes, prev, pager, next, jumper"
						prev-text="上一頁"
						next-text="下一頁"
						:total="total"
					>
					</el-pagination>
				</template>
			</el-col>
		</el-row>
	</div>
</template>

<!-- 表格元件-->
<script>
import Expand from '@/components/element/Expand.vue'
import tableJs from '@/assets/js/tableTemplate.js'

export default {
	name: 'TableTemplate',
	props: {
		//表頭設定
		columns: [],
		//table數據
		tableData: [],
		//總筆數
		total: null,
		//是否啟用載入動畫效果
		loading: null,
		//預設排序
		defaultsort: null,
		// 当前页
		currentPage: null,
		//每页多少条
		pageSize: null,
		//排序:ASC,DESC
		direction: null,
		//依據屬性欄位排序
		orderByName: null,
		//依據屬性欄位排序
		checkBoxShow: null,
		//按鈕顯示
		inDetailedButton: null,
		inEditButton: null,
		inDeleteButton: null,
	},
	components: {
		Expand,
	},
	data() {
		return {}
	},
	mounted() {
		this.init()
	},
	methods: {
		init() {},
		sortChange(column, prop, order) {
			console.log('點擊排序!')
			// 變更排序
			this.$emit('sort-change', column, prop, order)
			//在vue2.0中移除了组件的props的双向数据绑定功,故不可修改父組件傳送來的值
			let orderByName = ''
			let direction = ''
			if (column.order != null) {
				if ('ascending' == column.order) {
					direction = 'ASC'
				} else if ('descending' == column.order) {
					direction = 'DESC'
				}
			}
			if (column.prop != null) {
				orderByName = column.prop
			}
			this.$emit('getTableDataList', this.currentPage, this.pageSize, orderByName, direction)
			this.updateColor();
		},
		updateColor(){
			var tmp = this
			setTimeout(function() {
				// 變更排序-變更操作按鈕顏色
				console.log('expandData=', tmp.$refs.expandData)
				const expandComponents = tmp.$refs.expandData
				if (Array.isArray(expandComponents)) {
					console.log('expandComponents.length=', expandComponents.length)
					expandComponents.forEach(component => {
						if (component && typeof component.updateColor === 'function') {
							component.updateColor()
						}
					})
				} else {
					console.log('expandData 不是數組')
				}
			}, 100)
		},
		rowClick(row, event, column) {
			console.log('單行點擊! row=', row)
			tableJs.rowClick(row, event, column)
			if (row.orderId != null && row.workId) {
				this.$emit('selectOrderWorkUser', row)
			}
		},
		// eslint-disable-next-line no-unused-vars
		cellClick(row, column, cell, event) {
			// 只在點擊 Name 列時觸發處理
			if (column.property != 'action') {
				console.log('cellClick點擊! row=', row)
				if (row.customerId != null && row.nickName != null) {
					this.$emit('addOrder', row)
				}
			}
		},
		handleSizeChange(size) {
			console.log(`每页 ${size} 条`)
			//變更每頁數目
			this.$emit('handleSize-Change', size)
			this.$emit('getTableDataList', this.currentPage, size, this.orderByName, this.direction)
		},
		handleCurrentChange(currentPage) {
			console.log(`当前页: ${currentPage}`)
			//變更頁數
			this.$emit('handleCurrent-Change', currentPage)
			this.$emit('getTableDataList', currentPage, this.pageSize, this.orderByName, this.direction)
		},
		//設定表頭行的樣式
		tableHeaderColor() {
			return 'color:#fff;font-size:18px;text-align:center'
		},
		// eslint-disable-next-line no-unused-vars
		checkSelectable(row, index) {
			//禁用選取
			return tableJs.checkSelectable(row, index)
		},
		handleSelectChange(val) {
			//點擊單選
			tableJs.handleSelectChange(val)
		},
		// eslint-disable-next-line no-unused-vars
		getRowKeys(row) {
			return row.id
		},
		getSelection() {
			let arr = this.$refs.multipleTable.selection
			return arr
		},
		editClick(row) {
			console.log('TableTemplate row=', row)
			this.$emit('editClick', row)
		},
		deleteClick(row) {
			console.log('TableTemplate row=', row)
			this.$emit('deleteClick', row)
		},
		detailedClick(row) {
			console.log('TableTemplate row=', row)
			this.$emit('detailedClick', row)
		},
	},
}
</script>

<style>
#myTable {
        margin: 15px;
    }

    .el-pagination__editor {
        border: 0px solid #d1dbe5 !important;
        padding: 0px !important;
    }

    .el-pager li.active {
        /**頁數底色**/
        background-color: #1E90FF;
        border-color: #1E90FF;
        color: #fff;
        cursor: default;
    }

    .el-table .ascending .sort-caret.ascending {
        /**排序ASC底色**/
        border-bottom-color: #1E90FF;
    }

    .el-table .descending .sort-caret.descending {
        /**排序DESC底色**/
        border-top-color: #1E90FF;
    }

    .el-table__header .el-table-column--selection .cell .el-checkbox:after {
        color: #333;
        content: "全選";
        font-size: 16px;
        margin-left: 12px;
        font-weight: bold;
    }

	.pagination {
		white-space: nowrap;
		overflow-x: auto;
    max-width: 100%; /* 確保元素不會超出容器寬度 */
		box-sizing: border-box;
	}

    /* 隱藏 el-date-picker 的時鐘圖標 */
    .el-date-editor .el-input__prefix {
        display: none;
    }
</style>
