<template>
	<div class="popDialog">
		<el-dialog
			v-if="detailedDialog"
			width="95%"
			top="30px"
			:visible.sync="detailedDialog"
			:before-close="closeDialog"
			:fullscreen="dialogFull"
		>
			<template v-slot:title>
				<el-row>
					<el-col :span="24" class="selectTitleMax">
						<span>員工詳細資料</span>
					</el-col>
				</el-row>
			</template>
			<el-row>
				<el-col :span="24">
					<div class="containerDiv">
						<el-row>
							<el-col :span="selectDetailedTitleSpan" class="detailedTitle">
								<span>姓名:</span>
							</el-col>
							<el-col :span="selectDetailedInpudSpan" class="detailed">
								<el-input
									:value="`${row.name || ''} ${row.lastName || ''}`"
									:disabled="true"
									:style="{
										visibility: row.name !== null || row.lastName !== null ? 'visible' : 'hidden',
									}"
								></el-input>
								<!--                                <span>{{row.name}}{{row.lastName}}</span>-->
							</el-col>
							<el-col :span="selectDetailedTitleSpan" class="detailedTitle">
								<span>暱稱:</span>
							</el-col>
							<el-col :span="selectDetailedInpudSpan" class="detailed">
								<el-input
									:value="`${row.nickName || ''}`"
									:disabled="true"
									:style="{visibility: row.nickName !== null ? 'visible' : 'hidden'}"
								></el-input>
								<!--                                <span>{{row.nickName}}</span>-->
							</el-col>
							<el-col :span="selectDetailedTitleSpan" class="detailedTitle">
								<span>性別:</span>
							</el-col>
							<el-col :span="selectDetailedInpudSpan" class="detailed">
								<el-input
									:value="`${row.genderString || ''}`"
									:disabled="true"
									:style="{visibility: row.genderString !== null ? 'visible' : 'hidden'}"
								></el-input>
								<!--                                <span>{{row.genderString}}</span>-->
							</el-col>
						</el-row>
					</div>
				</el-col>
			</el-row>
		</el-dialog>
	</div>
</template>

<script>
export default {
	name: 'detailedDialog',
	components: {},
	props: {
		detailedDialog: null,
	},
	data() {
		return {
			dialogFull: false,
			row: null,
			token: null,
			companyId: null,
			reload: false,
		}
	},
	mounted() {},
	methods: {
		init(row) {
			this.row = row.user
			const loginReturnDto = localStorage.getItem('loginReturnDto')
			if (loginReturnDto != null && JSON.parse(loginReturnDto) != null) {
				this.loginReturnDto = JSON.parse(loginReturnDto)
				this.token = this.loginReturnDto.token
				this.companyId = this.loginReturnDto.companyId
				//console.log("editCompanyDialog取得token=" + this.token);
			}
		},
		loadingView() {
			//開啟載入特效
			this.loadingStatus = this.$loading({
				lock: true,
				text: 'Loading',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)',
			})
		},
		closeDialog() {
			console.log('關閉Pop')
			this.$emit('update:reload', this.reload)
			this.$emit('closeDialog')
		},
		closeLoading() {
			//關閉載入特效
			if (this.loadingStatus != null) {
				this.loadingStatus.close()
			}
		},
	},
}
</script>

<style scoped>
/* 使用 ::v-deep 深度选择器 */
::v-deep .el-input__inner {
  border: none !important; /* 去除外框线 */
  background-color: transparent !important; /* 设置背景色为透明 */
  padding: 0 !important; /* 去除内部的 padding */
  box-shadow: none !important; /* 去除阴影效果 */
}
</style>
