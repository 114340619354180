<template>
    <div class="popDialog">
        <el-dialog
                v-if="excelViewDialog"
                width="95%"
                top="30px"
                :visible.sync="excelViewDialog"
                :before-close="closeDialog"
                :fullscreen="dialogFull"
                custom-class="custom-dialog"
        >
            <template v-slot:title>
                <el-row>
                    <el-col :span="24" class="selectTitleMax">
                        <span>服務項目表</span>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="selectTitleSpan" class="selectTitle">
                        <span>公司:</span>
                    </el-col>
                    <el-col :span="selectInpudSpan" class="selectInpud">
                        <el-select v-model="companyId" placeholder="" :disabled="isCompanyid"
                                   @change="handleCompanyChange">
                            <el-option :key="'placeholder'" :label="'請選擇'" :value="null"></el-option>
                            <el-option
                                    v-for="item in companyOptions"
                                    :key="item.companyId"
                                    :label="item.companyName"
                                    :value="item.companyId"
                                    :disabled="item.disabled"
                            >
                            </el-option>
                        </el-select>
                    </el-col>
                </el-row>
                <el-row v-if="base64ExcelData != null && companyId != null">
                    <el-col :span="24" class="xlsxData">
                        <div class="scrollable-content">
                            <ExcelView :base64ExcelData="base64ExcelData"></ExcelView>
                        </div>
                    </el-col>
                </el-row>
                <el-row v-if="base64ExcelData == null || companyId == null">
                    <el-col :span="24" class="xlsxData">
                        <div class="scrollable-content">
                            <span>查無資料!</span>
                        </div>
                    </el-col>
                </el-row>
            </template>
        </el-dialog>
    </div>
</template>

<script>
    import ExcelView from '@/components/element/ExcelView.vue'

    export default {
        name: 'excelViewDialog',
        components: {
            ExcelView,
        },
        props: {
            excelViewDialog: null,
        },
        data() {
            return {
                dialogFull: false,
                companyId: null,
                companyOptions: [],
                //是否需要公司ID
                isCompanyid: true,
                loginReturnDto: null,
                base64ExcelData: null,
                token: null,
            }
        },
        mounted() {

        },
        methods: {
            init() {
                this.isCompanyid = JSON.parse(localStorage.getItem('isCompanyid'))
                console.log('excelViewDialog取得loginReturnDto.isCompanyid=' + this.isCompanyid)
                // 從 Local Storage 獲取 loginReturnDto
                this.loginReturnDto = localStorage.getItem('loginReturnDto')
                this.loginReturnDto = JSON.parse(this.loginReturnDto)
                this.token = this.loginReturnDto.token
                if (this.isCompanyid) {
                    this.companyId = this.loginReturnDto.companyId
                    console.log('excelViewDialog取得loginReturnDto.companyId=' + this.companyId)
                }
                this.searchCompanyList();
                this.getBase64ExcelData();
            },
            closeDialog() {
                console.log('關閉Pop')
                this.$emit('update:reload', this.reload)
                this.$emit('closeDialog')
            },
            async searchCompanyList() {
                //查詢全部公司下拉選單資料
                let tmp = this
                let url = 'login/search/searchCompanyOptions'
                let parameter = {
                    companyId: this.companyId,
                    companyName: '',
                    isActive: true,
                }
                //取得列表資料
                let responseData = await tmp.$api.doPost(parameter, url)
                if (responseData != null) {
                    tmp.companyOptions = responseData
                    console.log('取得下拉選單資料 companyOptions=' + tmp.companyOptions)
                }
            },
            async getBase64ExcelData() {
                console.log('-----開始等待 companyOptions-----')
                await this.waitForCompanyOptions();
                console.log('已收到資料 companyOptions=', this.companyOptions + ",companyId=" + this.companyId)
                // 確保找到了會員資產
                this.servicesFormat();
            },
            handleCompanyChange(value) {
                console.log('Selected company ID:', value)
                console.log('staffHome取得companyId=' + this.companyId)
                if (value != null && value != '') {
                    this.getBase64ExcelData();
                }
            },
            waitForCompanyOptions() {
                return new Promise((resolve) => {
                    const interval = setInterval(() => {
                        if (this.companyOptions != null && this.companyOptions.length > 0 && this.companyId != null && this.companyId != '') {
                            clearInterval(interval);
                            resolve();
                        }
                    }, 10); // 每100毫秒检查一次
                });
            },
            async servicesFormat() {
                //查詢服務項目表單
                let tmp = this
                let url = 'work/search/servicesFormat'
                let parameter = {
                    companyId: this.companyId,
                    isActive: true,
                    isMainProject: true,
                }
                //取得列表資料
                let responseData = await tmp.$api.doPostByToken(parameter, url, this.token)
                if (responseData != null) {
                    tmp.base64ExcelData = responseData
                    //console.log('取得XLSX資料 base64ExcelData=' + tmp.base64ExcelData)
                }
            },
        },
    }
</script>

<style scoped>
    /**大標題**/
    .xlsxData {

        /*text-align: center;*/
        /*font-size: 16px;*/
        margin-top: 20px;
        /*font-weight: bold;*/
    }

    .scrollable-content {
        max-height: 500px; /* 根据需要设置最大高度 */
        overflow: auto; /* 显示滚动条 */
    }

</style>
