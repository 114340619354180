<template>
	<div class="popDialog">
		<el-dialog
			v-if="orderWorkUserDialog"
			width="95%"
			top="30px"
			:visible.sync="orderWorkUserDialog"
			:before-close="orderWorkUserCloseDialog"
			:fullscreen="dialogFull"
			custom-class="custom-dialog"
		>
			<template v-slot:title>
				<el-row>
					<el-col :span="24" class="selectTitleMax">
						<span>服務人員資料</span>
					</el-col>
				</el-row>
			</template>
			<el-row>
				<el-col :span="24">
					<div class="containerDiv">
						<el-row v-for="(orderWork, index) in orderWorkUserData" :key="index">
							<el-row style="border: 2px solid #b85104; margin: 10px">
								<el-row>
									<el-col :span="selectTitleSpan" class="detailedTitle">
										<span>服務人員:</span>
									</el-col>
									<el-col :span="selectInpudSpan" class="detailed">
										<el-input
											:value="`${orderWork.nickName || ''}`"
											:disabled="true"
											:style="{visibility: orderWork.nickName !== null ? 'visible' : 'hidden'}"
										></el-input>
										<!--                                    {{orderWork.nickName}}-->
									</el-col>
									<el-col :span="selectTitleSpan" class="detailedTitle">
										<span>性別:</span>
									</el-col>
									<el-col :span="selectInpudSpan" class="detailed">
										<el-input
											:value="`${orderWork.genderString || ''}`"
											:disabled="true"
											:style="{visibility: orderWork.genderString !== null ? 'visible' : 'hidden'}"
										></el-input>
										<!--                                    {{orderWork.genderString}}-->
									</el-col>
									<el-col :span="selectTitleSpan" class="detailedTitle">
										<span>服務項目:</span>
									</el-col>
									<el-col :span="selectInpudSpan" class="detailed">
										<el-input
											:value="`${orderWork.projectType || ''}`"
											:disabled="true"
											:style="{visibility: orderWork.projectType !== null ? 'visible' : 'hidden'}"
										></el-input>
										<!--                                    {{orderWork.projectType}}-->
									</el-col>
								</el-row>
								<el-row>
									<el-col :span="selectTitleSpan" class="detailedTitle">
										<span>抽成比例(%):</span>
									</el-col>
									<el-col :span="selectInpudSpan" class="detailed">
										<el-input
											:value="`${orderWork.commissionRate || ''}`"
											:disabled="true"
											:style="{
												visibility: orderWork.commissionRate !== null ? 'visible' : 'hidden',
											}"
										></el-input>
										<!--                                    {{orderWork.commissionRate}}-->
									</el-col>
									<el-col :span="selectTitleSpan" class="detailedTitle">
										<span>抽成金額:</span>
									</el-col>
									<el-col :span="selectInpudSpan" class="detailed">
										<el-input
											:value="`${orderWork.commissionAmount || ''}`"
											:disabled="true"
											:style="{
												visibility: orderWork.commissionAmount !== null ? 'visible' : 'hidden',
											}"
										></el-input>
										<!--                                    {{orderWork.commissionAmount}}-->
									</el-col>
									<el-col :span="selectTitleSpan" class="detailedTitle">
										<span>佣金小計:</span>
									</el-col>
									<el-col :span="selectInpudSpan" class="detailed">
										<el-input
												:value="`${orderWork.commission || ''}`"
												:disabled="true"
												:style="{visibility: orderWork.commission !== null ? 'visible' : 'hidden'}"
										></el-input>
										<!--                                    {{orderWork.commission}}-->
									</el-col>
									<!--                                    <el-col :span="selectTitleSpan" class="detailedTitle">-->
									<!--                                        <span>最大抽成傭金:</span>-->
									<!--                                    </el-col>-->
									<!--                                    <el-col :span="selectInpudSpan" class="detailed">-->
									<!--                                        <el-input :value="`${orderWork.maximumCommissionAmount || ''}`" :disabled="true"-->
									<!--                                                  :style="{ visibility: orderWork.maximumCommissionAmount !== null ? 'visible' : 'hidden' }"></el-input>-->
									<!--                                        &lt;!&ndash;                                    {{orderWork.maximumCommissionAmount}}&ndash;&gt;-->
									<!--                                    </el-col>-->
								</el-row>
<!--								<el-row>-->
<!--									<el-col :span="selectTitleSpan" class="detailedTitle">-->
<!--										<span>佣金小計:</span>-->
<!--									</el-col>-->
<!--									<el-col :span="selectInpudSpan" class="detailed">-->
<!--										<el-input-->
<!--											:value="`${orderWork.commission || ''}`"-->
<!--											:disabled="true"-->
<!--											:style="{visibility: orderWork.commission !== null ? 'visible' : 'hidden'}"-->
<!--										></el-input>-->
<!--										&lt;!&ndash;                                    {{orderWork.commission}}&ndash;&gt;-->
<!--									</el-col>-->
<!--								</el-row>-->
							</el-row>
						</el-row>
					</div>
				</el-col>
			</el-row>
		</el-dialog>
	</div>
</template>

<script>
export default {
	name: 'orderWorkUserDialog',
	components: {},
	props: {
		orderWorkUserDialog: null,
		orderWorkUserData: [],
	},
	data() {
		return {
			dialogFull: false,
		}
	},
	mounted() {
		console.log('orderWorkUserDialog 開啟')
	},
	methods: {
		orderWorkUserCloseDialog() {
			console.log('關閉Pop')
			this.$emit('orderWorkUserCloseDialog')
		},
	},
}
</script>

<style scoped>
/* 使用 ::v-deep 深度选择器 */
::v-deep .el-input__inner {
  border: none !important; /* 去除外框线 */
  background-color: transparent !important; /* 设置背景色为透明 */
  padding: 0 !important; /* 去除内部的 padding */
  box-shadow: none !important; /* 去除阴影效果 */
}
</style>
