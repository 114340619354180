<template>
	<div class="popDialog">
		<el-dialog
			v-if="addDialog"
			width="95%"
			top="30px"
			:visible.sync="addDialog"
			:before-close="closeDialog"
			:fullscreen="dialogFull"
		>
			<template v-slot:title>
				<el-row>
					<el-col :span="24" class="selectTitleMax">
						<span>新增員工帳號資料</span>
					</el-col>
				</el-row>
			</template>
			<el-row>
				<el-col :span="24">
					<div class="containerDiv">
						<el-row>
							<el-col :span="selectTitleSpan" class="selectTitleMid">
								<span>帳號資料</span>
							</el-col>
						</el-row>
						<el-row>
							<el-col :span="selectTitleSpan" class="selectTitle">
								<span>公司<span class="red">*</span>:</span>
							</el-col>
							<el-col :span="selectInpudSpan" class="selectInpud">
								<el-select v-model="companyId" placeholder="" @change="handleCompanyChange" :disabled="isCompanyid">
									<el-option :key="'placeholder'" :label="'請選擇'" :value="null"></el-option>
									<el-option
										v-for="item in companyOptions"
										:key="item.companyId"
										:label="item.companyName"
										:value="item.companyId"
										:disabled="item.disabled"
									>
									</el-option>
								</el-select>
							</el-col>
							<el-col :span="selectTitleSpan" class="selectTitle">
								<span>分店<span class="red">*</span>:</span>
							</el-col>
							<el-col :span="selectInpudSpan" class="selectInpud">
								<el-select v-model="branchId" placeholder="" :disabled="isBranchid">
									<el-option :key="'placeholder'" :label="'請選擇'" :value="null"></el-option>
									<el-option
										v-for="item in branchOptions"
										:key="item.branchId"
										:label="item.branchName"
										:value="item.branchId"
										:disabled="item.disabled"
									>
									</el-option>
								</el-select>
							</el-col>
						</el-row>
						<el-row>
							<!-- 隱藏的假欄位 -->
							<el-col :span="selectTitleSpan" class="selectTitle">
								<span>帳號<span class="red">*</span>:</span>
							</el-col>
							<el-col :span="selectInpudSpan" class="selectInpud">
								<el-input
									v-model="userName"
									placeholder="請輸入帳號"
									@input="validateUsername"
									autocomplete="off"
								></el-input>
							</el-col>
							<el-col :span="selectTitleSpan" class="selectTitle">
								<span>密碼<span class="red">*</span>:</span>
								<!--                                <tr><span class="red" style="font-size: 10px">(至少8碼，需包含大小寫，數字，特殊符號)</span></tr>-->
							</el-col>
							<el-col :span="selectInpudSpan" class="selectInpud">
								<el-input
									:type="inputType"
									v-model="password"
									placeholder="需包含大小寫,數字,特殊符號"
									style="width: calc(100% - 30px)"
									autocomplete="new-password"
								></el-input>
								<span class="eye-icon" @click="togglePasswordVisibility"> {{ passwordVisible ? '👁️' : ' 👁️‍🗨️' }}</span>
							</el-col>
							<el-col :span="selectTitleSpan" class="selectTitle">
								<span>是否啟用<span class="red">*</span>:</span>
							</el-col>
							<el-col :span="selectInpudSpan" class="selectInpud">
								<el-radio-group v-model="isActive">
									<el-radio label="1">啟用</el-radio>
									<el-radio label="0">不啟用</el-radio>
								</el-radio-group>
							</el-col>
						</el-row>
						<el-row>
							<el-col :span="selectTitleSpan" class="selectTitle">
								<span>角色<span class="red">*</span>:</span>
							</el-col>
							<el-col :span="selectInpudSpan" class="selectInpud">
								<el-select v-model="role" placeholder="">
									<el-option :key="'placeholder'" :label="'請選擇'" :value="null"></el-option>
									<el-option
										v-for="item in roleOptions"
										:key="item.role"
										:label="item.roleName"
										:value="item.role"
										:disabled="item.disabled"
									>
									</el-option>
								</el-select>
							</el-col>
							<el-col :span="selectTitleSpan" class="selectTitle">
								<span>帳號狀態<span class="red">*</span>:</span>
							</el-col>
							<el-col :span="selectInpudSpan" class="selectInpud">
								<el-radio-group v-model="state">
									<el-radio label="0">鎖定</el-radio>
									<el-radio label="1">使用中</el-radio>
								</el-radio-group>
							</el-col>
							<el-col :span="selectTitleSpan" class="selectTitle">
								<span>信箱:</span>
							</el-col>
							<el-col :span="selectInpudSpan" class="selectInpud">
								<el-input v-model="mail" placeholder="請輸入信箱" v-on:change="validateEmail"></el-input>
							</el-col>
						</el-row>
						<el-row>
							<el-col :span="selectTitleSpan" class="selectTitleMid">
								<span>員工詳細資料</span>
							</el-col>
						</el-row>
						<el-row>
							<el-col :span="selectTitleSpan" class="selectTitle">
								<span>姓:</span>
							</el-col>
							<el-col :span="selectInpudSpan" class="selectInpud">
								<el-input v-model="name" placeholder="請輸入姓"></el-input>
							</el-col>
							<el-col :span="selectTitleSpan" class="selectTitle">
								<span>名:</span>
							</el-col>
							<el-col :span="selectInpudSpan" class="selectInpud">
								<el-input v-model="lastName" placeholder="請輸入名"></el-input>
							</el-col>
							<el-col :span="selectTitleSpan" class="selectTitle">
								<span>暱稱<span class="red">*</span>:</span>
							</el-col>
							<el-col :span="selectInpudSpan" class="selectInpud">
								<el-input v-model="nickName" placeholder="請輸入暱稱" @input="validateName"></el-input>
							</el-col>
						</el-row>
						<el-row>
							<el-col :span="selectTitleSpan" class="selectTitle">
								<span>性別:</span>
							</el-col>
							<el-col :span="selectInpudSpan" class="selectInpud">
								<el-radio-group v-model="gender">
									<el-radio label="1">男</el-radio>
									<el-radio label="0">女</el-radio>
								</el-radio-group>
							</el-col>
						</el-row>
					</div>
				</el-col>
			</el-row>
			<div slot="footer" class="popBatton">
				<el-button @click="addTemplate" type="primary">儲存</el-button>
				<el-button @click="closeDialog">關閉</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
export default {
	name: 'addStaffDialog',
	components: {},
	props: {
		addDialog: null,
	},
	data() {
		return {
			dialogFull: false,
			loginReturnDto: null,
			token: null,
			companyOptions: [],
			branchOptions: [],
			roleOptions: [],
			userName: null,
			password: null,
			passwordVisible: false,
			errorCount: 0,
			companyId: null,
			branchId: null,
			selectCompanyId: null,
			selectRolePowerCompanyId: null,
			selectBranchId: null,
			role: null,
			isActive: '1',
			state: '1',
			mail: null,
			name: null,
			lastName: null,
			nickName: null,
			gender: '1',
			reload: false,
			loginRole: null,
			//是否需要公司ID
			isCompanyid: true,
			//是否需要分店ID
			isBranchid: true,
			isSystem: false,
		}
	},
	computed: {
		inputType() {
			return this.passwordVisible ? 'text' : 'password'
		},
	},
	mounted() {},
	methods: {
		init() {
			this.selectCompanyId = null
			this.selectBranchId = null
			this.companyId = null
			this.branchId = null
			this.isCompanyid = JSON.parse(localStorage.getItem('isCompanyid'))
			this.isBranchid = JSON.parse(localStorage.getItem('isBranchid'))
			console.log('addStaffDialog取得isCompanyid=' + this.isCompanyid)
			console.log('addStaffDialog取得isBranchid=' + this.isBranchid)
			const loginReturnDto = localStorage.getItem('loginReturnDto')
			if (loginReturnDto != null && JSON.parse(loginReturnDto) != null) {
				this.loginReturnDto = JSON.parse(loginReturnDto)
				this.token = this.loginReturnDto.token
				if (this.isCompanyid) {
					this.companyId = this.loginReturnDto.companyId
					this.selectCompanyId = this.companyId
					this.selectRolePowerCompanyId = this.companyId
					console.log('addCompanyDialog取得loginReturnDto.companyId=' + this.companyId)
				} else {
					//包含系統角色
					this.isSystem = true
				}
				if (this.isBranchid) {
					this.branchId = this.loginReturnDto.branchId
					this.selectBranchId = this.branchId
				}
				this.loginRole = this.loginReturnDto.role
				console.log('editCompanyDialog取得loginRole=' + this.loginRole)
			}
			this.searchCompanyList()
			this.searchBranchList()
			this.searchRoleOptions()
		},
		closeDialog() {
			console.log('關閉Pop')
			this.$emit('update:reload', this.reload)
			this.$emit('closeDialog')
		},
		async addTemplate() {
			//新增資料
			let tmp = this
			let url = 'staff/addStaff'
			try {
				console.log('this.nickName=' + this.nickName)
				var user = {
					name: this.name,
					lastName: this.lastName,
					nickName: this.nickName,
					gender: this.gender == null ? null : parseInt(this.gender, 10),
				}
				let parameter = {
					userName: this.userName,
					password: this.password,
					errorCount: this.errorCount,
					isActive: this.isActive == null ? null : this.isActive == '0' ? false : true,
					companyId: this.companyId,
					branchId: this.branchId,
					role: this.role == null ? null : parseInt(this.role, 10),
					state: this.state == null ? null : parseInt(this.state, 10),
					mail: this.mail,
					user: user,
				}
				if (!this.checkAddParameter(parameter)) {
					console.log('新增驗證資料失敗!')
					return
				}
				this.reload = true
				tmp.loadingView()
				let responseData = await tmp.$api.doPostByMsgToken(parameter, url, this.token)
				if (responseData) {
					// tmp.$api.openMsg("新增資料完成!", "success");
					console.log('新增資料完成!')
					// 在延迟 2000 毫秒（2 秒）后执行代码
					setTimeout(function () {
						tmp.closeDialog()
						// tmp.$emit('init');
					}, 1000)
				} else {
					//tmp.$api.openMsg("新增資料失敗!", "error");
					console.log('新增資料失敗!')
				}
			} catch (error) {
				console.log('新增資料發生異常! error=' + error)
			} finally {
				tmp.closeLoading()
			}
		},
		checkAddParameter(parameter) {
			console.log('開始資料驗證! parameter=', parameter)
			var ststus = true
			if (parameter != null && parameter != '') {
				if (parameter.userName == null || !parameter.userName) {
					console.log('帳號不可為空!')
					this.$api.openMsg('帳號不可為空!', 'error')
					return false
				}
				if (parameter.password == null || !parameter.password) {
					console.log('密碼不可為空!')
					this.$api.openMsg('密碼不可為空!', 'error')
					return false
				} else if (!this.validatePassword()) {
					console.log('密碼設定錯誤!')
					return false
				}

				if (parameter.companyId == null || !parameter.companyId) {
					console.log('所屬公司不可為空!')
					this.$api.openMsg('所屬公司不可為空!', 'error')
					return false
				}
				if (parameter.branchId == null || !parameter.branchId) {
					console.log('所屬分店不可為空!')
					this.$api.openMsg('所屬分店不可為空!', 'error')
					return false
				}

				console.log('角色=' + parameter.role)
				if (parameter.role == null) {
					console.log('角色不可為空!')
					this.$api.openMsg('角色不可為空!', 'error')
					return false
				}
				var user = parameter.user
				if (user.nickName == null || !user.nickName) {
					console.log('暱稱不可為空!')
					this.$api.openMsg('暱稱不可為空!', 'error')
					return false
				}
			} else {
				console.log('必填資料不可為空!')
				this.$api.openMsg('必填資料不可為空!', 'error')
				return false
			}
			return ststus
		},
		loadingView() {
			//開啟載入特效
			this.loadingStatus = this.$loading({
				lock: true,
				text: 'Loading',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)',
			})
		},
		closeLoading() {
			//關閉載入特效
			if (this.loadingStatus != null) {
				this.loadingStatus.close()
			}
		},
		async searchCompanyList() {
			//查詢全部公司下拉選單資料
			let tmp = this
			let url = 'login/search/searchCompanyOptions'
			let parameter = {
				companyId: this.selectCompanyId,
				companyName: '',
				isActive: true,
			}
			//取得列表資料
			let responseData = await tmp.$api.doPost(parameter, url)
			if (responseData != null) {
				tmp.companyOptions = responseData
				console.log('取得下拉選單資料 companyOptions=' + tmp.companyOptions)
			}
		},
		async searchBranchList() {
			//查詢全部分店下拉選單資料
			let tmp = this
			let url = 'login/search/searchBranchOptions'
			let parameter = {
				companyId: this.selectCompanyId,
				branchId: this.selectBranchId,
				branchName: '',
				isActive: true,
			}
			//取得列表資料
			let responseData = await tmp.$api.doPostByToken(parameter, url, this.token)
			if (responseData != null) {
				tmp.branchOptions = responseData
				console.log('取得下拉選單資料 companyOptions=' + tmp.companyOptions)
			}
		},
		async searchRoleOptions() {
			//查詢角色下拉選單資料
			let tmp = this
			let url = 'rolePower/search/rolePower'
			let parameter = {
				roleName: null,
				role: null,
				isSystem: tmp.isSystem,
				companyId: this.selectRolePowerCompanyId,
			}
			//取得列表資料
			let responseData = await tmp.$api.doPostByToken(parameter, url, this.token)
			if (responseData != null) {
				tmp.roleOptions = responseData
				console.log('取得角色下拉選單資料 roleOptions=' + tmp.roleOptions)
			}
		},
		togglePasswordVisibility() {
			//檢視密碼
			this.passwordVisible = !this.passwordVisible
		},
		handleDateChange(date) {
			if (date) {
				// 將 Date 對象轉換為字串，格式為 YYYY-MM-DD
				this.formattedBirthday = date.toISOString().slice(0, 10)
			} else {
				this.formattedBirthday = ''
			}
		},
		handleCompanyChange(value) {
			this.branchId = null
			console.log('Selected company ID:', value)
			//系統管理員權限時會查詢一次該公司所屬的分店
			if (!this.isCompanyid) {
				this.selectCompanyId = value
				this.selectRolePowerCompanyId = value
				this.selectBranchId = null
				this.searchBranchList()
				this.role = null
				this.searchRoleOptions()
			}
		},
		validateUsername() {
			// 使用正則表達式檢查用戶名是否僅包含英數字大小寫以及 "-" 和 "_"
			// this.userName = this.userName.replace(/[^a-zA-Z0-9-_]/g, '');
			this.userName = this.$api.validateInput(this.userName, 'userName')
		},
		validatePassword() {
			// 密码长度至少为8个字符
			if (this.password.length < 8) {
				this.$api.openMsg('密碼長度至少為8個字符!', 'error')
				console.log('密碼長度至少為8個字符')
				return false
			}
			// 包含至少一个数字
			if (!/\d/.test(this.password)) {
				this.$api.openMsg('密碼需包含至少一個數字!', 'error')
				console.log('密碼需包含至少一個數字')
				return false
			}
			// 包含至少一个小写字母
			if (!/[a-z]/.test(this.password)) {
				this.$api.openMsg('密碼需包含至少一個小寫字母!', 'error')
				console.log('密碼需包含至少一個小寫字母')
				return false
			}
			// 包含至少一个大写字母
			if (!/[A-Z]/.test(this.password)) {
				this.$api.openMsg('密碼需包含至少一個大寫字母!', 'error')
				console.log('密碼需包含至少一個大寫字母')
				return false
			}
			// 包含至少一个特殊字符
			if (!/[^a-zA-Z0-9]/.test(this.password)) {
				this.$api.openMsg('密碼需包含至少一個特殊字符!', 'error')
				console.log('密碼需包含至少一個特殊字符')
				return false
			}
			return true
		},
		validateName() {
			this.nickName = this.$api.validateInput(this.nickName, 'name')
		},
		validateEmail() {
			this.mail = this.$api.validateInput(this.mail, 'email')
		},
	},
}
</script>

<style scoped></style>
