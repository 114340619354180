<template>
	<div class="p1Div">
		<el-row>
			<el-col :span="24">
				<MenuPath></MenuPath>
			</el-col>
		</el-row>
		<div class="containerDiv">
			<el-row>
				<el-col :span="selectTitleSpan" class="selectTitle">
					<span>公司:</span>
				</el-col>
				<el-col :span="selectInpudSpan" class="selectInpud">
					<el-select v-model="companyId" placeholder="" @change="handleCompanyChange" :disabled="isCompanyid">
						<el-option :key="'placeholder'" :label="'請選擇'" :value="null"></el-option>
						<el-option
							v-for="item in companyOptions"
							:key="item.companyId"
							:label="item.companyName"
							:value="item.companyId"
							:disabled="item.disabled"
						>
						</el-option>
					</el-select>
				</el-col>
				<el-col :span="selectTitleSpan" class="selectTitle">
					<span>分店:</span>
				</el-col>
				<el-col :span="selectInpudSpan" class="selectInpud">
					<el-select v-model="branchId" placeholder="" :disabled="isBranchid">
						<el-option :key="'placeholder'" :label="'請選擇'" :value="null"></el-option>
						<el-option
							v-for="item in branchOptions"
							:key="item.branchId"
							:label="item.branchName"
							:value="item.branchId"
							:disabled="item.disabled"
						>
						</el-option>
					</el-select>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="selectTitleSpan" class="selectTitle">
					<span>開始時間:</span>
				</el-col>
				<el-col :span="selectInpudSpan" class="selectInpud">
					<el-date-picker v-model="startDate" type="datetime" placeholder="選擇日期和時間"> </el-date-picker>
				</el-col>
				<el-col :span="selectTitleSpan" class="selectTitle">
					<span>結束時間:</span>
				</el-col>
				<el-col :span="selectInpudSpan" class="selectInpud">
					<el-date-picker v-model="endDate" type="datetime" placeholder="選擇日期和時間"> </el-date-picker>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="selectTitleSpan" class="selectTitle">
					<span>搜索條件:</span>
				</el-col>
				<el-col :span="selectInpudSpan" class="selectInpud">
					<el-input v-model="nickName" placeholder="請輸入暱稱/服務項目/資產識別碼"></el-input>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="selectBattonSpan" class="selectBatton">
					<div class="grid-content bg-purple-light">
						<el-button @click="init()" type="primary" size="medium">查詢 </el-button>
					</div>
				</el-col>
				<el-col :span="selectBattonSpan" class="selectBatton">
					<div class="grid-content bg-purple-light">
						<el-button @click="clearData()" type="primary" size="medium">清除 </el-button>
					</div>
				</el-col>
			</el-row>
		</div>
		<el-row>
			<el-col :span="24" class="selectTitle">
				<span>本次查詢的總佣金數:{{ totalCommission }}</span>
			</el-col>
		</el-row>
		<el-row>
			<el-col :span="24">
				<TableTemplate
					ref="tableTemplate"
					:tableData="tableData"
					:columns="columns"
					:total="total"
					:loading="loading"
					:defaultsort="defaultsort"
					:currentPage="currentPage"
					:pageSize="pageSize"
					:direction="direction"
					:orderByName="orderByName"
					:checkBoxShow="checkBoxShow"
					:inDetailedButton="inDetailedButton"
					:inEditButton="inEditButton"
					:inDeleteButton="inDeleteButton"
					@getTableDataList="findEntityList"
					@editClick="editClick"
					@deleteClick="deleteClick"
					@detailedClick="detailedClick"
				></TableTemplate>
			</el-col>
		</el-row>
	</div>
</template>

<script>
import TableTemplate from '@/components/element/TableTemplate.vue'
import MenuPath from '@/components/element/MenuPath.vue'
import tableColumns from '@/assets/js/commissionHomeTable.js'

export default {
	name: 'commissionHome',
	components: {
		MenuPath,
		TableTemplate,
	},
	data() {
		return {
			//表頭設定
			columns: [],
			//table數據
			tableData: [],
			//總筆數
			total: 0,
			//抽成總佣金
			totalCommission: 0,
			//是否啟用載入動畫效果
			loading: false,
			//預設排序
			defaultsort: {prop: 'orderId', order: 'descending'},
			// 当前页
			currentPage: 1,
			//每页多少条
			pageSize: 10,
			//排序:ASC,DESC
			direction: 'DESC',
			//依據屬性欄位排序
			orderByName: 'orderId',
			checkBoxShow: false,
			editDialog: false,
			addDialog: false,
			detailedDialog: false,
			token: null,
			companyOptions: [],
			branchOptions: [],
			orderId: null,
			companyId: null,
			branchId: null,
			staffId: null,
			role: null,
			nickName: null,
			startDate: null,
			endDate: null,
			loginReturnDto: null,
			addButton: null,
			//按鈕
			inDetailedButton: null,
			inEditButton: null,
			inDeleteButton: null,
			reload: false,
			permissionControlList: null,
			page: 'COMMISSION',
			functionCodes: ['ADD', 'QRY', 'EDIT', 'DEL', 'VIEW'],
			//是否需要公司ID
			isCompanyid: true,
			//是否需要分店ID
			isBranchid: true,
		}
	},
	mounted() {
		this.columns = tableColumns
		this.init()
	},
	methods: {
		init() {
			this.isCompanyid = JSON.parse(localStorage.getItem('isCompanyid'))
			this.isBranchid = JSON.parse(localStorage.getItem('isBranchid'))
			console.log('commissionHome取得isCompanyid=' + this.isCompanyid)
			console.log('commissionHome取得isBranchid=' + this.isBranchid)
			// 從 Local Storage 獲取 loginReturnDto
			this.loginReturnDto = localStorage.getItem('loginReturnDto')
			if (this.loginReturnDto != null && JSON.parse(this.loginReturnDto) != null) {
				this.loginReturnDto = JSON.parse(this.loginReturnDto)
				this.token = this.loginReturnDto.token
				this.role = this.loginReturnDto.role
				if (this.isCompanyid) {
					this.companyId = this.loginReturnDto.companyId
					console.log('commissionHome取得loginReturnDto.companyId=' + this.companyId)
				}
				if (this.isBranchid) {
					this.branchId = this.loginReturnDto.branchId
					console.log('commissionHome取得loginReturnDto.branchId=' + this.branchId)
				}
			} else {
				console.log('commissionHome未取得token資料，回到登入頁面!')
				this.$api.openMsg('尚未登入完成，請重新登入!', 'error')
				this.$router.push({path: '/login'})
				return
			}
			//權限判斷
			this.checkPermission()
			this.searchCompanyList()
			this.searchBranchList()
			this.currentPage = 1
			this.pageSize = 10
			this.findEntityList(this.currentPage, this.pageSize, this.orderByName, this.direction)
		},
		checkPermission() {
			//權限判斷
			const permissionControl = localStorage.getItem('permissionControl')
			if (permissionControl != null && JSON.parse(permissionControl) != null) {
				this.permissionControlList = JSON.parse(permissionControl)
				console.log('companyHome取得permissionControlList=' + this.permissionControlList.length)
			}
			if (this.permissionControlList != null && this.permissionControlList.length > 0) {
				const results = this.$api.findAndCategorizePermissions(
					this.permissionControlList,
					this.page,
					this.functionCodes
				)
				this.addButton = this.$api.hasDataForSpecificFunctionCode(results, 'ADD') ? true : false
				this.qryButton = this.$api.hasDataForSpecificFunctionCode(results, 'QRY') ? true : false
				this.inEditButton = this.$api.hasDataForSpecificFunctionCode(results, 'EDIT') ? true : false
				this.inDeleteButton = this.$api.hasDataForSpecificFunctionCode(results, 'DEL') ? true : false
				this.inDetailedButton = this.$api.hasDataForSpecificFunctionCode(results, 'VIEW') ? true : false
			}
			console.log('commissionHome取得addButton=' + this.addButton)
			console.log('commissionHome取得qryButton=' + this.qryButton)
			console.log('commissionHome取得inEditButton=' + this.inEditButton)
			console.log('commissionHome取得inDeleteButton=' + this.inDeleteButton)
			console.log('commissionHome取得inDetailedButton=' + this.inDetailedButton)
			if (!this.inEditButton && !this.inDetailedButton && !this.inDeleteButton) {
				//按鈕都隱藏時，不顯示操作欄位
				this.columns = this.columns.filter((column) => column.prop !== 'action')
			}
		},
		async findEntityList(currentPage, pageSize, orderByName, direction) {
			/**
			 * vue axios 同步請求
			 * **/
			console.log('findEntityList in .....')
			console.log('currentPage=' + currentPage)
			console.log('pageSize=' + pageSize)
			console.log('orderByName=' + orderByName)
			console.log('direction=' + direction)
			if (!this.qryButton) {
				console.log('登入者無查詢權限!')
				this.$api.openMsg('登入者無查詢權限!', 'error')
				return
			}
			this.currentPage = currentPage
			this.pageSize = pageSize
			this.orderByName = orderByName
			this.direction = direction
			this.loading = true
			let tmp = this
			let url = 'order/search/employeeCommission'
			let parameter = {
				orderId: tmp.orderId,
				companyId: tmp.companyId,
				branchId: tmp.branchId,
				staffId: tmp.staffId,
				nickName: tmp.nickName,
				startDate: tmp.startDate,
				endDate: tmp.endDate,
				pageNo: tmp.currentPage,
				dataSize: tmp.pageSize,
				orderByName: tmp.orderByName,
				direction: tmp.direction,
			}
			//取得列表資料
			let responseData = await tmp.$api.doPostByToken(parameter, url, this.token)
			if (responseData != null) {
				tmp.tableData = responseData
				if (tmp.tableData != null && tmp.tableData.length > 0) {
					tmp.total = tmp.tableData[0].totalRecords
					tmp.totalCommission = tmp.tableData[0].totalCommission
					console.log('total=' + tmp.total)
				} else {
					tmp.total = 0
					console.log('total=' + tmp.total)
				}
			}
			this.loading = false
			console.log('asyncTest----end')
		},
		clearData() {
			//清除查詢資料
			if (this.isCompanyid) {
				this.companyId = this.loginReturnDto.companyId
				console.log('commissionHome取得loginReturnDto.companyId=' + this.companyId)
			} else {
				this.companyId = null
			}
			if (this.isBranchid) {
				this.branchId = this.loginReturnDto.branchId
				console.log('commissionHome取得loginReturnDto.branchId=' + this.branchId)
			} else {
				this.branchId = null
			}
			this.nickName = null
			this.startDate = null
			this.endDate = null
		},
		closeDialog() {
			this.editDialog = false
			this.addDialog = false
			this.detailedDialog = false
			console.log('reload=' + this.reload)
			//判斷是否需要重載畫面
			if (this.reload) {
				window.location.reload()
			}
		},
		editClick(row) {
			console.log('編輯 row=' + row)
		},
		detailedClick(row) {
			//詳細頁
			console.log('詳細頁 row=' + row)
		},
		async deleteClick(row) {
			//刪除
			console.log('刪除 row=' + row)
		},
		add() {
			console.log('新增...role=' + this.role)
		},
		handleReloadUpdate(newValue) {
			this.reload = newValue
		},
		async searchCompanyList() {
			//查詢全部公司下拉選單資料
			let tmp = this
			let url = 'login/search/searchCompanyOptions'
			let parameter = {
				companyId: this.companyId,
				companyName: '',
				isActive: true,
			}
			//取得列表資料
			let responseData = await tmp.$api.doPost(parameter, url)
			if (responseData != null) {
				tmp.companyOptions = responseData
				console.log('取得下拉選單資料 companyOptions=' + tmp.companyOptions)
			}
		},
		async searchBranchList() {
			//查詢全部分店下拉選單資料
			let tmp = this
			let url = 'login/search/searchBranchOptions'
			let parameter = {
				companyId: this.companyId,
				branchId: this.branchId,
				branchName: '',
				isActive: true,
			}
			//取得列表資料
			let responseData = await tmp.$api.doPostByToken(parameter, url, this.token)
			if (responseData != null) {
				tmp.branchOptions = responseData
				console.log('取得下拉選單資料 companyOptions=' + tmp.companyOptions)
			}
		},
		handleCompanyChange(value) {
			console.log('Selected company ID:', value)
			console.log('staffHome取得isCompanyid=' + this.isCompanyid)
			//系統管理員權限時會查詢一次該公司所屬的分店
			if (!this.isCompanyid) {
				this.companyId = value
				this.branchId = null
				this.searchBranchList()
			}
		},
	},
}
</script>

<style>
.p1Div {
        width: 99%;
    }

    /**日期選擇器**/
    .selectInpud .el-date-editor {
        width: 100%;
    }
</style>
