<template>
	<div>
		<el-row style="height: 40px; margin-top: 0px; margin-left: 0px">
			<el-col :span="menuPathButton" style="text-align: center; margin-left: 15px; margin-top: 10px">
				<el-button v-model="isCollapse" v-on:click="startIsCollapse()" style="font-size: 12px"
					><i class="el-icon-menu"></i
				></el-button>
			</el-col>
			<el-col :span="menuPathDiv" style="height: 40px; margin-top: 15px">
				<el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb">
					<el-breadcrumb-item v-for="(iten, index) in breadcrumbListData" :key="index">
						<span class="nav-span" v-on:click="handleLink(iten)">{{ iten.name}}</span>
					</el-breadcrumb-item>
				</el-breadcrumb>
			</el-col>
		</el-row>
		<el-row style="margin-top: 0px">
			<el-col :span="24">
				<el-divider></el-divider>
			</el-col>
		</el-row>
	</div>
</template>

<!-- meun 麵包屑-->
<script>
export default {
	name: 'MenuPath',
	data() {
		return {
			menuPath: '',
			breadcrumbListData: [],
			isCollapse: true,
		}
	},
	mounted() {
		this.init()
	},
	methods: {
		init() {
			//Home:主框架模板頁面
			for (let i = 0; i < this.$GLOBAL.breadcrumbListData.length; i++) {
				if (this.$GLOBAL.breadcrumbListData[i].path != '/home') {
					this.breadcrumbListData.push(this.$GLOBAL.breadcrumbListData[i])
				}
			}
		},
		handleLink(iten) {
			console.log('iten.path=' + iten.path)
			if (iten.meta.title != null) {
				this.$router.push({path: iten.path}).catch(() => {})
			}
		},
		startIsCollapse() {
			//menu展開/縮小
			this.isCollapse = !this.isCollapse
			this.$eventBus.$emit('MenuTemplate') // 触发事件
			this.$eventBus.$emit('Home') // 触发事件
		},
	},
}
</script>

<style scoped>
.breadcrumb {
    display: flex;
    flex-wrap: wrap;
    /*padding: 20px;*/
		padding: 5px;
    margin-bottom: 1rem;
    list-style: none;
    background-color: #ffffff;
    border-radius: .25rem;
    font-size: 20px;
		/* margin-top: 5px; */
  }

  /* For small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    .breadcrumb {
      font-size: 16px;
    }
  }

  /* For medium devices (tablets, 600px to 1024px) */
  @media only screen and (min-width: 601px) and (max-width: 1024px) {
    .breadcrumb {
      font-size: 18px;
    }
  }

  /* For large devices (desktops, 1025px and up) */
  @media only screen and (min-width: 1025px) {
    .breadcrumb {
      font-size: 20px;
    }
  }

  .el-divider--horizontal {
    display: block;
    height: 1px;
    width: 100%;
    margin: 0px;
  }

  span:hover {
    /*color: #1E90FF;*/
    text-decoration: underline;
    color: #1E90FF;
    padding: 2px;
  }
</style>
