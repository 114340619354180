<template>
	<div id="app">
		<router-view />
		<!--    本專案各版本資訊:-->
		<!--    1.vue版本:2.6.14-->
		<!--    2.vue/cli版本:4.5.13-->
		<!--    3.npm版本:8.19.4-->
		<!--    4.node版本:V16.20.2-->
	</div>
</template>

<script>
export default {
	name: 'App',
	components: {},
}
</script>
