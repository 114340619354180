export default [
	{
		prop: 'companyId',
		label: '公司ID',
		minWidth: '200', //列寬
		showOverflowTooltip: false, //設置表格文字過長顯示省略號
		align: 'center', //對齊方式
		sortable: true,
	},
	{
		prop: 'companyName',
		label: '公司名稱',
		minWidth: '350', //列寬
		showOverflowTooltip: false, //設置表格文字過長顯示省略號
		align: 'center', //對齊方式
		sortable: true,
	},
	{
		prop: 'branchId',
		label: '分店ID',
		minWidth: '200', //列寬
		showOverflowTooltip: false, //設置表格文字過長顯示省略號
		align: 'center', //對齊方式
		sortable: true,
	},
	{
		prop: 'branchName',
		label: '分店名稱',
		minWidth: '350', //列寬
		showOverflowTooltip: false, //設置表格文字過長顯示省略號
		align: 'center', //對齊方式
		sortable: true,
	},
	{
		prop: 'address',
		label: '分店地址',
		minWidth: '350',
		showOverflowTooltip: false,
		align: 'center',
		sortable: true,
	},
	{
		prop: 'isActiveString',
		label: '是否啟用',
		minWidth: '250',
		showOverflowTooltip: false,
		align: 'center',
		sortable: true,
	},
	{
		prop: 'action',
		label: '操作',
		minWidth: '400',
		showOverflowTooltip: true,
		align: 'center',
		render: 'action',
		sortable: false,
	},
]
