<template>
	<div class="header">
		<el-row :gutter="20">
			<el-col :span="headerTitleSpan">
				<div class="header-left">
					<span class="header-title">{{ headerTitle }}</span>
					<span class="header-version">{{ version }}</span>
				</div>
			</el-col>
			<el-col :span="headerNicknameSpan">
				<div class="header-right">
					<span class="header-nickname">{{ nickName }}</span>
					<el-button type="primary" class="logoOut" @click="logoOut">登出</el-button>
				</div>
			</el-col>
		</el-row>
	</div>
</template>
<script>
import responsiveSpan from '@/assets/js/responsiveSpan.js'
export default {
	mixins: [responsiveSpan],
	name: 'Header',
	components: {
		// LangTemplate
	},
	data() {
		return {
			version: null,
			loginReturnDto: null,
			nickName: null,
			headerTitle: null,
			title:null,
			isSmallScreen: window.innerWidth < 600,
		}
	},
	computed: {

	},
	mounted() {
		this.init()
		window.addEventListener('resize', this.handleResize);
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.handleResize);
	},
	methods: {
		init() {
			this.version = this.$version
			this.headerTitle = JSON.parse(localStorage.getItem('title'))
			document.title = this.headerTitle
			this.headerTitle=this.headerTitle.replace("股份有限公司","").replace("有限公司","");
			console.log('Home取得headerTitle=' + this.headerTitle)
			// 從 Local Storage 獲取 loginReturnDto
			const loginReturnDto = localStorage.getItem('loginReturnDto')
			if (loginReturnDto) {
				this.loginReturnDto = JSON.parse(loginReturnDto)
			}
			console.log('Header取得loginReturnDto=' + this.loginReturnDto)
			if (this.loginReturnDto != null) {
				this.nickName = this.loginReturnDto.nickName
				console.log('Home取得nickName=' + this.nickName)
			}
		},
		handleResize() {
			this.isSmallScreen = window.innerWidth < 600 ? true:false;
			if(this.isSmallScreen){
				this.title=this.headerTitle.length > 3 ? this.headerTitle.slice(0, 3) + '...' : this.headerTitle;
			}else{
				this.isSmallScreen=false;
				this.title=this.headerTitle;
			}
			console.log('windowWidth=' + this.windowWidth+",isSmallScreen="+this.isSmallScreen)
		},
		logoOut() {
			localStorage.setItem('loginReturnDto', null)
			localStorage.setItem('permissionControl', null)
			this.$router.push({path: '/login'})
			// const loginName = localStorage.getItem('loginName');
			// var loginNameDto;
			// if (loginName != null && JSON.parse(loginName) != null) {
			//     loginNameDto = JSON.parse(loginName);
			// }
			// if(loginNameDto == 'login'){
			//     localStorage.setItem('loginName', null);
			//     this.$router.push({path: '/login'});
			// }
			// if(loginNameDto == 'login1'){
			//     localStorage.setItem('loginName', null);
			//     this.$router.push({path: '/login1'});
			// }
		},
	},
}
</script>

<style scoped>
.header {
        /*background: #2554FF; !* 确保背景颜色设置正确 *!*/
        /*padding: 10px 0;*/
        position: relative; /* 确保元素是正常流中的块级元素 */
        z-index: 1; /* 设置较高的层叠顺序 */
    }

    .header-left {
        display: flex;
        align-items: center;
    }

    .header-right {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-right: 10px;
    }

    .header-nickname {
        margin-right: 10px;
    }

    /*.header-title {*/
    /*    font-size: 30px;*/
    /*    margin-left: 30px;*/
    /*}*/

    /*.header-version {*/
    /*    font-size: 30px;*/
    /*    margin-left: 10px;*/
    /*}*/

    /* For small devices (phones, 600px and down) */
    @media only screen and (max-width: 600px) {
        .header-title {
            font-size: 12px;
            margin-left: 5px;
        }
        .header-version {
            font-size: 12px;
            margin-left: 5px;
        }
        .header-nickname {
            font-size: 12px;
            margin-right: 10px;
        }
        .logoOut{
            width: 40%;
            font-size: 12px;
            margin-left: 1px;
        }
    }

    /* For medium devices (tablets, 600px to 1024px) */
    @media only screen and (min-width: 601px) and (max-width: 1024px) {
        .header-title {
            font-size: 18px;
            margin-left: 20px;
        }
        .header-version {
            font-size: 18px;
            margin-left: 10px;
        }
        .header-nickname {
            font-size: 14px;
            margin-right: 10px;
        }
        .logoOut{
            width: 40%;
            font-size: 12px;
            margin-left: 5px;
        }
    }

    /* For large devices (desktops, 1025px to 1279px) */
    @media only screen and (min-width: 1025px) and (max-width: 1279px){
        .header-title {
            font-size: 24px;
            margin-left: 30px;
        }
        .header-version {
            font-size: 24px;
            margin-left: 10px;
        }
        .header-nickname {
            font-size:  18px;
            margin-right: 10px;
        }
        .logoOut{
            width: 30%;
            font-size: 14px;
            margin-left: 10px;
        }
    }
    /* For large devices (desktops, 1280px and up) */
    @media only screen and (min-width: 1280px){
        .header-title {
            font-size: 28px;
            margin-left: 30px;
        }
        .header-version {
            font-size: 28px;
            margin-left: 10px;
        }
        .header-nickname {
            font-size:  24px;
            margin-right: 10px;
        }
        .logoOut{
            width: 25%;
            font-size: 16px;
            margin-left: 10px;
        }
    }
</style>
