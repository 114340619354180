<template>
	<div>
		<el-row>
			<el-col :span="selectInpudSpan" class="selectInpud">
				<el-button @click="addInput" style="font-size: 20px; margin: 0px 0px 0px 15px">+</el-button>
			</el-col>
		</el-row>
		<el-row>
			<el-col v-for="(input, index) in inputs" :key="index" class="addInputStyle" :span="assetsModelTypeElCol">
				<el-col :span="assetsModelTypeTitleSpan" class="selectTitle"
					><span>資產類型<span class="red">*</span>:</span>
				</el-col>
				<el-col :span="assetsModelTypeInpudSpan" class="selectInpud">
					<!-- 使用 v-for 指令遍历 inputs 数组并渲染多个 <el-col> 元素 -->
					<el-input
						placeholder="請輸入資產類型"
						name="assetsModelType"
						v-model="input.assetsModel"
						@input="emitInputs"
					></el-input>
				</el-col>
				<el-col :span="assetsModelTypeBattonSpan" class="selectInpud">
					<!-- 添加一个删除按钮 -->
					<el-button @click="removeInput(index)" style="font-size: 20px">-</el-button>
				</el-col>
			</el-col>
		</el-row>
	</div>
</template>

<script>
export default {
	name: 'addAssetsModelType',
	data() {
		return {
			inputs: [{companyId: null, assetsModel: ''}], // 用于存储所有输入框状态的数组
		}
	},
	methods: {
		addInput() {
			// 添加一个新的输入框状态到数组中，每个输入框状态包含一个 value 字段
			this.inputs.push({companyId: null, assetsModel: ''})
			this.emitInputs()
		},
		removeInput(index) {
			// 从数组中移除指定的输入框
			this.inputs.splice(index, 1)
			this.emitInputs()
		},
		emitInputs() {
			// 触发自定义事件，并传递 inputs 数据
			this.$emit('updateAssetsModelInputs', this.inputs)
		},
	},
}
</script>

<style scoped>
.addInputStyle {
        border: 2px solid #b85104;
        background-color: #F5F7FA;
        margin-top: 5px;
        /*text-align: left;*/
        /*margin: 20px 0px 15px 15px;*/
        /*!*margin-bottom: 10px; !* 可选的样式，用于分隔输入框 *!*!*/
        /*display: flex; !* 使输入框和按钮在同一行 *!*/
        /*align-items: center;*/
    }

    /*.addInputStyle .el-input {*/
    /*    flex: 1; !* 输入框占满剩余空间 *!*/
    /*}*/
</style>
