<template>
    <div class="popDialog">
        <el-dialog
                v-if="editDialog"
                width="95%"
                top="30px"
                :visible.sync="editDialog"
                :before-close="closeDialog"
                :fullscreen="dialogFull"
                custom-class="custom-dialog"
        >
            <template v-slot:title>
                <el-row>
                    <el-col :span="24" class="selectTitleMax">
                        <span>編輯工單資料</span>
                    </el-col>
                </el-row>
            </template>
            <el-row>
                <el-col :span="24">
                    <div class="containerDiv">
                        <el-row>
                            <el-col :span="selectTitleSpan" class="selectTitle">
                                <span>公司<span class="red">*</span>:</span>
                            </el-col>
                            <el-col :span="selectInpudSpan" class="selectInpud">
                                <el-select v-model="companyId" placeholder="" @change="handleCompanyChange"
                                           :disabled="true">
                                    <el-option :key="'placeholder'" :label="'請選擇'" :value="null"></el-option>
                                    <el-option
                                            v-for="item in companyOptions"
                                            :key="item.companyId"
                                            :label="item.companyName"
                                            :value="item.companyId"
                                            :disabled="item.disabled"
                                    >
                                    </el-option>
                                </el-select>
                            </el-col>
                            <el-col :span="selectTitleSpan" class="selectTitle">
                                <span>分店<span class="red">*</span>:</span>
                            </el-col>
                            <el-col :span="selectInpudSpan" class="selectInpud">
                                <el-select v-model="branchId" placeholder="" @change="handleBranchChange"
                                           :disabled="true">
                                    <el-option :key="'placeholder'" :label="'請選擇'" :value="null"></el-option>
                                    <el-option
                                            v-for="item in branchOptions"
                                            :key="item.branchId"
                                            :label="item.branchName"
                                            :value="item.branchId"
                                            :disabled="item.disabled"
                                    >
                                    </el-option>
                                </el-select>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="selectTitleSpan" class="selectTitle">
                                <span>會員<span class="red">*</span>:</span>
                            </el-col>
                            <el-col :span="selectInpudSpan" class="selectInpud">
                                <el-select v-model="customerId" placeholder="" :disabled="true"
                                           @change="handleCustomerChange">
                                    <el-option :key="'placeholder'" :label="'請選擇'" :value="null"></el-option>
                                    <el-option
                                            v-for="item in customerOptions"
                                            :key="item.customerId"
                                            :label="item.nickName+'-'+item.userName"
                                            :value="item.customerId"
                                            :disabled="item.disabled"
                                    >
                                    </el-option>
                                </el-select>
                            </el-col>
                            <el-col :span="selectTitleSpan" class="selectTitle">
                                <span>會員資產<span class="red">*</span>:</span>
                            </el-col>
                            <el-col :span="selectInpudSpan" class="selectInpud">
                                <el-select v-model="customerAssetsId" placeholder="" :disabled="true">
                                    <el-option :key="'placeholder'" :label="'請選擇'" :value="null"></el-option>
                                    <el-option
                                            v-for="item in customerAssetsOptions"
                                            :key="item.customerAssetsId"
                                            :label="item.identifier+'('+item.serviceLevel+')'"
                                            :value="item.customerAssetsId"
                                            :disabled="item.disabled"
                                    >
                                    </el-option>
                                </el-select>
                            </el-col>
                            <el-col :span="selectTitleSpan" class="selectTitle">
                                <span>工單狀態<span class="red">*</span>:</span>
                            </el-col>
                            <el-col :span="selectInpudSpan" class="selectInpud">
                                <el-select v-model="orderStatus" placeholder="">
                                    <el-option
                                            v-for="item in orderStatusOptions"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value"
                                            :disabled="item.disabled"
                                    >
                                    </el-option>
                                </el-select>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="selectTitleSpan" class="selectTitle">
                                <span>服務開始時間<span class="red">*</span>:</span>
                            </el-col>
                            <el-col :span="selectInpudSpan" class="selectInpud">
                                <EndDatepicker
                                        :isDisabled="isDisabled"
                                        :companyId="companyId"
                                        :branchId="branchId"
                                        ref="datepicker"
                                        v-if="orderUpdatedData != null"
                                        @updateStartTimeInput="handleUpdateStartTimeInput"
                                ></EndDatepicker>
                            </el-col>
                            <!--                            <el-col :span="selectTitleSpan" class="selectTitle">-->
                            <!--                                <span>折扣率(%):</span>-->
                            <!--                            </el-col>-->
                            <!--                            <el-col :span="selectInpudSpan" class="selectInpud">-->
                            <!--                                <el-input v-model="discountPercentage" placeholder="請輸入折扣率" type="number"-->
                            <!--                                          :disabled="true"></el-input>-->
                            <!--                            </el-col>-->
                            <el-col :span="selectTitleSpan" class="selectTitle">
                                <span>折扣金額:</span>
                            </el-col>
                            <el-col :span="selectInpudSpan" class="selectInpud">
                                <el-input v-model="discountAmount" placeholder="請輸入折扣金額" type="text"
                                          @input="validateDiscountAmount"></el-input>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="selectTitleSpan" class="selectTitle">
                                <span>備註:</span>
                            </el-col>
                            <el-col :span="selectInpudSpan" class="selectInpud">
                                <el-input v-model="remark" placeholder="請輸入備註"></el-input>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="selectTitleSpan" class="selectTitle">
                                <span>總金額:</span>
                            </el-col>
                            <el-col :span="selectInpudSpan" class="selectInpud">
                                <el-input
                                        class="sum"
                                        :value="`${'$'+allSum}`"
                                        :disabled="true"
                                        :style="{visibility: allSum !== null ? 'visible' : 'hidden'}"
                                ></el-input>
                            </el-col>
                            <el-col :span="selectTitleSpan" class="selectTitle">
                                <span>支付金額:</span>
                            </el-col>
                            <el-col :span="selectInpudSpan" class="selectInpud">
                                <el-input
                                        class="sum"
                                        :value="`${'$'+discountSum}`"
                                        :disabled="true"
                                        :style="{visibility: discountSum !== null ? 'visible' : 'hidden'}"
                                ></el-input>
                            </el-col>
                            <el-col :span="selectTitleSpan" class="selectTitle">
                                <span>總工時:</span>
                            </el-col>
                            <el-col :span="selectInpudSpan" class="selectInpud">
                                <el-input
                                        class="sum"
                                        :value="`${allWorkingHours+'分鐘'}`"
                                        :disabled="true"
                                        :style="{visibility: allWorkingHours !== null ? 'visible' : 'hidden'}"
                                ></el-input>
                            </el-col>
                        </el-row>
                        <el-row>
                            <EndOrderWorkUser
                                    :companyId="companyId"
                                    :branchId="branchId"
                                    :token="token"
                                    :mainProject="mainProject"
                                    :orderStatus="orderStatus"
                                    :orderWorkUserInputsResult="orderWorkUserInputsResult"
                                    :assetsModelTypeId="assetsModelTypeId"
                                    ref="orderWorkUser"
                                    @update:assetsModelTypeId="updateAssetsModelTypeId"
                                    @orderWorkUserAddInputs="handleOrderWorkUserAddInputs"
                            ></EndOrderWorkUser>
                            <EndOrderSubWorkUser
                                    v-if="isSubProject"
                                    :companyId="companyId"
                                    :branchId="branchId"
                                    :token="token"
                                    :orderStatus="orderStatus"
                                    :orderMainWorkUserInputs="orderWorkUserInputs"
                                    :assetsModelTypeId="assetsModelTypeId"
                                    ref="orderSubWorkUser"
                                    :orderSubWorkUserInputsResult="orderSubWorkUserInputsResult"
                                    @orderWorkUserAddInputs="handleOrderSubWorkUserAddInputs"
                            ></EndOrderSubWorkUser>
                        </el-row>
                    </div>
                </el-col>
            </el-row>
            <div slot="footer" class="popBatton">
                <el-button @click="addTemplate" type="primary">儲存</el-button>
                <el-button @click="closeDialog">關閉</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import EndOrderWorkUser from '@/views/order/EndOrderWorkUser.vue'
    import EndOrderSubWorkUser from '@/views/order/EndOrderSubWorkUser.vue'
    import EndDatepicker from '@/components/element/EndDatepicker.vue'

    export default {
        name: 'editOrderDialog',
        components: {
            EndOrderWorkUser,
            EndDatepicker,
            EndOrderSubWorkUser,
        },
        props: {
            editDialog: null,
        },
        data() {
            return {
                dialogFull: false,
                loginReturnDto: null,
                token: null,
                companyOptions: [],
                branchOptions: [],
                customerOptions: [],
                customerAssetsOptions: [],
                workOptions: [],
                userOptions: [],
                userSubOptions: [],
                workIds: [],
                staffIds: [],
                orderStatusOptions: [
                    {value: '0', label: '未執行'},
                    {value: '1', label: '執行中'},
                    {value: '2', label: '完成'},
                    {value: '3', label: '取消'},
                ],
                companyId: null,
                branchId: null,
                customerId: null,
                customerAssetsId: null,
                orderStatus: '0',
                discountPercentage: null,
                discountAmount: null,
                startTime: null,
                remark: null,
                workUserList: null,
                subWorkUserList: [],
                assetsModel: null,
                reload: false,
                companyType: null,
                //是否需要公司ID
                isCompanyid: true,
                //是否需要分店ID
                isBranchid: true,
                orderWorkUserInputs: [], // 用于存储从子组件传递过来的输入框数据
                orderSubWorkUserInputs: [], // 用于存储从子组件传递过来的输入框数据
                orderWorkUserInputsResult: [], // 後端傳來的主項目更新資料
                orderSubWorkUserInputsResult: [], // 後端傳來的副項目更新資料
                inDate: null,
                //是否有副項目
                isSubProject: false,
                assetsModelTypeId: null,
                //主項目[新增按鈕]是否顯示
                mainProject: true,
                orderId: null,
                orderUpdatedData: null,
                //項目總金額
                allSum: 0,
                //折扣後金額
                discountSum: 0,
                //項目總工時
                allWorkingHours: 0,
                mainSum: 0,
                mainWorkingHoursAll: 0,
                subSum: 0,
                subWorkingHoursAll: 0,
                rolePower: null,
                //禁用編輯服務開始時間
                isDisabled: true,
            }
        },
        watch: {
            orderUpdatedData: {
                handler(newVal) {
                    if (newVal && newVal != null) {
                        console.log('已收到資料 orderUpdatedData=', newVal);
                        this.initData();
                    }
                },
                immediate: true, // 可設定為 `true`，在初始化時觸發監控
                deep: false // 若需要深度監控，可設置這個參數
            },
            customerAssetsOptions: {
                handler(newVal) {
                    if (newVal && newVal.length > 0) {
                        console.log('已收到資料 customerAssetsOptions=', newVal);
                        this.getAssetsModelTypeId();
                    }
                },
                immediate: true, // 可設定為 `true`，在初始化時觸發監控
                deep: false // 若需要深度監控，可設置這個參數
            },
            companyOptions: {
                handler(newVal) {
                    if (newVal && newVal.length > 0) {
                        console.log('已收到資料 companyOptions=', newVal);
                        this.checkIsSubProject();
                    }
                },
                immediate: true, // 如果需要初始化時觸發，設置為 true
                deep: false, // 不需要深度監控
            },
            inDate: {
                handler(newVal) {
                    if (newVal !== null && newVal != '') {
                        const checkDatepickerReady = setInterval(() => {
                            if (this.$refs.datepicker && typeof this.$refs.datepicker.init === 'function') {
                                clearInterval(checkDatepickerReady); // 停止檢查
                                console.log('已收到資料 inDate=', newVal);
                                let tmp = this
                                setTimeout(function () {
                                    tmp.initDatepicker();
                                    console.log('已收到資料 tmp=', tmp);
                                }, 100)
                            }
                        }, 50); // 每50毫秒檢查一次
                    }
                },
                immediate: true, // 如果需要初始化時觸發，設置為 true
                deep: false, // 不需要深度監控
            },
            allSum: {
                handler(newVal) {
                    console.log('總金額 allSum 改變.............allSum=' + newVal);
                    if (newVal != null) {
                        console.log('已收到資料 allSum=', newVal);
                        //計算金額
                        this.discountSumData();
                    }
                },
                immediate: true, // 可設定為 `true`，在初始化時觸發監控
                deep: false // 若需要深度監控，可設置這個參數
            }
        },
        mounted() {
        },
        methods: {
            async init(orderId) {
                this.isCompanyid = JSON.parse(localStorage.getItem('isCompanyid'))
                this.isBranchid = JSON.parse(localStorage.getItem('isBranchid'))
                console.log('editOrderDialog isCompanyid=' + this.isCompanyid)
                console.log('editOrderDialog isBranchid=' + this.isBranchid)
                const loginReturnDto = localStorage.getItem('loginReturnDto')
                if (loginReturnDto != null && JSON.parse(loginReturnDto) != null) {
                    this.loginReturnDto = JSON.parse(loginReturnDto)
                    this.token = this.loginReturnDto.token
                }
                if (orderId != null && orderId != '') {
                    console.log('取得更新的工單資料 orderId=', orderId)
                    await this.getOrder(orderId)
                }
                console.log('editOrderDialog init mainProject=' + this.mainProject)
                this.subSum = 0;
                //this.initData()
                //查詢登入者角色權利資料
                this.getRolePower()
            },
            initData() {
                console.log('initData orderUpdatedData=', this.orderUpdatedData)
                let tmp = this
                // setTimeout(function () {
                try {
                    if (tmp.orderUpdatedData != null) {
                        tmp.orderId = tmp.orderUpdatedData.orderId
                        tmp.companyId = tmp.orderUpdatedData.companyId
                        tmp.branchId = tmp.orderUpdatedData.branchId
                        tmp.customerId = tmp.orderUpdatedData.customerId
                        tmp.customerAssetsId = tmp.orderUpdatedData.customerAssetsId
                        tmp.orderStatus = tmp.orderUpdatedData.orderStatus.toString()
                        tmp.discountPercentage = tmp.orderUpdatedData.discountPercentage
                        tmp.discountAmount = tmp.orderUpdatedData.discountAmount
                        tmp.startTime = tmp.orderUpdatedData.startTime
                        tmp.inDate = tmp.orderUpdatedData.startTime
                        tmp.remark = tmp.orderUpdatedData.remark
                        //tmp.initDatepicker();
                        if (tmp.orderUpdatedData.orderWorkList != null && tmp.orderUpdatedData.orderWorkList.length > 0) {
                            tmp.orderUpdatedData.orderWorkList.forEach((work) => {
                                let orderWorkUserIntDtoList = []
                                if (work.orderWorkUserDtoList != null && work.orderWorkUserDtoList.length > 0) {
                                    orderWorkUserIntDtoList = work.orderWorkUserDtoList.map((user) => user.userId)
                                }
                                let orderWorkData = {
                                    workId: work.workId,
                                    workStatus: work.workStatus.toString(),
                                    orderWorkUserDtoList: orderWorkUserIntDtoList,
                                }
                                tmp.orderWorkUserInputsResult.push(orderWorkData)
                            })
                        }
                        if (tmp.orderUpdatedData.subWorkUserList != null && tmp.orderUpdatedData.subWorkUserList.length > 0) {
                            tmp.orderUpdatedData.subWorkUserList.forEach((work) => {
                                let orderWorkUserIntDtoList = []
                                if (work.orderWorkUserDtoList != null && work.orderWorkUserDtoList.length > 0) {
                                    orderWorkUserIntDtoList = work.orderWorkUserDtoList.map((user) => user.userId)
                                }
                                let orderWorkData = {
                                    workId: work.workId,
                                    workStatus: work.workStatus.toString(),
                                    orderWorkUserDtoList: orderWorkUserIntDtoList,
                                }
                                tmp.orderSubWorkUserInputsResult.push(orderWorkData)
                            })
                        }
                    }
                    //查詢全部公司下拉選單資料
                    tmp.searchCompanyList()
                    //查詢全部分店下拉選單資料
                    tmp.searchBranchList()
                    //查詢公司會員下拉選單資料
                    tmp.searchCustomerOptions()
                    //依據條件查詢會員資產資料
                    tmp.searchAssetsModelTypeList()
                    //判斷公司是否有副項目
                    //tmp.checkIsSubProject();
                    console.log('initData init mainProject=' + tmp.mainProject)
                    //取得資產類型ID
                    // tmp.getAssetsModelTypeId();
                    //取得折扣率或折扣金額
                    //tmp.getDiscount();

                } catch (e) {
                    console.log('載入資料發生錯誤! e=' + e)
                }
                // }, 20)
                // setTimeout(function () {
                //     tmp.discountSumData();
                // }, 100)
            },
            async checkServiceLevelStatus() {
                let levelStatus = false;
                console.log('已收到資料 customerAssetsOptions=', this.customerAssetsOptions + ",customerAssetsId=" + this.customerAssetsId)
                const customerAssets = await this.customerAssetsOptions.find((data) => data.customerAssetsId === this.customerAssetsId)
                // 確保找到了會員資產
                if (customerAssets) {
                    // 取得資產服務等級狀態
                    levelStatus = customerAssets.serviceLevelStatus;
                    console.log("checkServiceLevelStatus levelStatus=" + levelStatus)
                }
                return levelStatus;
            },
            //取得折扣率或折扣金額
            async getDiscount() {
                console.log('-----開始等待 CustomerAssets-----')
                await this.waitForCustomerAssetsOptions();
                console.log('已收到資料 customerAssetsOptions=', this.customerAssetsOptions + ",customerAssetsId=" + this.customerAssetsId)
                const customerAssets = this.customerAssetsOptions.find((data) => data.customerAssetsId === this.customerAssetsId)
                // 確保找到了會員資產
                if (customerAssets) {
                    // 取得資產服務等級狀態
                    const serviceLevelStatus = customerAssets.serviceLevelStatus;
                    if (serviceLevelStatus) {
                        if (customerAssets.discountPercentage != null && customerAssets.discountPercentage != '') {
                            this.discountPercentage = customerAssets.discountPercentage;
                            console.log("折扣率 discountPercentage=" + this.discountPercentage);
                        } else {
                            this.discountPercentage = 0;
                        }
                        if (this.orderUpdatedData.discountAmount == null || this.orderUpdatedData.discountAmount == '') {
                            if (customerAssets.discountAmount != null && customerAssets.discountAmount != '') {
                                this.discountAmount = customerAssets.discountAmount;
                                console.log("折扣金額 discountAmount=" + this.discountAmount);
                            } else {
                                this.discountAmount = 0;
                            }
                        } else {
                            this.discountAmount = this.orderUpdatedData.discountAmount;
                        }
                    }
                    console.log("資產服務等級狀態 serviceLevelStatus=" + serviceLevelStatus)
                }
            },
            async checkIsSubProject() {
                console.log('開始檢查公司副項目，companyId=' + this.companyId);
                if (this.companyOptions && this.companyOptions.length > 0) {
                    const company = this.companyOptions.find(
                        (company) => company.companyId === this.companyId
                    );
                    // 確保找到了公司資料
                    if (company) {
                        this.isSubProject = company.isSubProject;
                        console.log(`公司 ${company.companyName} 是否有副項目: ${this.isSubProject}`);
                    } else {
                        console.log(`找不到 companyId 為 ${this.companyId} 的公司資料`);
                    }
                } else {
                    console.log('尚未獲取到 companyOptions，等待中...');
                }
            },
            //判斷公司是否有副項目
            // async checkIsSubProject() {
            //     console.log('-----開始等待 companyOptions-----')
            //     await this.waitForCompanyOptions();
            //     console.log('已收到資料 companyOptions=', this.companyOptions + ",companyId=" + this.companyId)
            //     const company = this.companyOptions.find((company) => company.companyId === this.companyId)
            //     // 確保找到了公司資料
            //     if (company) {
            //         // 取得該公司的 isSubProject 資料
            //         this.isSubProject = company.isSubProject
            //         console.log(`公司 ${company.companyName} 是否有副項目: ${this.isSubProject}`)
            //     } else {
            //         console.log(`找不到 companyId 為 ${this.companyId} 的公司資料`)
            //     }
            // },
            waitForCompanyOptions() {
                return new Promise((resolve) => {
                    const interval = setInterval(() => {
                        if (this.companyOptions != null && this.companyOptions.length > 0) {
                            clearInterval(interval);
                            resolve();
                        }
                    }, 10); // 每100毫秒检查一次
                });
            },
            //取得資產類型ID
            async getAssetsModelTypeId() {
                console.log('-----開始等待 customerAssetsOptions----');
                console.log('初始資產類別 customerAssetsId=' + this.customerAssetsId);
                if (this.customerAssetsOptions && this.customerAssetsOptions.length > 0) {
                    const customerAssets = this.customerAssetsOptions.find(
                        (cu) => cu.customerAssetsId === this.customerAssetsId
                    );
                    if (customerAssets != null) {
                        this.assetsModelTypeId = customerAssets.assetsModelTypeId;
                        console.log('選擇資產類別 assetsModelTypeId=' + this.assetsModelTypeId);
                    } else {
                        console.log('未找到匹配的資產類別');
                    }
                } else {
                    console.log('尚未獲取到 customerAssetsOptions，等待中...');
                }
            },
            //取得資產類型ID
            // async getAssetsModelTypeId() {
            //     console.log('-----開始等待 customerAssetsOptions----')
            //     console.log('初始資產類別 customerAssetsId=' + this.customerAssetsId)
            //     await this.waitForCustomerAssetsOptions();
            //     console.log('已收到資料 customerAssetsOptions=', this.customerAssetsOptions)
            //     const customerAssets = this.customerAssetsOptions.find((cu) => cu.customerAssetsId === this.customerAssetsId);
            //     if (customerAssets != null) {
            //         this.assetsModelTypeId = customerAssets.assetsModelTypeId;
            //         console.log('選擇資產類別 assetsModelTypeId=' + this.assetsModelTypeId);
            //     }
            // },
            waitForCustomerAssetsOptions() {
                return new Promise((resolve) => {
                    const interval = setInterval(() => {
                        if (this.customerAssetsOptions != null && this.customerAssetsOptions.length > 0) {
                            clearInterval(interval);
                            resolve();
                        }
                    }, 10); // 每100毫秒检查一次
                });
            },
            async getOrder(orderId) {
                //取得更新的工單資料
                let tmp = this
                let url = 'order/search/getOrder'
                console.log('取得更新的工單資料 orderId=' + orderId)
                let parameter = {
                    orderId: orderId,
                    workId: null,
                    isMainProject: null,
                }
                let responseData = await tmp.$api.doPostByToken(parameter, url, this.token)
                if (responseData != null) {
                    tmp.orderUpdatedData = responseData
                    console.log('取得更新的工單資料 orderUpdatedData=', tmp.orderUpdatedData)
                } else {
                    console.error('未找到更新的工單資料 orderUpdatedData=', tmp.orderUpdatedData)
                }
            },
            closeDialog() {
                console.log('關閉Pop')
                this.orderWorkUserInputsResult = []
                this.orderSubWorkUserInputsResult = []
                this.companyId = null
                this.branchId = null
                this.customerId = null
                this.customerAssetsId = null
                this.startTime = null
                this.remark = null
                this.discountPercentage = null
                this.discountAmount = null
                this.orderSubWorkUserInputs = []
                this.mainProject = true
                this.isSubProject = false
                this.companyOptions = []
                this.customerAssetsOptions = []
                this.inDate = null;
                this.$emit('update:reload', this.reload)
                this.$emit('closeDialog')
            },
            async addTemplate() {
                //編輯資料
                let tmp = this
                let url = 'order/updated'
                try {
                    this.emitInputs()
                    this.emitInputs_sub()
                    this.setInput()
                    console.log('編輯資料 orderWorkUserInputs=', tmp.orderWorkUserInputs)
                    console.log('編輯資料 orderSubWorkUserInputs=', tmp.orderSubWorkUserInputs)
                    this.setOrderWorkUser(this.orderWorkUserInputs, this.userOptions)
                    this.setOrderSubWorkUser(this.orderSubWorkUserInputs, this.userSubOptions)
                    let parameter = {
                        orderId: this.orderId,
                        companyId: this.companyId,
                        branchId: this.branchId,
                        customerId: this.customerId,
                        customerAssetsId: this.customerAssetsId,
                        orderStatus: this.orderStatus,
                        discountPercentage: this.discountPercentage,
                        discountAmount: this.discountAmount,
                        startTime: new Date(this.startTime),
                        remark: this.remark,
                        workUserList: this.workUserList,
                        subWorkUserList: this.subWorkUserList,
                    }
                    console.log('編輯資料 parameter=', parameter)
                    if (!this.checkAddParameter(parameter)) {
                        console.log('編輯驗證資料失敗!')
                        return
                    }
                    tmp.reload = true
                    tmp.loadingView()
                    let responseData = await tmp.$api.doPostByToken(parameter, url, this.token)
                    console.log('responseData='+responseData)
                    if (responseData) {
                        tmp.$api.openMsg('編輯資料完成!', 'success')
                        // 在延迟 2000 毫秒（2 秒）后执行代码
                        setTimeout(function () {
                            tmp.closeDialog()
                            //  tmp.$emit('init');
                        }, 1000)
                    } else {
                        tmp.$api.openMsg('編輯資料失敗!', 'error')
                        console.log('編輯資料失敗!')
                    }
                } catch (error) {
                    console.log('編輯資料發生異常! error=' + error)
                } finally {
                    tmp.closeLoading()
                }
                tmp.closeLoading()
            },
            checkAddParameter(parameter) {
                console.log('儲存驗證資料! parameter=', parameter)
                var ststus = true
                if (parameter != null && parameter != '') {
                    if (parameter.companyId == null || parameter.companyId === '') {
                        console.log('公司不可為空!')
                        this.$api.openMsg('公司不可為空!', 'error')
                        return false
                    }
                    if (parameter.branchId == null || parameter.branchId === '') {
                        console.log('分店不可為空!')
                        this.$api.openMsg('分店不可為空!', 'error')
                        return false
                    }
                    if (parameter.customerId == null || parameter.customerId === '') {
                        console.log('會員不可為空!')
                        this.$api.openMsg('會員不可為空!', 'error')
                        return false
                    }
                    if (parameter.customerAssetsId == null || parameter.customerAssetsId === '') {
                        console.log('會員資產不可為空!')
                        this.$api.openMsg('會員資產不可為空!', 'error')
                        return false
                    }
                    if (parameter.orderStatus == null || parameter.orderStatus === '') {
                        console.log('工單狀態不可為空!')
                        this.$api.openMsg('工單狀態不可為空!', 'error')
                        return false
                    }
                    if (parameter.startTime == null || parameter.startTime === '') {
                        console.log('服務開始時間不可為空!')
                        this.$api.openMsg('服務開始時間不可為空!', 'error')
                        return false
                    }
                    if (parameter.workUserList == null || parameter.workUserList.length === 0) {
                        console.log('主服務項目不可為空!')
                        this.$api.openMsg('主服務項目不可為空!', 'error')
                        return false
                    } else {
                        var work = parameter.workUserList.find(
                            (orderWorkUser) => orderWorkUser.workId == null || orderWorkUser.workId === ''
                        )
                        if (work != null && work != '') {
                            console.log('主服務項目不可為空!')
                            this.$api.openMsg('主服務項目不可為空!', 'error')
                            return false
                        }
                    }
                    //主項目驗證
                    if (parameter.workUserList != null && parameter.workUserList.length > 0) {
                        //項目執行狀態為[已派工]或[執行中]時，服務人員不可為空
                        var status = true;
                        var con = 0;
                        this.workUserList.find(orderWorkUser => {
                            console.log("orderWorkUser.workStatus=" + orderWorkUser.workStatus)
                            switch (orderWorkUser.workStatus) {
                                case  "0":
                                    if (parameter.orderStatus == "1" || parameter.orderStatus == "2") {
                                        this.showErrorMessage('主項目執行狀態為[未執行]時，工單狀態選擇錯誤!')
                                        status = false;
                                    }
                                    break;
                                case  "1":
                                    if (parameter.orderStatus == "0" || parameter.orderStatus == "2") {
                                        this.showErrorMessage('主項目執行狀態為[已派工]時，工單狀態選擇錯誤!')
                                        status = false;
                                        break;
                                    }
                                    if (orderWorkUser.orderWorkUserDtoList == null || orderWorkUser.orderWorkUserDtoList.length == 0) {
                                        this.showErrorMessage('主項目執行狀態為[已派工]時，服務人員不可為空!')
                                        status = false;
                                        break;
                                    }
                                    break;
                                case  "2":
                                    if (parameter.orderStatus == "0" || parameter.orderStatus == "2") {
                                        this.showErrorMessage('主項目執行狀態為[執行中]時，工單狀態選擇錯誤!')
                                        status = false;
                                        break;
                                    }
                                    if (orderWorkUser.orderWorkUserDtoList == null || orderWorkUser.orderWorkUserDtoList.length == 0) {
                                        this.showErrorMessage('主項目執行狀態為[執行中]時，服務人員不可為空!')
                                        status = false;
                                        break;
                                    }
                                    break;
                                case  "3":
                                    if (parameter.orderStatus == "0" || parameter.orderStatus == "1") {
                                        this.showErrorMessage('主項目執行狀態為[完成]時，工單狀態選擇錯誤!')
                                        status = false;
                                        break;
                                    }
                                    if (orderWorkUser.orderWorkUserDtoList == null || orderWorkUser.orderWorkUserDtoList.length == 0) {
                                        this.showErrorMessage('主項目執行狀態為[完成]時，服務人員不可為空!')
                                        status = false;
                                        break;
                                    }
                                    break;
                                case  "4":
                                    con = con + 1;
                                    // status = false;
                                    break;
                            }
                        });
                        console.log("con=" + con + ",workUserList.length=" + this.workUserList.length)
                        if (con == this.workUserList.length) {
                            if (parameter.orderStatus != "3") {
                                this.showErrorMessage('主項目執行狀態都為[取消]時，工單狀態選擇錯誤!')
                                return false
                            }
                        }
                        if (!status) {
                            return false
                        }
                    }

                    //副項目驗證
                    if (parameter.subWorkUserList != null && parameter.subWorkUserList.length > 0) {
                        const orderWorkUserData = parameter.subWorkUserList.find(
                            (orderWorkUser) => orderWorkUser.workId == null || orderWorkUser.workId == ''
                        )
                        if (orderWorkUserData != null) {
                            this.showErrorMessage('副服務項目不可為空!')
                            return false
                        }
                        //項目執行狀態為[已派工]或[執行中]時，服務人員不可為空
                        const workUserExecutingOrCompleted = parameter.subWorkUserList.find(
                            (orderWorkUser) => orderWorkUser.workStatus == "1" || orderWorkUser.workStatus == "2" || orderWorkUser.workStatus == "3"
                        )
                        if (workUserExecutingOrCompleted) {
                            if (parameter.orderStatus == "0") {
                                this.showErrorMessage('副項目執行狀態為[已派工]或[執行中]時，工單狀態選擇錯誤!')
                                return false
                            }
                            if (
                                !workUserExecutingOrCompleted.orderWorkUserDtoList ||
                                workUserExecutingOrCompleted.orderWorkUserDtoList.length === 0
                            ) {
                                this.showErrorMessage('副項目執行狀態為[已派工]或[執行中]或[完成]時，服務人員不可為空!')
                                return false
                            }
                        }
                        //副項目不驗證工單狀態
                        // const workUserNotDispatched = parameter.subWorkUserList.find((orderWorkUser) => orderWorkUser.workStatus == 0)
                        // if (workUserNotDispatched && (parameter.orderStatus == 1 || parameter.orderStatus == 2)) {
                        //     this.showErrorMessage('副項目執行狀態為[未派工]時，工單狀態選擇錯誤!')
                        //     return false
                        // }
                    }
                } else {
                    console.log('必填資料不可為空!')
                    this.$api.openMsg('必填資料不可為空!', 'error')
                    return false
                }
                return ststus
            },
            loadingView() {
                //開啟載入特效
                this.loadingStatus = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)',
                })
            },
            closeLoading() {
                //關閉載入特效
                if (this.loadingStatus != null) {
                    this.loadingStatus.close()
                }
            },
            async searchCompanyList() {
                //查詢全部公司下拉選單資料
                let tmp = this
                let url = 'login/search/searchCompanyOptions'
                let parameter = {
                    companyId: this.companyId,
                    companyName: '',
                    isActive: true,
                }
                console.log('查詢全部公司下拉選單資料 companyId=', tmp.companyId)
                //取得列表資料
                let responseData = await tmp.$api.doPost(parameter, url)
                if (responseData != null) {
                    tmp.companyOptions = responseData
                    console.log('取得全部公司下拉選單資料 companyOptions=', tmp.companyOptions)
                }
            },
            async searchBranchList() {
                //查詢全部分店下拉選單資料
                let tmp = this
                let url = 'login/search/searchBranchOptions'
                let parameter = {
                    companyId: tmp.companyId,
                    branchId: tmp.branchId,
                    companyName: '',
                    isActive: true,
                }
                console.log('查詢全部分店下拉選單資料 companyId=', tmp.companyId + ',branchId=' + tmp.branchId)
                //取得列表資料
                let responseData = await tmp.$api.doPostByToken(parameter, url, this.token)
                if (responseData != null) {
                    tmp.branchOptions = responseData
                    console.log('取得全部分店下拉選單資料 branchOptions=', tmp.branchOptions)
                }
            },
            async searchCustomerOptions() {
                //查詢公司會員下拉選單資料
                let tmp = this
                let url = 'consumer/search/searchCustomerOptions'
                let parameter = {
                    companyId: tmp.companyId,
                    branchId: tmp.branchId,
                    isActive: true,
                }
                console.log('查詢公司會員下拉選單資料 companyId=', tmp.companyId + ',branchId=' + tmp.branchId)
                //取得列表資料
                let responseData = await tmp.$api.doPostByToken(parameter, url, this.token)
                if (responseData != null) {
                    tmp.customerOptions = responseData
                    console.log('取得公司會員下拉選單資料 customerOptions=', tmp.customerOptions)
                }
            },
            async searchAssetsModelTypeList() {
                //依據條件查詢會員資產資料
                let tmp = this
                let url = 'consumer/search/assets'
                let parameter = {
                    companyId: tmp.companyId,
                    branchId: tmp.branchId,
                    customerId: tmp.customerId,
                    nickName: null,
                    identifier: null,
                    isActive: null,
                    remark: null,
                }
                console.log(
                    '依據條件查詢會員資產資料 companyId=',
                    tmp.companyId + ',branchId=' + tmp.branchId + ',customerId=' + tmp.customerId
                )
                //取得列表資料
                let responseData = await tmp.$api.doPostByToken(parameter, url, this.token)
                if (responseData != null) {
                    tmp.customerAssetsOptions = responseData
                    console.log('取得會員資產資料下拉選單 customerAssetsOptions=', tmp.customerAssetsOptions)
                }
            },
            handleCompanyChange(value) {
                //選擇公司下拉觸發
                let tmp = this
                console.log('選擇公司下拉觸發 company ID:', value)
                if (!this.isCompanyid) {
                    this.companyId = value
                    this.branchId = null
                    setTimeout(function () {
                        tmp.searchBranchList()
                        // tmp.selectWorkOptions()
                        const company = tmp.companyOptions.find((company) => company.companyId === value)
                        // 確保找到了公司資料
                        if (company) {
                            // 取得該公司的 isSubProject 資料
                            tmp.isSubProject = company.isSubProject
                            console.log(`公司 ${company.companyName} 是否有副項目: ${tmp.isSubProject}`)
                        } else {
                            console.log(`找不到 companyId 為 ${tmp.companyId} 的公司資料`)
                        }
                    }, 500)
                }
            },
            handleBranchChange(value) {
                //選擇分店下拉觸發
                let tmp = this
                console.log('選擇分店下拉觸發 Branch ID:', value)
                if (!this.isBranchid) {
                    this.branchId = value
                    setTimeout(function () {
                        tmp.searchCustomerOptions()
                        tmp.selectStaffOptions()
                        tmp.selectStaffOptions_sub()
                    }, 500)
                }
            },
            handleCustomerChange(value) {
                //選擇會員下拉觸發
                let tmp = this
                console.log(
                    '選擇會員下拉觸發 companyId=' + this.companyId + ',branchId=' + this.branchId + ',customerId=' + value
                )
                setTimeout(function () {
                    tmp.searchAssetsModelTypeList()
                }, 500)
            },
            //主項目子層-刷新項目資料
            handleOrderWorkUserAddInputs(newInputs, userOptions, mainSum, mainWorkingHoursAll) {
                console.log('主項目刷新項目資料 handleOrderWorkUserAddInputs...............................')
                this.userOptions = userOptions
                this.orderWorkUserInputs = newInputs
                this.mainSum = mainSum;
                this.mainWorkingHoursAll = mainWorkingHoursAll;
                this.allSum = this.mainSum + this.subSum;
                this.allWorkingHours = this.mainWorkingHoursAll + this.subWorkingHoursAll;
                //計算金額
                //this.discountSumData();
                console.log('主項目 this.orderWorkUserInputs=', this.orderWorkUserInputs + ",mainSum=" + mainSum + ",subSum=" + this.subSum + ",mainWorkingHoursAll=" + mainWorkingHoursAll)
                console.log('主項目 this.userOptions=', this.userOptions + ",allSum=" + this.allSum + ",allWorkingHours=" + this.allWorkingHours)
            },
            //副項目子層-刷新項目資料
            handleOrderSubWorkUserAddInputs(newInputs, userSubOptions, subSum, subWorkingHoursAll) {
                console.log('副項目刷新項目資料 handleOrderSubWorkUserAddInputs...............................')
                this.userSubOptions = userSubOptions
                this.orderSubWorkUserInputs = newInputs
                this.subSum = subSum;
                this.subWorkingHoursAll = subWorkingHoursAll;
                this.allSum = this.mainSum + this.subSum;
                this.allWorkingHours = this.mainWorkingHoursAll + this.subWorkingHoursAll;
                //計算金額
               // this.discountSumData();
                console.log('副項目 this.orderSubWorkUserInputs=', this.orderSubWorkUserInputs + ",mainSum=" + this.mainSum + ",subSum=" + subSum + ",subWorkingHoursAll=" + subWorkingHoursAll)
                console.log('副項目 this.userSubOptions=', this.userSubOptions + ",allSum=" + this.allSum + ",allWorkingHours=" + this.allWorkingHours)
                if (this.orderSubWorkUserInputs != null && this.orderSubWorkUserInputs.length > 0) {
                    this.mainProject = false
                } else {
                    this.mainProject = true
                }
            },
            async selectWorkOptions() {
                try {
                    //查詢全部服務項目下拉選單
                    await this.$refs.orderWorkUser.searchWorkOptionsList()
                } catch (e) {
                    console.error('selectWorkOptions 查詢全部服務項目下拉選單發生異常 e=', e)
                }
            },
            async selectStaffOptions() {
                try {
                    //查詢員工帳號下拉選單
                    await this.$refs.orderWorkUser.searchStaffOptionsList()
                } catch (e) {
                    console.error('selectStaffOptions 查詢員工帳號下拉選單發生異常 e=', e)
                }
            },
            async emitInputs() {
                //觸發傳遞資料事件
                await this.$refs.orderWorkUser.emitInputs()
            },
            async selectWorkOptions_sub() {
                try {
                    //查詢全部服務項目下拉選單
                    await this.$refs.orderSubWorkUser.searchWorkOptionsList()
                } catch (e) {
                    console.error('selectWorkOptions_sub 查詢全部服務項目下拉選單發生異常 e=', e)
                }
            },
            async selectStaffOptions_sub() {
                try {
                    //查詢員工帳號下拉選單
                    await this.$refs.orderSubWorkUser.searchStaffOptionsList()
                } catch (e) {
                    console.error('selectStaffOptions_sub 查詢員工帳號下拉選單發生異常 e=', e)
                }
            },
            async emitInputs_sub() {
                //觸發傳遞資料事件
                await this.$refs.orderSubWorkUser.emitInputs()
            },
            //主項目子層-刷新人員資料
            setOrderWorkUser(orderWorkUserInputs, userOptions) {
                console.log('this.orderWorkUserInputs=', orderWorkUserInputs + ',userOptions=' + userOptions)
                // 更新子件傳過來的 inputs 数据
                // if (orderWorkUserInputs == null || orderWorkUserInputs.length == 0) {
                //     this.workUserList=orderWorkUserInputs;
                // }
                // 创建一个新的数组来存储转换后的数据
                let transformedWorkUserList = orderWorkUserInputs.map((input) => {
                    let newInput = {...input} // 创建 input 的浅拷贝
                    let users = newInput.orderWorkUserDtoList // 取出 input 中的 users 数据
                    if (users && users.length > 0) {
                        let orderWorkUserDtoList = users
                            .map((order) => {
                                console.log('资料转换 setOrderWorkUser this.order=', order)
                                let data = userOptions.find((user) => user.userId === order)
                                console.log('资料转换 setOrderWorkUser this.data=', data)
                                if (!data) {
                                    return null
                                }
                                return {
                                    staffId: data.staffId,
                                    userId: data.userId,
                                }
                            })
                            .filter((user) => user !== null) // 过滤掉 null 值

                        console.log('orderWorkUserDtoList=', orderWorkUserDtoList)
                        newInput.orderWorkUserDtoList = orderWorkUserDtoList
                    }
                    return newInput
                })
                // 将转换后的数据赋值给 workUserList
                this.workUserList = transformedWorkUserList
            },
            //副項目子層-刷新人員資料
            setOrderSubWorkUser(orderSubWorkUserInputs, userOptions) {
                console.log('this.orderSubWorkUserInputs=', orderSubWorkUserInputs + ',userOptions=' + userOptions)
                // 更新子件傳過來的 inputs 数据
                // if (orderWorkUserInputs == null || orderWorkUserInputs.length == 0) {
                //     this.workUserList=orderWorkUserInputs;
                // }
                let transformedWorkUserList = orderSubWorkUserInputs.map((input) => {
                    let newInput = {...input} // 创建 input 的浅拷贝
                    let users = newInput.orderWorkUserDtoList // 取出 input 中的 users 数据
                    if (users && users.length > 0) {
                        let orderWorkUserDtoList = users
                            .map((order) => {
                                console.log('资料转换 setOrderSubWorkUser this.order=', order)
                                let data = userOptions.find((user) => user.userId === order)
                                console.log('资料转换 setOrderSubWorkUser this.data=', data)
                                if (!data) {
                                    return null
                                }
                                return {
                                    staffId: data.staffId,
                                    userId: data.userId,
                                }
                            })
                            .filter((user) => user !== null) // 过滤掉 null 值

                        console.log('orderWorkUserDtoList=', orderWorkUserDtoList)
                        newInput.orderWorkUserDtoList = orderWorkUserDtoList
                    }
                    return newInput
                })
                // 将转换后的数据赋值给 workUserList
                this.subWorkUserList = transformedWorkUserList
            },
            handleUpdateStartTimeInput(newInput) {
                // 更新日期數據
                //this.startTime = newInput
                console.log('更新日期數據 newInput=' + newInput)
            },
            updateAssetsModelTypeId(newId) {
                console.log('updateAssetsModelTypeId newId=' + newId)
                //主項目公司下拉選單觸動
                //父項目傳過來的資料
                // let tmp = this
                // tmp.assetsModelTypeId = newId
                // console.log('更新參數值 assetsModelTypeId=' + tmp.assetsModelTypeId)
                // console.log('是否有副項目 isSubProject=' + tmp.isSubProject)
                // //有副項目
                // if (tmp.isSubProject) {
                // 	//刷新副項目資料
                // 	setTimeout(function () {
                // 		tmp.selectWorkOptions_sub()
                // 	}, 500)
                // }
            },
            async setInput() {
                //觸發日期資料傳送
                console.log('觸發日期資料傳送 startTime=' + this.startTime)
                await this.$refs.datepicker.setInput()
            },
            async initDatepicker() {
                //觸發日期資料傳送
                console.log('服務開始時間 inDate=' + this.inDate)
                await this.$refs.datepicker.init(this.inDate)
            },
            //計算金額
            discountSumData() {
                console.log("父層計算金額 allSum=" + this.allSum)
                if (this.discountAmount != null && this.discountAmount != 0) {
                    this.discountSum = this.allSum - this.discountAmount;
                } else if (this.discountPercentage != null) {
                    this.discountSum = Math.round(this.allSum * (1 - this.discountPercentage / 100));
                } else {
                    this.discountSum = this.allSum;
                }
                console.log("父層支付金額 discountSum=" + this.discountSum)
                // 确保支付金额为正数
                if (this.discountSum <= 0) {
                    this.discountSum = 0;
                }
            },
            //輸入折扣金額觸發
            validateDiscountAmount(value) {
                console.log("validateDiscountAmount called"); // 调试信息
                // 移除非数字字符
                let sanitizedValue = value.replace(/[^0-9]/g, '');
                console.log("移除非数字字符 value=" + value + ",sanitizedValue=" + sanitizedValue)
                // 如果值为空，或者是0或负数，清空输入框
                if (sanitizedValue != null && sanitizedValue != '' && sanitizedValue.length > 0) {
                    if (sanitizedValue.length > 1 && sanitizedValue.charAt(0) === '0') {
                        sanitizedValue = sanitizedValue.substring(1);
                    }
                    if (this.rolePower != null && this.rolePower.maximumDiscountAmount != null && sanitizedValue > this.rolePower.maximumDiscountAmount) {
                        console.log("本角色給予的折扣最高額度 rolePower.maximumDiscountAmount=" + this.rolePower.maximumDiscountAmount)
                        this.discountAmount = 0;
                        this.showErrorMessage('已超過本角色給予的折扣最高額度!')
                    } else {
                        this.discountAmount = sanitizedValue;
                    }
                } else {
                    this.discountAmount = 0;
                }
                this.discountSumData();
            },
            //輸入折扣率觸發
            validateDiscountPercentage(value) {
                // 移除非数字字符
                console.log("移除非数字字符 value=" + value)
                const sanitizedValue = value.replaceAll(/[^0-9]/g, '');
                // 如果值为空，或者是0或负数，清空输入框
                if (sanitizedValue === '' || parseInt(sanitizedValue, 10) <= 0) {
                    this.discountPercentage = '';
                } else {
                    this.discountPercentage = sanitizedValue;
                }
                this.discountSumData();
            },
            showErrorMessage(message) {
                console.log(message)
                this.$api.openMsg(message, 'error')
            },
            async getRolePower() {
                //查詢登入者角色權利資料
                let tmp = this
                let url = 'rolePower/getRolePower'
                //取得列表資料
                let responseData = await tmp.$api.doGetByToken(url, this.token)
                if (responseData != null) {
                    tmp.rolePower = responseData
                    console.log('查詢登入者角色權利資料 rolePower=', tmp.rolePower)
                }
            },
        },
    }
</script>

<style scoped>
    img {
        width: 50px;
        height: 50px;
        /*height: auto;*/
        margin: 10px 0px 0px 0px;
    }

    /**日期選擇器**/
    .selectInpud .el-date-editor {
        width: 100%;
    }

    /* 使用 ::v-deep 深度选择器 */
    .sum ::v-deep .el-input__inner {
        border: none !important; /* 去除外框线 */
        background-color: transparent !important; /* 设置背景色为透明 */
        padding: 0 !important; /* 去除内部的 padding */
        box-shadow: none !important; /* 去除阴影效果 */
    }
</style>
