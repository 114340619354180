<template>
    <div class="vue-tempalte login">
        <div class="vertical-center">
            <div class="inner-block">
                <div class="vue-tempalte">
                    <form>
                        <h3>{{ title }}</h3>
                        <h3>登入</h3>
                        <div class="form-group">
                            <label>帳號</label>
                            <el-input v-model="userName" placeholder="請輸入帳號" autocomplete="off"></el-input>
                        </div>

                        <div class="form-group">
                            <label>密碼</label>
                            <el-input
                                    v-model="password"
                                    type="password"
                                    placeholder="請輸入密碼"
                                    class="custom-password-input"
                            />

                            <!--                            <el-input-->
                            <!--                                    :type="passwordVisible ? 'text' : 'password'"-->
                            <!--                                    v-model="password"-->
                            <!--                                    placeholder="請輸入密碼"-->
                            <!--                                    style="width: calc(100% - 30px)"-->
                            <!--                                    autocomplete="new-password"></el-input>-->
                            <!--                            <span-->
                            <!--                                    class="eye-icon"-->
                            <!--                                    @click="togglePasswordVisibility">-->
                            <!--                                    {{ passwordVisible ? '👁️' : ' 👁️‍🗨️' }}</span>-->
                        </div>
                        <button type="button" class="btn btn-dark btn-lg btn-block" id="login" @click="login">
                            <span>確定</span>
                        </button>
                        <p class="forgot-password text-right mt-2 mb-4">
                            <router-link to="/forgot-password">忘記密碼 ?</router-link>
                        </p>
                        <el-row>
                            <el-col :span="24" class="container">
                                <span>{{ version2 }}</span>
                            </el-col>
                        </el-row>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<!-- 登入頁面-->
<script>
    import 'bootstrap/dist/css/bootstrap.min.css'
    import axios from 'axios'

    export default {
        data() {
            return {
                userName: null,
                password: null,
                passwordVisible: false,
                loginType: 0,
                loginReturnDto: null,
                options: [],
                companyId: null,
                token: null,
                role: null,
                title: null,
                company: null,
            }
        },
        computed: {
            inputType() {
                return this.passwordVisible ? 'text' : 'password'
            },
        },
        mounted() {
            console.log('畫面載入執行.....ok')
            this.version2 = this.$version2
            this.init()
        },
        methods: {
            init() {
                console.log('init...........start')
                document.title = ''
                if (!this.$companyId) {
                    this.companyId = null
                    document.title = '系統管理後台'
                    this.title = '系統管理後台'
                    localStorage.setItem('title', JSON.stringify(document.title))
                    console.log('畫面載入執行 companyId is null')
                } else {
                    this.companyId = parseInt(this.$companyId, 10)
                    this.getCompany()
                    console.log('畫面載入執行 companyId=' + this.companyId)
                }
                let tmp = this
                setTimeout(function () {
                    console.log('登入取得公司資料 company=', tmp.company)
                    if (tmp.company != null) {
                        document.title = tmp.company.companyName + '管理後台'
                        localStorage.setItem('title', JSON.stringify(document.title))
                        tmp.title = tmp.company.companyName + '管理後台'
                        tmp.title = tmp.title.replace("股份有限公司", "").replace("有限公司", "");
                        console.log('登入取得公司資料 companyName=', tmp.company.companyName)
                    }
                }, 500)
                console.log('init...........end')
            },
            async loadConfig() {
                try {
                    const response = await axios.get('../config/config.json')
                    this.config = response.config.baseURL
                    console.log('畫面載入執行 baseURL=' + this.config)
                } catch (error) {
                    console.error('Error loading config:', error)
                }
            },
            async login() {
                let tmp = this
                //this.checkLoginName();
                let url = 'login/staffLogin'
                let parameter = {
                    companyId: tmp.companyId,
                    userName: tmp.userName,
                    password: tmp.password,
                    loginType: tmp.loginType,
                }
                if (!this.checkAddParameter(parameter)) {
                    return
                }
                //取得列表資料
                let responseData = await tmp.$api.doPostByMsg(parameter, url)
                if (responseData != null) {
                    tmp.loginReturnDto = responseData
                    console.log('登入成功! loginReturnDto=' + tmp.loginReturnDto)
                    console.log('登入成功! token=' + tmp.loginReturnDto.token)
                    // 存儲到 Local Storage
                    localStorage.setItem('loginReturnDto', JSON.stringify(tmp.loginReturnDto))
                    //取得權限控制資料
                    tmp.searchPermissionControl()
                    // tmp.$router.push({path: '/home'});
                } else {
                    console.log('登入失敗!')
                }
            },
            async searchCompanyList() {
                //查詢全部公司資料
                let tmp = this
                let url = 'login/search/searchCompanyOptions'
                let parameter = {
                    companyId: tmp.companyId,
                    branchId: null,
                    companyName: '',
                    isActive: true,
                }
                //取得列表資料
                let responseData = await tmp.$api.doPost(parameter, url)
                if (responseData != null) {
                    tmp.options = responseData
                    console.log('取得下拉選單資料 options=' + tmp.options)
                }
            },
            async getCompany() {
                //查詢公司資料
                let tmp = this
                let url = 'login/getCompany/' + tmp.companyId
                console.log('getCompany url=' + url)
                //取得列表資料
                let responseData = await tmp.$api.doGet(url)
                if (responseData != null) {
                    tmp.company = responseData
                    console.log('取得查詢公司資料 company=', tmp.company)
                }
            },
            async searchPermissionControl() {
                console.log('------------------開始取得權限控制資料--------------------')
                let tmp = this
                // 從 Local Storage 獲取 loginReturnDto
                if (tmp.loginReturnDto != null) {
                    tmp.token = tmp.loginReturnDto.token
                    tmp.role = tmp.loginReturnDto.role
                }
                let url = 'permission/search/permissionControl'
                let parameter = {
                    role: tmp.role,
                    page: '',
                    functionCode: '',
                    isActive: true,
                }
                console.log('searchPermissionControl parameter=' + parameter.toString())
                //取得列表資料
                let responseData = await tmp.$api.doPostByToken(parameter, url, this.token)
                console.log('權限控制資料 responseData.length=' + responseData.length)
                if (responseData != null && responseData.length > 0) {
                    console.log('已取得權限控制資料!')
                    const results = this.$api.findAndCategorizePermissions(responseData, 'ALL', ['ISCOMPANYID', 'ISBRANCHID'])
                    const isCompanyid = this.$api.hasDataForSpecificFunctionCode(results, 'ISCOMPANYID') ? true : false
                    const isBranchid = this.$api.hasDataForSpecificFunctionCode(results, 'ISBRANCHID') ? true : false
                    // 存儲到 Local Storage
                    localStorage.setItem('permissionControl', JSON.stringify(responseData))
                    localStorage.setItem('isCompanyid', JSON.stringify(isCompanyid))
                    localStorage.setItem('isBranchid', JSON.stringify(isBranchid))
                    if(tmp.$companyId){
                        await tmp.waitForCompanyId();
                        tmp.$router.push({path: '/home'})
                    }else{
                        tmp.$router.push({path: '/home'})
                    }
                } else {
                    console.log('無法取得權限控制資料，請重新登入! responseData is null')
                }
                console.log('------------------取得權限控制資料完成--------------------')
            },
            waitForCompanyId() {
                return new Promise((resolve) => {
                    const interval = setInterval(() => {
                        if (this.companyId != null && this.companyId != '' && this.company != null && this.company != '') {
                            clearInterval(interval);
                            resolve();
                            console.log("已找到公司資料!")
                        }
                    }, 10); // 每100毫秒检查一次
                });
            },
            async checkLoginName() {
                // 获取当前页面的 URL
                const currentUrl = window.location.href
                // 找到最后一个斜线的位置
                const lastSlashIndex = currentUrl.lastIndexOf('/')
                // 提取斜线后面的字符串
                const loginName = currentUrl.substring(lastSlashIndex + 1)
                console.log('登入者的公司為 loginName=' + loginName) // 输出斜线后面的字符串
                // 存儲到 Local Storage
                localStorage.setItem('loginName', JSON.stringify(loginName))
                if (loginName != null && loginName == 'login') {
                    this.value = null
                } else if (loginName != null && loginName == 'login1') {
                    this.value = 1
                } else {
                    this.value = 999999
                }
            },
            checkAddParameter(parameter) {
                var ststus = true
                if (parameter != null && parameter != '') {
                    if (parameter.userName == null || !parameter.userName) {
                        console.log('帳號不可為空!')
                        this.$api.openMsg('帳號不可為空!', 'error')
                        return false
                    }
                    if (parameter.password == null || !parameter.password) {
                        console.log('密碼不可為空!')
                        this.$api.openMsg('密碼不可為空!', 'error')
                        return false
                    }
                } else {
                    console.log('必填資料不可為空!')
                    this.$api.openMsg('必填資料不可為空!', 'error')
                    return false
                }
                return ststus
            },
            togglePasswordVisibility() {
                //檢視密碼
                this.passwordVisible = !this.passwordVisible
            },
        },
    }
</script>

<style>
    .login {
        /*background: #2554FF !important;*/
        background-color: #015bb3 !important;
    }

    .container {
        font-size: 0.7rem;
        text-align: center;
    }
</style>
