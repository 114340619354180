<template>
    <div class="popDialog">
        <el-dialog
                v-if="uploadExcelDialog"
                width="95%"
                top="30px"
                :visible.sync="uploadExcelDialog"
                :before-close="closeDialog"
                :fullscreen="dialogFull"
                custom-class="custom-dialog"
        >
            <template v-slot:title>
                <el-row>
                    <el-col :span="24" class="selectTitleMax">
                        <span>匯入客戶資料</span>
                    </el-col>
                </el-row>
            </template>
            <el-row>
                <el-col :span="24">
                    <div class="containerDiv">
                        <el-row>
                            <el-col :span="selectTitleSpan" class="selectTitle">
                                <span>公司<span class="red">*</span>:</span>
                            </el-col>
                            <el-col :span="selectInpudSpan" class="selectInpud">
                                <el-select v-model="companyId" placeholder="" @change="handleCompanyChange"
                                           :disabled="isCompanyid">
                                    <el-option :key="'placeholder'" :label="'請選擇'" :value="null"></el-option>
                                    <el-option
                                            v-for="item in companyOptions"
                                            :key="item.companyId"
                                            :label="item.companyName"
                                            :value="item.companyId"
                                            :disabled="item.disabled"
                                    >
                                    </el-option>
                                </el-select>
                            </el-col>
                            <el-col :span="selectTitleSpan" class="selectTitle">
                                <span>分店<span class="red">*</span>:</span>
                            </el-col>
                            <el-col :span="selectInpudSpan" class="selectInpud">
                                <el-select v-model="branchId" placeholder="" :disabled="isBranchid">
                                    <el-option :key="'placeholder'" :label="'請選擇'" :value="null"></el-option>
                                    <el-option
                                            v-for="item in branchOptions"
                                            :key="item.branchId"
                                            :label="item.branchName"
                                            :value="item.branchId"
                                            :disabled="item.disabled"
                                    >
                                    </el-option>
                                </el-select>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="selectTitleSpan" class="selectTitle">
                                <span>匯入客戶資料<span>(僅支援xlsx檔案)</span>:</span>
                            </el-col>
                            <!-- 文件上傳輸入框 -->
                            <el-col :span="selectInpudSpan" class="selectInpud">
                                <input type="file" ref="fileInput" @change="handleFileUpload" accept=".xlsx"/>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="selectTitleSpan" class="selectTitle">
                                <span>下載客戶範例資料</span>
                            </el-col>
                            <el-col :span="selectInpudSpan" class="selectInpud">
                                <el-button @click="downloadExcel">下載</el-button>
                            </el-col>
                        </el-row>
                    </div>
                </el-col>
            </el-row>
            <div slot="footer" class="popBatton">
                <el-button @click="uploadExcel" type="primary">匯入</el-button>
                <el-button @click="closeDialog">關閉</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: 'uploadExcelDialog',
        components: {
            // pdf,
        },
        props: {
            uploadExcelDialog: null,
        },
        data() {
            return {
                dialogFull: false,
                token: null,
                companyId: null,
                branchId: null,
                base64ExcelFile: null,
                reload: false,
                companyOptions: [],
                branchOptions: [],
                //是否需要公司ID
                isCompanyid: true,
                //是否需要分店ID
                isBranchid: true,
            }
        },
        mounted() {
        },
        methods: {
            init() {
                this.isCompanyid = JSON.parse(localStorage.getItem('isCompanyid'))
                this.isBranchid = JSON.parse(localStorage.getItem('isBranchid'))
                const loginReturnDto = localStorage.getItem('loginReturnDto')
                if (loginReturnDto != null && JSON.parse(loginReturnDto) != null) {
                    this.loginReturnDto = JSON.parse(loginReturnDto)
                    this.token = this.loginReturnDto.token
                    if (this.isCompanyid) {
                        this.companyId = this.loginReturnDto.companyId
                        console.log('uploadExcelDialog取得loginReturnDto.companyId=' + this.companyId)
                    }
                    if (this.isBranchid) {
                        this.branchId = this.loginReturnDto.branchId
                        console.log('uploadExcelDialog取得loginReturnDto.branchId=' + this.branchId)
                    }
                    // console.log("uploadExcelDialog取得token=" + this.token);
                }
                this.searchCompanyList()
                this.searchBranchList()
            },
            closeDialog() {
                console.log('關閉Pop')
                this.base64ExcelFile = null
                this.$refs.fileInput.value = '' // 清空文件輸入框
                this.$emit('update:reload', this.reload)
                this.$emit('closeDialog')
            },
            async uploadExcel() {
                //上傳的Excel資料
                //開啟特效
                let tmp = this
                try {
                    console.log('匯入客戶資料....')
                    tmp.loadingView()
                    let url = 'consumer/uploadExcelFile'
                    let parameter = {
                        companyId: this.companyId,
                        branchId: this.branchId,
                        base64ExcelFile: this.base64ExcelFile,
                        filePath: null,
                    }
                    if (!this.checkAddParameter(parameter)) {
                        console.log('匯入驗證資料失敗!')
                        return
                    }
                    tmp.reload = true
                    let responseData = await tmp.$api.doPostByToken(parameter, url, this.token)
                    if (responseData != null && responseData != '') {
                        var importSuccess = responseData.importSuccess;
                        var importFailed = responseData.importFailed;
                        var base64Data = responseData.base64Data;
                        tmp.$api.openMsg(importSuccess + "," + importFailed, 'success');
                        tmp.downloadBase64AsTxt(base64Data, "failed_entries.txt");
                        // 在延迟 2000 毫秒（2 秒）后执行代码
                        setTimeout(function () {
                            tmp.closeDialog()
                            // tmp.$emit('init');
                        }, 3000)
                    } else {
                        tmp.$api.openMsg('匯入客戶資料失敗!', 'error')
                        console.log('匯入客戶資料失敗!')
                    }
                } catch (error) {
                    console.log('匯入客戶資料發生異常! error=' + error)
                    tmp.$api.openMsg('匯入客戶資料發生異常!', 'error')
                } finally {
                    tmp.closeLoading()
                }
            },
            downloadBase64AsTxt(base64Data, fileName) {
                if (base64Data != null && base64Data !== '') {
                    // 將 Base64 字符串解碼為二進制數組
                    const byteCharacters = atob(base64Data);
                    const byteNumbers = new Array(byteCharacters.length);
                    for (let i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    const byteArray = new Uint8Array(byteNumbers);

                    // 創建 Blob 對象，並指定類型為 'text/plain;charset=utf-8'
                    const blob = new Blob([byteArray], {type: 'text/plain;charset=utf-8'});

                    // 創建一個指向 Blob 的臨時 URL
                    const url = URL.createObjectURL(blob);

                    // 創建一個隱藏的 <a> 元素，模擬點擊下載
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = fileName;  // 指定下載文件的名稱
                    document.body.appendChild(a);  // 將 <a> 元素附加到 DOM
                    a.click();  // 模擬點擊以觸發下載
                    document.body.removeChild(a);  // 移除 <a> 元素
                    console.log('匯入失敗的資料已下載!');
                } else {
                    console.error('Base64 data is empty or null');
                }
            },
            loadingView() {
                //開啟載入特效
                this.loadingStatus = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)',
                })
            },
            closeLoading() {
                //關閉載入特效
                if (this.loadingStatus != null) {
                    this.loadingStatus.close()
                }
            },
            async handleFileUpload(event) {
                let tmp = this
                console.log('開始載入檔案!')
                try {
                    const file = event.target.files[0] // 獲取上傳的文件
                    // 檢查文件擴展名
                    if (file == null || !file.name.endsWith('.xlsx')) {
                        tmp.$api.openMsg('僅支援上傳.xlsx 文件!', 'error')
                        return
                    }
                    console.log('Filename=' + file.name)
                    // 使用Promise處理文件讀取
                    const base64Str = await this.readFileAsBase64(file)
                    this.base64ExcelFile = base64Str // 將 base64 字串賦值給 data 中的 base64String 變量
                    console.log('匯入客戶資料! base64ExcelFile=' + this.base64ExcelFile)
                } catch (e) {
                    console.log('載入檔案發生異常! error=' + e)
                }
            },
            readFileAsBase64(file) {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader() // 創建文件讀取器
                    reader.onload = (e) => {
                        const binaryStr = e.target.result
                        const base64Str = btoa(binaryStr) // 將文件轉換成 base64 字串
                        resolve(base64Str)
                    }
                    reader.onerror = (error) => {
                        reject(error)
                    }
                    reader.readAsBinaryString(file) // 讀取文件為二進制字符串
                })
            },
            async searchCompanyList() {
                //查詢全部公司下拉選單資料
                let tmp = this
                let url = 'login/search/searchCompanyOptions'
                let parameter = {
                    companyId: this.companyId,
                    companyName: '',
                    isActive: true,
                }
                //取得列表資料
                let responseData = await tmp.$api.doPost(parameter, url)
                if (responseData != null) {
                    tmp.companyOptions = responseData
                    console.log('取得下拉選單資料 companyOptions=' + tmp.companyOptions)
                }
            },
            async searchBranchList() {
                //查詢全部分店下拉選單資料
                let tmp = this
                let url = 'login/search/searchBranchOptions'
                let parameter = {
                    companyId: this.companyId,
                    branchId: this.branchId,
                    branchName: '',
                    isActive: true,
                }
                //取得列表資料
                let responseData = await tmp.$api.doPostByToken(parameter, url, this.token)
                if (responseData != null) {
                    tmp.branchOptions = responseData
                    console.log('取得下拉選單資料 companyOptions=' + tmp.companyOptions)
                }
            },
            handleCompanyChange(value) {
                console.log('Selected company ID:', value)
                if (!this.isCompanyid) {
                    this.companyId = value
                    this.branchId = null
                    this.searchBranchList()
                }
            },
            checkAddParameter(parameter) {
                var ststus = true
                if (parameter != null && parameter != '') {
                    if (parameter.companyId == null || !parameter.companyId) {
                        console.log('所屬公司不可為空!')
                        this.$api.openMsg('所屬公司不可為空!', 'error')
                        return false
                    }
                    if (parameter.branchId == null || !parameter.branchId) {
                        console.log('所屬分店不可為空!')
                        this.$api.openMsg('所屬分店不可為空!', 'error')
                        return false
                    }
                    if (this.base64ExcelFile == null || this.base64ExcelFile == '') {
                        console.log('匯入的檔案不可為空!')
                        this.$api.openMsg('匯入的檔案不可為空!', 'error')
                        return false
                    }
                } else {
                    console.log('必填資料不可為空!')
                    this.$api.openMsg('必填資料不可為空!', 'error')
                    return false
                }
                return ststus
            },
            async downloadExcel() {
                console.log('下載範例資料!')
                let tmp = this
                try {
                    // 获取Base64编码的文件内容
                    let base64Encoded = await tmp.$api.doGetByToken('consumer/download/excel', this.token)
                    //console.log('下載範例資料 base64Encoded='+base64Encoded)
                    // 如果Base64编码字符串包含前缀，去除它
                    const base64Data = base64Encoded.includes('base64,')
                        ? base64Encoded.split('base64,')[1]
                        : base64Encoded;

                    // 验证Base64数据格式
                    const base64Pattern = /^[A-Za-z0-9+/=]+$/;
                    if (!base64Pattern.test(base64Data)) {
                        throw new Error('Base64 data is not valid');
                    }

                    // 将Base64字符串解码为字节数组
                    const binaryString = window.atob(base64Data);
                    const len = binaryString.length;
                    const bytes = new Uint8Array(len);
                    for (let i = 0; i < len; i++) {
                        bytes[i] = binaryString.charCodeAt(i);
                    }

                    // 创建Blob对象
                    const blob = new Blob([bytes], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});

                    // 创建一个下载链接并点击它以下载文件
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'example.xlsx');  // 设置下载文件的名称
                    document.body.appendChild(link);
                    link.click();

                    // 清理URL对象和DOM元素
                    window.URL.revokeObjectURL(url);
                    document.body.removeChild(link);
                } catch (error) {
                    console.error('下載失敗:', error)
                }
                this.closeDialog()
            },
        },
    }
</script>

<style scoped></style>
