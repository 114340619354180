import {Message} from 'element-ui'
import axios from 'axios'

const api = {
	//duration:显示时间, 毫秒
	//showClose:是否显示关闭按钮
	//center:文字是否居中
	//type:主题,success/warning/info/error
	//message:消息文字
	openMsg(message, type) {
		//訊息視窗
		Message({
			showClose: true,
			message: message,
			type: type,
			duration: 3000,
			center: false,
		})
	},
	async downloadFile(id, fileName, url) {
		//下載檔案
		let printerStatus = false
		let tmp = this
		let data = {
			id: id,
		}
		await axios({
			method: 'post',
			url: url,
			data: data,
			timeout: 60000,
			responseType: 'blob',
			headers: {'Content-Type': 'application/json'},
		})
			.then(function (response) {
				if (response.status == '200' && response.data != null && fileName != null) {
					tmp.download(response.data, fileName)
					console.log('下載成功!')
					tmp.openMsg('下載檔案成功!', 'success')
					printerStatus = true
				} else {
					console.log('下載失敗!')
					tmp.openMsg('下載檔案失敗!', 'error')
					printerStatus = false
				}
			})
			.catch(function (error) {
				console.log(error)
				tmp.openMsg('下載檔案失敗!', 'error')
				printerStatus = false
			})
		return printerStatus
	},
	download(data, fileName) {
		//檔案轉換輸出
		if (!data) {
			return
		}
		let url = window.URL.createObjectURL(new Blob([data]))
		let link = document.createElement('a')
		link.style.display = 'none'
		link.href = url
		link.setAttribute('download', fileName)
		document.body.appendChild(link)
		link.click()
	},
	async printerFile(id, url) {
		//列印檔案
		let printerStatus = false
		let tmp = this
		let data = {
			id: id,
		}
		await axios({
			method: 'post',
			url: url,
			data: data,
			timeout: 60000,
			headers: {'Content-Type': 'application/json'},
		})
			.then(function (response) {
				if (response.data.code == '0000') {
					console.log('列印檔案成功!')
					tmp.openMsg('列印檔案成功!', 'success')
					printerStatus = true
				} else {
					console.log('列印檔案失敗!')
					tmp.openMsg('列印檔案失敗!', 'error')
					printerStatus = false
				}
			})
			.catch(function (error) {
				console.log('列印檔案失敗! error=' + error)
				tmp.openMsg('列印檔案失敗!', 'error')
				printerStatus = false
			})
		return printerStatus
	},
	async doPost(parameter, url) {
		//post取得資料
		let responseData = null
		await axios({
			method: 'post',
			url: url,
			data: parameter,
			headers: {'Content-Type': 'application/json'},
		})
			.then(function (response) {
				if (response.data.code == '0000') {
					responseData = response.data.data
					console.log('取得資料成功!')
				} else {
					console.log('取得資料失敗!')
				}
			})
			.catch(function (error) {
				console.log(error)
			})
		return responseData
	},
	async doGet(url) {
		// get取得資料
		let responseData = null
		await axios({
			method: 'get', // 改成 GET 请求
			url: url,
			headers: {
				'Content-Type': 'application/json',
			},
		})
			.then(function (response) {
				if (response.data.code === '0000') {
					responseData = response.data.data
					console.log('取得資料成功!')
				} else {
					console.log('取得資料失敗!')
				}
			})
			.catch(function (error) {
				console.log(error)
			})
		return responseData
	},
	async doPostByMsg(parameter, url) {
		//post取得資料
		let tmp = this
		let responseData = null
		await axios({
			method: 'post',
			url: url,
			data: parameter,
			headers: {'Content-Type': 'application/json'},
		})
			.then(function (response) {
				if (response.data.code == '0000') {
					responseData = response.data.data
					tmp.openMsg(response.data.msg, 'success')
					console.log('執行成功!')
				} else {
					console.log('response.data.msg=' + response.data.msg)
					tmp.openMsg(response.data.msg, 'error')
					console.log('執行失敗!')
				}
			})
			.catch(function (error) {
				console.log(error)
			})
		return responseData
	},
	async doPostByMsgToken(parameter, url, token) {
		//post取得資料
		let tmp = this
		let responseData = null
		await axios({
			method: 'post',
			url: url,
			data: parameter,
			headers: {
				'Content-Type': 'application/json',
				Authorization: `${token}`, // 添加 token 到 headers
			},
		})
			.then(function (response) {
				if (response.data.code == '0000') {
					responseData = response.data.data
					tmp.openMsg(response.data.msg, 'success')
					console.log('執行成功!')
				} else {
					console.log('response.data.msg=' + response.data.msg)
					tmp.openMsg(response.data.msg, 'error')
					console.log('執行失敗!')
				}
			})
			.catch(function (error) {
				console.log(error)
			})
		return responseData
	},
	async doPostByToken(parameter, url, token) {
		// post取得資料
		let responseData = null
		await axios({
			method: 'post',
			url: url,
			data: parameter,
			headers: {
				'Content-Type': 'application/json',
				Authorization: `${token}`, // 添加 token 到 headers
			},
		})
			.then(function (response) {
				if (response.data.code === '0000') {
					responseData = response.data.data
					console.log('取得資料成功!')
				} else {
					console.log('取得資料失敗!')
				}
			})
			.catch(function (error) {
				console.log(error)
			})
		return responseData
	},
	async doGetByToken(url, token) {
		// get取得資料
		// 使用 await 直接等待 axios 的响应
		let response = await axios({
			method: 'get',
			url: url,
			headers: {
				'Content-Type': 'application/json',
				Authorization: `${token}`,
			},
		})
		// 检查响应码并返回数据
		if (response.data.code === '0000') {
			console.log('取得資料成功!')
			return response.data.data
		} else {
			console.log('取得資料失敗!')
			return null
		}
	},
	async doDeleteByToken(url, token) {
		// get取得資料
		let responseData = null
		await axios({
			method: 'delete', // 改成 GET 请求
			url: url,
			headers: {
				'Content-Type': 'application/json',
				Authorization: `${token}`, // 添加 token 到 headers
			},
		})
			.then(function (response) {
				if (response.data.code === '0000') {
					responseData = response.data.data
					console.log('删除数据成功!')
				} else {
					console.log('删除数据失败!')
				}
			})
			.catch(function (error) {
				console.error('请求出错:', error)
			})
		return responseData
	},
	async dataURItoBlob(base64Data) {
		//console.log(base64Data);//data:image/png;base64,
		var byteString
		if (base64Data.split(',')[0].indexOf('base64') >= 0)
			byteString = atob(base64Data.split(',')[1]) //base64 解碼
		else {
			byteString = unescape(base64Data.split(',')[1])
		}
		var mimeString = base64Data.split(',')[0].split(':')[1].split(';')[0] //mime型別 -- image/png

		// var arrayBuffer = new ArrayBuffer(byteString.length); //建立緩衝陣列
		// var ia = new Uint8Array(arrayBuffer);//建立檢視
		var ia = new Uint8Array(byteString.length) //建立檢視
		for (var i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i)
		}
		var blob = new Blob([ia], {
			type: mimeString,
		})
		return blob
	},
	findAndCategorizePermissions(resultVo, page, functionCodes) {
		//權限控制
		// 初始化分类结果的对象
		const categorizedResults = functionCodes.reduce((acc, code) => {
			acc[code] = []
			return acc
		}, {})
		// 遍历数据并分类
		resultVo.forEach((item) => {
			if (item.page === page && functionCodes.includes(item.functionCode)) {
				categorizedResults[item.functionCode].push(item)
			}
		})
		return categorizedResults
	},
	hasDataForSpecificFunctionCode(results, specificFunctionCode) {
		return results[specificFunctionCode] && results[specificFunctionCode].length > 0
	},
	validateInput(value, type) {
		let regex = null
		let replacement = ''
		let newValue = null
		switch (type) {
			case 'userName':
				// 检查帳號
				regex = /[^a-zA-Z0-9-_]/g
				newValue = value.replace(regex, replacement)
				break
			case 'errorCount':
				// 检查錯誤次數
				regex = /[^\d]/g
				newValue = value.replace(regex, replacement)
				break
			case 'phone':
			case 'contactPhone':
				// 检查手機號碼
				// 去除所有非数字字符
				value = value.replace(/[^0-9]/g, '')
				// 保留以09开头的数字序列，其他去掉
				if (value.startsWith('09')) {
					newValue = value.slice(0, 10) // 保留前10个字符
				} else {
					newValue = ''
				}
				break
			case 'age':
				// 检查年齡
				regex = /^(?:[1-9]\d*)?$/ // 匹配不以0开头的数字串
				newValue = regex.test(value) ? value : ''
				break
			case 'name':
			case 'contactName':
				// 检查聯絡人
				regex = /[^\w\s\u4e00-\u9fa5]|_/g
				newValue = value.replace(regex, replacement)
				break
			case 'identifier':
				// 检查資產識別碼
				//regex = /[^a-zA-Z0-9-]/g;
				regex = /[^\w\d-_]/g
				replacement = ''
				newValue = value.replace(regex, replacement)
				break
			case 'email':
				// 检查邮箱格式
				regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
				newValue = regex.test(value) ? value : ''
				break
			default:
				return value
		}
		return newValue
	},
}
export default api
