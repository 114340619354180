export default {
	data() {
		return {
			windowWidth: window.innerWidth,
		}
	},
	computed: {
		menuPathButton() {
			//console.log('windowWidth=' + this.windowWidth)
			if (this.windowWidth <= 600) {
				return 4 // 小屏幕设备时占满一行
			} else if (this.windowWidth <= 1024) {
				return 2 // 中等屏幕设备时占半行
			} else if (this.windowWidth <= 1280) {
				return 2 // 中等屏幕设备时占半行
			} else {
				return 1 // 大屏幕设备时占1/6行
			}
		},
		menuPathDiv() {
			//console.log('windowWidth=' + this.windowWidth)
			if (this.windowWidth <= 600) {
				return 18 // 小屏幕设备时占满一行
			} else if (this.windowWidth <= 1024) {
				return 15 // 中等屏幕设备时占半行
			} else if (this.windowWidth <= 1280) {
				return 15 // 中等屏幕设备时占半行
			} else {
				return 22 // 大屏幕设备时占1/6行
			}
		},
		headerTitleSpan() {
			//console.log('windowWidth=' + this.windowWidth)
			if (this.windowWidth <= 600) {
				return 12 // 小屏幕设备时占满一行
			} else if (this.windowWidth <= 1024) {
				return 17 // 中等屏幕设备时占半行
			} else if (this.windowWidth <= 1280) {
				return 17 // 中等屏幕设备时占半行
			} else {
				return 18 // 大屏幕设备时占1/6行
			}
		},
		headerNicknameSpan() {
			if (this.windowWidth <= 600) {
				return 12 // 小屏幕设备时占满一行
			} else if (this.windowWidth <= 1024) {
				return 7 // 中等屏幕设备时占半行
			} else if (this.windowWidth <= 1280) {
				return 7 // 中等屏幕设备时占半行
			} else {
				return 6 // 大屏幕设备时占1/6行
			}
		},
		selectTitleMid() {
			if (this.windowWidth <= 600) {
				return 21 // 小屏幕设备时占满一行
			} else if (this.windowWidth <= 1024) {
				return 4 // 中等屏幕设备时占半行
			} else {
				return 24 // 大屏幕设备时占1/6行
			}
		},
		selectTitleSpan() {
			if (this.windowWidth <= 600) {
				return 21 // 小屏幕设备时占满一行
			} else if (this.windowWidth <= 1024) {
				return 4 // 中等屏幕设备时占半行
			} else {
				return 3 // 大屏幕设备时占1/6行
			}
		},
		selectInpudSpan() {
			if (this.windowWidth <= 600) {
				return 21 // 小屏幕设备时占满一行
			} else if (this.windowWidth <= 1024) {
				return 6 // 中等屏幕设备时占半行
			} else {
				return 4 // 大屏幕设备时占1/6行
			}
		},
		selectInpudRadioSpan() {
			if (this.windowWidth <= 600) {
				return 21 // 小屏幕设备时占满一行
			} else if (this.windowWidth <= 1024) {
				return 10 // 中等屏幕设备时占半行
			} else {
				return 8 // 大屏幕设备时占1/6行
			}
		},
		selectBattonSpan() {
			if (this.windowWidth <= 600) {
				return 8 // 小屏幕设备时占满一行
			} else if (this.windowWidth <= 1024) {
				return 6 // 中等屏幕设备时占半行
			} else {
				return 5 // 大屏幕设备时占1/6行
			}
		},
		selectInpudImg() {
			if (this.windowWidth <= 600) {
				return 21 // 小屏幕设备时占满一行
			} else if (this.windowWidth <= 1024) {
				return 21 // 中等屏幕设备时占半行
			} else {
				return 4 // 大屏幕设备时占1/6行
			}
		},
		assetsModelTypeElCol() {
			if (this.windowWidth <= 600) {
				return 24 // 小屏幕设备时占满一行
			} else if (this.windowWidth <= 1024) {
				return 12 // 中等屏幕设备时占半行
			} else {
				return 8 // 大屏幕设备时占1/6行
			}
		},
		assetsModelTypeTitleSpan() {
			if (this.windowWidth <= 600) {
				return 4 // 小屏幕设备时占满一行
			} else if (this.windowWidth <= 1024) {
				return 5 // 中等屏幕设备时占半行
			} else if (this.windowWidth <= 1280) {
				return 6 // 中等屏幕设备时占半行
			} else {
				return 4 // 大屏幕设备时占1/6行
			}
		},
		assetsModelTypeInpudSpan() {
			if (this.windowWidth <= 600) {
				return 16 // 小屏幕设备时占满一行
			} else if (this.windowWidth <= 1024) {
				return 14 // 中等屏幕设备时占半行
			} else if (this.windowWidth <= 1280) {
				return 12 // 中等屏幕设备时占半行
			} else {
				return 16 // 大屏幕设备时占1/6行
			}
		},
		assetsModelTypeBattonSpan() {
			if (this.windowWidth <= 600) {
				return 2 // 小屏幕设备时占满一行
			} else if (this.windowWidth <= 1024) {
				return 2 // 中等屏幕设备时占半行
			} else {
				return 2 // 大屏幕设备时占1/6行
			}
		},
		selectDetailedTitleSpan() {
			if (this.windowWidth <= 600) {
				return 21 // 小屏幕设备时占满一行
			} else if (this.windowWidth <= 1024) {
				return 3 // 中等屏幕设备时占半行
			} else {
				return 2 // 大屏幕设备时占1/6行
			}
		},
		selectDetailedInpudSpan() {
			if (this.windowWidth <= 600) {
				return 23 // 小屏幕设备时占满一行
			} else if (this.windowWidth <= 1024) {
				return 7 // 中等屏幕设备时占半行
			} else {
				return 5 // 大屏幕设备时占1/6行
			}
		},
	},
	methods: {
		updateWindowWidth() {
			this.windowWidth = window.innerWidth
		},
	},
	mounted() {
		window.addEventListener('resize', this.updateWindowWidth)
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.updateWindowWidth)
	},
}
