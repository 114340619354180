<template>
	<div>
		<el-button v-if="showDetailedButton" type="primary" class="btn" v-on:click="detailedClick(scope.row)"
			>詳細
		</el-button>
		<el-button v-if="showEditButton" type="primary" :class="['btn', buttonClass]" v-on:click="editClick(scope.row)"
			>编辑</el-button
		>
		<el-button v-if="showDeleteButton" type="primary" class="btn" v-on:click="deleteClick(scope.row)">刪除</el-button>
	</div>
</template>

<!-- 表格擴充欄位-->
<script>
export default {
	name: 'Expand',
	props: {
		scope: Object,
		inDetailedButton: null,
		inEditButton: null,
		inDeleteButton: null,
	},
	data() {
		return {
			showDetailedButton: null,
			showEditButton: null,
			showDeleteButton: null,
			inEditButtonStatus: null,
		}
	},
	computed: {
		buttonClass() {
			switch (this.inEditButtonStatus) {
				case 2:
					return 'btn-gray'
				case 3:
					return 'btn-red'
				default:
					return ''
			}
		},
	},
	mounted() {
		let tmp = this
		tmp.init()
		// setTimeout(function () {
		// 	tmp.buttonStyle()
		// }, 500)
	},
	methods: {
		init() {
			this.showDetailedButton = this.inDetailedButton
			this.showEditButton = this.inEditButton
			this.showDeleteButton = this.inDeleteButton
			if (this.scope.row.orderStatus != null) {
				this.inEditButtonStatus = this.scope.row.orderStatus
			}
		},
		editClick(row) {
			console.log('Expand row=', row)
			this.$emit('editClick', row)
		},
		deleteClick(row) {
			console.log('Expand row=', row)
			this.$emit('deleteClick', row)
		},
		detailedClick(row) {
			console.log('Expand row=', row)
			this.$emit('detailedClick', row)
		},
		updateColor(){
			//console.log('變更按鈕顏色...')
			this.init();
		}
	},
}
</script>

<style scoped>
.btn {
	background-color: #015BB3;
	color: #fff;
}

.btn:hover {
  color: #333;
  background-color: #1890FF;
}

.btn-gray {
  background-color: #d3d3d3;
  color: #333;
}

.btn-gray:hover {
  color: #333;
  background-color: #d3d3d3;
}

.btn-red {
  background-color: #f44336;
  color: #fff;
}

.btn-red:hover {
  color: #fff;
  background-color: #f44336;
}
</style>
