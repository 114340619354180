export default [
	{
		prop: 'companyName',
		label: '公司名稱',
		minWidth: '150',
		showOverflowTooltip: false,
		align: 'center',
		sortable: true,
	},
	{
		prop: 'branchName',
		label: '分店名稱',
		minWidth: '150',
		showOverflowTooltip: false,
		align: 'center',
		sortable: true,
	},
	{
		prop: 'nickName',
		label: '暱稱',
		minWidth: '150', //列寬
		showOverflowTooltip: false, //設置表格文字過長顯示省略號
		align: 'center', //對齊方式
		sortable: true,
	},
	{
		prop: 'brand',
		label: '資產品牌',
		minWidth: '150', //列寬
		showOverflowTooltip: false, //設置表格文字過長顯示省略號
		align: 'center', //對齊方式
		sortable: true,
	},
	{
		prop: 'assetsModel',
		label: '資產類型',
		minWidth: '150', //列寬
		showOverflowTooltip: false, //設置表格文字過長顯示省略號
		align: 'center', //對齊方式
		sortable: true,
	},
	{
		prop: 'assetsColor',
		label: '資產顏色',
		minWidth: '150', //列寬
		showOverflowTooltip: false, //設置表格文字過長顯示省略號
		align: 'center', //對齊方式
		sortable: true,
	},
	{
		prop: 'identifier',
		label: '資產識別碼',
		minWidth: '150',
		showOverflowTooltip: false,
		align: 'center',
		sortable: true,
	},
	{
		prop: 'priceRange',
		label: '價格區間',
		minWidth: '150',
		showOverflowTooltip: false,
		align: 'center',
		sortable: true,
	},
	// {
	// 	prop: 'isImportedString',
	// 	label: '國內/國外',
	// 	minWidth: '150',
	// 	showOverflowTooltip: false,
	// 	align: 'center',
	// 	sortable: true,
	// },
	{
		prop: 'serviceLevel',
		label: '資產服務等級',
		minWidth: '200',
		showOverflowTooltip: false,
		align: 'center',
		sortable: true,
	},
	{
		prop: 'startDate',
		label: '會員資格起算日',
		minWidth: '200',
		showOverflowTooltip: false,
		align: 'center',
		sortable: true,
	},
	{
		prop: 'endDate',
		label: '會員資格終止日',
		minWidth: '200',
		showOverflowTooltip: false,
		align: 'center',
		sortable: true,
	},
	{
		prop: 'status',
		label: '會員資格狀態',
		minWidth: '200',
		showOverflowTooltip: false,
		align: 'center',
		sortable: true,
	},
	{
		prop: 'isUseString',
		label: '會員資格使用',
		minWidth: '200',
		showOverflowTooltip: false,
		align: 'center',
		sortable: true,
	},
	{
		prop: 'statusChangeDate',
		label: '會員資格狀態變更日',
		minWidth: '250',
		showOverflowTooltip: false,
		align: 'center',
		sortable: true,
	},
	{
		prop: 'purchaseAmount',
		label: '會員資格購買金額',
		minWidth: '200',
		showOverflowTooltip: false,
		align: 'center',
		sortable: true,
	},
	{
		prop: 'discountPercentage',
		label: '折扣率',
		minWidth: '150',
		showOverflowTooltip: false,
		align: 'center',
		sortable: true,
	},
	{
		prop: 'discountAmount',
		label: '折扣金額',
		minWidth: '150',
		showOverflowTooltip: false,
		align: 'center',
		sortable: true,
	},
	{
		prop: 'isActiveString',
		label: '是否啟用',
		minWidth: '150',
		showOverflowTooltip: false,
		align: 'center',
		sortable: true,
	},
	{
		prop: 'contactName',
		label: '聯絡人',
		minWidth: '150',
		showOverflowTooltip: false,
		align: 'center',
		sortable: true,
	},
	{
		prop: 'contactGenderString',
		label: '聯絡人性別',
		minWidth: '150',
		showOverflowTooltip: false,
		align: 'center',
		sortable: true,
	},
	{
		prop: 'contactPhoneNumber',
		label: '聯絡人電話',
		minWidth: '150',
		showOverflowTooltip: false,
		align: 'center',
		sortable: true,
	},
	// {
	// 	prop: 'remark',
	// 	label: '備註',
	// 	minWidth: '150',
	// 	showOverflowTooltip: false,
	// 	align: 'center',
	// 	sortable: true,
	// },
	{
		prop: 'action',
		label: '操作',
		minWidth: '200',
		showOverflowTooltip: true,
		align: 'center',
		render: 'action',
		sortable: false,
	},
]
