<template>
    <div class="popDialog">
        <el-dialog
                v-if="addDialog"
                width="95%"
                top="30px"
                :visible.sync="addDialog"
                :before-close="closeDialog"
                :fullscreen="dialogFull"
                custom-class="custom-dialog"
        >
            <template v-slot:title>
                <el-row>
                    <el-col :span="24" class="selectTitleMax">
                        <span>新增會員帳號資料</span>
                    </el-col>
                </el-row>
            </template>
            <el-row>
                <el-col :span="24">
                    <div class="containerDiv">
                        <el-row>
                            <el-col :span="24" class="selectTitleMid">
                                <span>帳號資料</span>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="selectTitleSpan" class="selectTitle">
                                <span>公司<span class="red">*</span>:</span>
                            </el-col>
                            <el-col :span="selectInpudSpan" class="selectInpud">
                                <el-select v-model="companyId" placeholder="" @change="handleCompanyChange"
                                           :disabled="isCompanyid">
                                    <el-option :key="'placeholder'" :label="'請選擇'" :value="null"></el-option>
                                    <el-option
                                            v-for="item in companyOptions"
                                            :key="item.companyId"
                                            :label="item.companyName"
                                            :value="item.companyId"
                                            :disabled="item.disabled"
                                    >
                                    </el-option>
                                </el-select>
                            </el-col>
                            <el-col :span="selectTitleSpan" class="selectTitle">
                                <span>分店<span class="red">*</span>:</span>
                            </el-col>
                            <el-col :span="selectInpudSpan" class="selectInpud">
                                <el-select v-model="branchId" placeholder="" :disabled="isBranchid">
                                    <el-option :key="'placeholder'" :label="'請選擇'" :value="null"></el-option>
                                    <el-option
                                            v-for="item in branchOptions"
                                            :key="item.branchId"
                                            :label="item.branchName"
                                            :value="item.branchId"
                                            :disabled="item.disabled"
                                    >
                                    </el-option>
                                </el-select>
                            </el-col>
                            <el-col :span="selectTitleSpan" class="selectTitle">
                                <span>是否啟用<span class="red">*</span>:</span>
                            </el-col>
                            <el-col :span="selectInpudSpan" class="selectInpud">
                                <el-radio-group v-model="isActive">
                                    <el-radio label="1">啟用</el-radio>
                                    <el-radio label="0">不啟用</el-radio>
                                </el-radio-group>
                            </el-col>
                        </el-row>
                        <!--						<el-row>-->
                        <!--							<el-col :span="selectTitleSpan" class="selectTitle">-->
                        <!--								<span>帳號<span class="red">*</span>:</span>-->
                        <!--							</el-col>-->
                        <!--							<el-col :span="selectInpudSpan" class="selectInpud">-->
                        <!--								<el-input-->
                        <!--									v-model="userName"-->
                        <!--									placeholder="請輸入帳號"-->
                        <!--									@input="validateUsername"-->
                        <!--									autocomplete="off"-->
                        <!--								></el-input>-->
                        <!--							</el-col>-->
                        <!--							<el-col :span="selectTitleSpan" class="selectTitle">-->
                        <!--								<span>密碼<span class="red">*</span>:</span>-->
                        <!--								&lt;!&ndash;                                <tr><span class="red" style="font-size: 10px">(至少8碼，需包含大小寫，數字，特殊符號)</span></tr>&ndash;&gt;-->
                        <!--							</el-col>-->
                        <!--							<el-col :span="selectInpudSpan" class="selectInpud">-->
                        <!--								<el-input-->
                        <!--									:type="inputType"-->
                        <!--									v-model="password"-->
                        <!--									placeholder="需包含大小寫,數字,特殊符號"-->
                        <!--									style="width: calc(100% - 30px)"-->
                        <!--									autocomplete="new-password"-->
                        <!--								></el-input>-->
                        <!--								<span class="eye-icon" @click="togglePasswordVisibility"> {{ passwordVisible ? '👁️' : ' 👁️‍🗨️' }}</span>-->
                        <!--							</el-col>-->
                        <!--						</el-row>-->
                        <el-row>
<!--                            <el-col :span="selectTitleSpan" class="selectTitle">-->
<!--                                <span>角色<span class="red">*</span>:</span>-->
<!--                            </el-col>-->
<!--                            <el-col :span="selectInpudSpan" class="selectInpud">-->
<!--                                <el-select v-model="role" placeholder="">-->
<!--                                    <el-option :key="'placeholder'" :label="'請選擇'" :value="null"></el-option>-->
<!--                                    <el-option-->
<!--                                            v-for="item in roleOptions"-->
<!--                                            :key="item.value"-->
<!--                                            :label="item.label"-->
<!--                                            :value="item.value"-->
<!--                                            :disabled="item.disabled"-->
<!--                                    >-->
<!--                                    </el-option>-->
<!--                                </el-select>-->
<!--                            </el-col>-->
                            <el-col :span="selectTitleSpan" class="selectTitle">
                                <span>信箱:</span>
                            </el-col>
                            <el-col :span="selectInpudSpan" class="selectInpud">
                                <el-input v-model="mail" placeholder="請輸入信箱" v-on:change="validateEmail"></el-input>
                            </el-col>
                            <el-col :span="selectTitleSpan" class="selectTitle">
                                <span>帳號狀態<span class="red">*</span>:</span>
                            </el-col>
                            <el-col :span="selectInpudSpan" class="selectInpud">
                                <el-radio-group v-model="state">
                                    <el-radio label="0">鎖定</el-radio>
                                    <el-radio label="1">使用中</el-radio>
                                </el-radio-group>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="24" class="selectTitleMid">
                                <span>會員詳細資料</span>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="selectTitleSpan" class="selectTitle">
                                <span>姓:</span>
                            </el-col>
                            <el-col :span="selectInpudSpan" class="selectInpud">
                                <el-input v-model="name" placeholder="請輸入姓"></el-input>
                            </el-col>
                            <el-col :span="selectTitleSpan" class="selectTitle">
                                <span>名:</span>
                            </el-col>
                            <el-col :span="selectInpudSpan" class="selectInpud">
                                <el-input v-model="lastName" placeholder="請輸入名"></el-input>
                            </el-col>
                            <el-col :span="selectTitleSpan" class="selectTitle">
                                <span>稱謂:</span>
                            </el-col>
                            <el-col :span="selectInpudSpan" class="selectInpud">
                                <el-select v-model="title" placeholder="請選擇稱謂">
                                    <el-option :key="'placeholder'" :label="'請選擇'" :value="null"></el-option>
                                    <el-option label="先生" value="先生"></el-option>
                                    <el-option label="小姐" value="小姐"></el-option>
                                </el-select>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="selectTitleSpan" class="selectTitle">
                                <span>暱稱<span class="red">*</span>:</span>
                            </el-col>
                            <el-col :span="selectInpudSpan" class="selectInpud">
                                <el-input v-model="nickName" placeholder="請輸入暱稱" @input="validateName"></el-input>
                            </el-col>
                            <el-col :span="selectTitleSpan" class="selectTitle">
                                <span>性別:</span>
                            </el-col>
                            <el-col :span="selectInpudSpan" class="selectInpud">
                                <el-radio-group v-model="gender">
                                    <el-radio label="1">男</el-radio>
                                    <el-radio label="0">女</el-radio>
                                </el-radio-group>
                            </el-col>
                            <!--							<el-col :span="selectTitleSpan" class="selectTitle">-->
                            <!--								<span>年齡:</span>-->
                            <!--							</el-col>-->
                            <!--							<el-col :span="selectInpudSpan" class="selectInpud">-->
                            <!--								<el-input v-model="age" placeholder="請輸入年齡" type="number" @input="validateAge"></el-input>-->
                            <!--							</el-col>-->
                        </el-row>
                        <el-row>
                            <el-col :span="selectTitleSpan" class="selectTitle">
                                <span>手機號碼<span class="red">*</span>:</span>
                            </el-col>
                            <el-col :span="selectInpudSpan" class="selectInpud">
                                <el-input
                                        v-model="phoneNumber"
                                        placeholder="請輸入手機號碼"
                                        v-on:change="validatePhoneNumber"
                                ></el-input>
                            </el-col>
                            <el-col :span="selectTitleSpan" class="selectTitle">
                                <span>生日:</span>
                            </el-col>
                            <el-col :span="selectInpudSpan" class="selectInpud">
                                <el-date-picker v-model="birthday" type="date" placeholder="選擇日期"
                                                @change="handleDateChange">
                                </el-date-picker>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="selectTitleSpan" class="selectTitle">
                                <span>住址:</span>
                            </el-col>
                            <el-col :span="7" class="selectInpud">
                                <el-input v-model="address" placeholder="請輸入住址"></el-input>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="selectTitleSpan" class="selectTitle">
                                <span>備註:</span>
                            </el-col>
                            <el-col :span="7" class="selectInpud">
                                <el-input v-model="infoRemark" placeholder="請輸入備註"></el-input>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="24" class="selectTitleMid">
                                <span>會員資產資料</span>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="selectTitleSpan" class="selectTitle">
                                <span>資產品牌:</span>
                            </el-col>
                            <el-col :span="selectInpudSpan" class="selectInpud">
                                <el-input v-model="brand" placeholder="請輸入資產品牌"></el-input>
                            </el-col>
                            <el-col :span="selectTitleSpan" class="selectTitle">
                                <span>資產類型<span class="red">*</span>:</span>
                            </el-col>
                            <el-col :span="selectInpudSpan" class="selectInpud">
                                <el-select v-model="assetsModelTypeId" placeholder="">
                                    <el-option :key="'placeholder'" :label="'請選擇'" :value="null"></el-option>
                                    <el-option
                                            v-for="item in assetsOptions"
                                            :key="item.assetsModelTypeId"
                                            :label="item.assetsModel"
                                            :value="item.assetsModelTypeId"
                                            :disabled="item.disabled"
                                    >
                                    </el-option>
                                </el-select>
                            </el-col>
                            <el-col :span="selectTitleSpan" class="selectTitle">
                                <span>資產顏色:</span>
                            </el-col>
                            <el-col :span="selectInpudSpan" class="selectInpud">
                                <el-input v-model="assetsColor" placeholder="請輸入資產顏色"></el-input>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="selectTitleSpan" class="selectTitle">
                                <span>資產識別碼<span class="red">*</span>:</span>
                                <!--                                <tr><span class="red" style="font-size: 10px">(僅能大小寫，數字，特殊符號:-_)</span></tr>-->
                            </el-col>
                            <el-col :span="selectInpudSpan" class="selectInpud">
                                <el-input
                                        v-model="identifier"
                                        placeholder="僅能大小寫，數字，特殊符號:-_"
                                        @input="validateIdentifier"
                                ></el-input>
                            </el-col>
                            <el-col :span="selectTitleSpan" class="selectTitle">
                                <span>價格區間:</span>
                            </el-col>
                            <el-col :span="selectInpudSpan" class="selectInpud">
                                <el-slider
                                        v-model="priceRange"
                                        :min="1000000"
                                        :max="10000000"
                                        :step="1000000"
                                        show-tooltip
                                ></el-slider>
                                <!--								<el-input v-model="priceRange" placeholder="請輸入價格區間"></el-input>-->
                            </el-col>
                            <el-col :span="selectTitleSpan" class="selectTitle">
                                <span>會員資格購買金額:</span>
                            </el-col>
                            <el-col :span="selectInpudSpan" class="selectInpud">
                                <!--                                <el-input v-model="purchaseAmount" placeholder="請輸入會員資格購買金額" type="number"></el-input>-->
                                <el-input
                                        v-model="purchaseAmount"
                                        @blur="formatAmount"
                                        @focus="clearFormat"
                                        placeholder="請輸入會員資格購買金額"
                                        type="text"
                                ></el-input>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="selectTitleSpan" class="selectTitle">
                                <span>資產服務等級<span class="red">*</span>:</span>
                            </el-col>
                            <el-col :span="selectInpudSpan" class="selectInpud">
                                <el-select v-model="serviceLevelTypeId" placeholder=""
                                           @change="handleServiceLevelTypeChange">
                                    <el-option :key="'placeholder'" :label="'請選擇'" :value="null"></el-option>
                                    <el-option
                                            v-for="item in serviceLevelOptions"
                                            :key="item.serviceLevelTypeId"
                                            :label="item.serviceLevel"
                                            :value="item.serviceLevelTypeId"
                                            :disabled="item.disabled"
                                    >
                                    </el-option>
                                </el-select>
                            </el-col>
                            <el-col :span="selectTitleSpan" class="selectTitle">
                                <span>會員資格起算日:</span>
                            </el-col>
                            <el-col :span="selectInpudSpan" class="selectInpud">
                                <el-date-picker v-model="startDate" type="date" placeholder="選擇日期"></el-date-picker>
                            </el-col>
                            <el-col :span="selectTitleSpan" class="selectTitle">
                                <span>會員資格終止日:</span>
                            </el-col>
                            <el-col :span="selectInpudSpan" class="selectInpud">
                                <el-date-picker v-model="endDate" type="date" placeholder="選擇日期"></el-date-picker>
                            </el-col>
                        </el-row>
                        <el-row>
                            <!--                                <el-input-number-->
                            <!--                                        v-model="discountPercentage"-->
                            <!--                                        :min="0"-->
                            <!--                                        :max="100"-->
                            <!--                                        :step="1"-->
                            <!--                                        placeholder="請輸入折扣率">-->
                            <!--                                </el-input-number>-->

                            <el-col :span="selectTitleSpan" class="selectTitle">
                                <span>聯絡人<span class="red">*</span>:</span>
                            </el-col>
                            <el-col :span="selectInpudSpan" class="selectInpud">
                                <el-input v-model="contactName" placeholder="請輸入聯絡人"
                                          @input="validateContactName"></el-input>
                            </el-col>
                            <el-col :span="selectTitleSpan" class="selectTitle">
                                <span>聯絡人性別:</span>
                            </el-col>
                            <el-col :span="selectInpudSpan" class="selectInpud">
                                <el-radio-group v-model="contactGender">
                                    <el-radio label="1">男</el-radio>
                                    <el-radio label="0">女</el-radio>
                                </el-radio-group>
                            </el-col>
                            <el-col :span="selectTitleSpan" class="selectTitle">
                                <span>聯絡人電話<span class="red">*</span>:</span>
                            </el-col>
                            <el-col :span="selectInpudSpan" class="selectInpud">
                                <el-input
                                        v-model="contactPhoneNumber"
                                        placeholder="請輸入聯絡人電話"
                                        v-on:change="validateContactPhoneNumber"
                                ></el-input>
                            </el-col>
                        </el-row>
                        <el-row>
                            <!--                            <el-col :span="selectTitleSpan" class="selectTitle">-->
                            <!--                                <span>折扣率%:</span>-->
                            <!--                            </el-col>-->
                            <!--                            <el-col :span="selectInpudSpan" class="selectInpud">-->
                            <!--                                <el-input v-model="discountPercentage" placeholder="請輸入折扣率" type="number"-->
                            <!--                                          :disabled="true"></el-input>-->
                            <!--                            </el-col>-->
                            <!--                            <el-col :span="selectTitleSpan" class="selectTitle">-->
                            <!--                                <span>折扣金額:</span>-->
                            <!--                            </el-col>-->
                            <!--                            <el-col :span="selectInpudSpan" class="selectInpud">-->
                            <!--                                <el-input v-model="discountAmount" placeholder="請輸入折扣金額" type="number"-->
                            <!--                                          :disabled="true"></el-input>-->
                            <!--                            </el-col>-->
                            <!--                            <el-col :span="selectTitleSpan" class="selectTitle">-->
                            <!--                                <span>國內/國外:</span>-->
                            <!--                            </el-col>-->
                            <!--                            <el-col :span="selectInpudSpan" class="selectInpud">-->
                            <!--                                <el-radio-group v-model="isImported">-->
                            <!--                                    <el-radio label="1">國內</el-radio>-->
                            <!--                                    <el-radio label="0">國外</el-radio>-->
                            <!--                                </el-radio-group>-->
                            <!--                            </el-col>-->
                            <!--							<el-col :span="selectTitleSpan" class="selectTitle">-->
                            <!--								<span>備註:</span>-->
                            <!--							</el-col>-->
                            <!--							<el-col :span="selectInpudSpan" class="selectInpud">-->
                            <!--								<el-input v-model="carRemark" placeholder="請輸入備註"></el-input>-->
                            <!--							</el-col>-->
                        </el-row>
                        <el-row>
                            <el-col :span="24" class="selectTitleMid">
                                <span>會員資產照片(僅支援jpg,最大3M)</span>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="selectTitleSpan" class="selectTitle">
                                <span>照片1:</span>
                            </el-col>
                            <el-col :span="selectInpudImg" class="selectInpud">
                                <img
                                        v-if="photoBase64_1 != null"
                                        :src="'data:image/jpg;base64,' + photoBase64_1"
                                        alt="Image Preview 1"
                                />
                                <input type="file" ref="fileInpu1" @change="handleFileUpload1"
                                       accept="image/jpeg, .jpg, .JPG"/>
                            </el-col>
                            <el-col :span="selectTitleSpan" class="selectTitle">
                                <span>照片2:</span>
                            </el-col>
                            <el-col :span="selectInpudImg" class="selectInpud">
                                <img
                                        v-if="photoBase64_2 != null"
                                        :src="'data:image/jpg;base64,' + photoBase64_2"
                                        alt="Image Preview 1"
                                />
                                <input type="file" ref="fileInput2" @change="handleFileUpload2"
                                       accept="image/jpeg, .jpg, .JPG"/>
                            </el-col>
                            <el-col :span="selectTitleSpan" class="selectTitle">
                                <span>照片3:</span>
                            </el-col>
                            <el-col :span="selectInpudImg" class="selectInpud">
                                <img
                                        v-if="photoBase64_3 != null"
                                        :src="'data:image/jpg;base64,' + photoBase64_3"
                                        alt="Image Preview 1"
                                />
                                <input type="file" ref="fileInput3" @change="handleFileUpload3"
                                       accept="image/jpeg, .jpg, .JPG"/>
                            </el-col>
                        </el-row>
                    </div>
                </el-col>
            </el-row>
            <div slot="footer" class="popBatton">
                <el-button @click="addTemplate" type="primary">儲存</el-button>
                <el-button @click="closeDialog">關閉</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: 'addCustomerDialog',
        components: {},
        props: {
            addDialog: null,
        },
        data() {
            return {
                dialogFull: false,
                loginReturnDto: null,
                token: null,
                companyOptions: [],
                branchOptions: [],
                assetsOptions: [],
                serviceLevelOptions: [],
                serviceLevelTypeId: null,
                roleOptions: [
                    {value: '99', label: '一般會員'},
                    {value: '999', label: 'VIP會員'},
                    {value: '9999', label: 'VVIP會員'},
                ],
                userName: null,
                password: null,
                passwordVisible: false,
                errorCount: 0,
                companyId: null,
                branchId: null,
                role: '99',
                isActive: '1',
                state: '1',
                mail: null,
                name: null,
                lastName: null,
                title: null,
                nickName: null,
                gender: '1',
                age: null,
                birthday: null,
                formattedBirthday: '', // 這裡存儲格式化的日期字串
                phoneNumber: null,
                address: null,
                infoRemark: null,
                brand: null,
                assetsModel: null,
                assetsModelTypeId: null,
                assetsColor: null,
                identifier: null,
                priceRange: 0,
                isImported: '1',
                serviceLevel: null,
                startDate: null,
                endDate: null,
                status: '正常',
                isUse: true,
                statusChangeDate: null,
                purchaseAmount: null,
                discountPercentage: 0,
                discountAmount: 0,
                carRemark: null,
                memberNumber: null,
                photoBase64_1: null,
                photoBase64_2: null,
                photoBase64_3: null,
                reload: false,
                contactName: null,
                contactGender: null,
                contactPhoneNumber: null,
                companyType: null,
                //是否需要公司ID
                isCompanyid: true,
                //是否需要分店ID
                isBranchid: true,
            }
        },
        computed: {
            inputType() {
                return this.passwordVisible ? 'text' : 'password'
            },
        },
        mounted() {
        },
        methods: {
            init() {
                this.isCompanyid = JSON.parse(localStorage.getItem('isCompanyid'))
                this.isBranchid = JSON.parse(localStorage.getItem('isBranchid'))
                const loginReturnDto = localStorage.getItem('loginReturnDto')
                if (loginReturnDto != null && JSON.parse(loginReturnDto) != null) {
                    this.loginReturnDto = JSON.parse(loginReturnDto)
                    this.token = this.loginReturnDto.token
                    //系統管理員權限
                    if (this.isCompanyid) {
                        this.companyId = this.loginReturnDto.companyId
                        this.companyType = this.loginReturnDto.companyType
                        console.log('addCompanyDialog取得loginReturnDto.companyId=' + this.companyId)
                    }
                    //老闆權限
                    if (this.isBranchid) {
                        this.branchId = this.loginReturnDto.branchId
                    }
                    //console.log("addCompanyDialog取得token=" + this.token);
                }
                // this.checkCompanyType();
                this.searchCompanyList()
                this.searchBranchList()
                this.searchAssetsModelTypeList()
                this.searchServiceLevelTypeList()
                this.processDiscount()
            },
            //取得折扣率或折扣金額
            async processDiscount() {
                console.log('-----開始等待 CustomerAssets-----')
                await this.waitForServiceLevelOptions();
                console.log('已收到資料 serviceLevelOptions=', this.serviceLevelOptions + ",serviceLevelTypeId=" + this.serviceLevelTypeId)
                const serviceLevel = this.serviceLevelOptions.find((data) => data.serviceLevelTypeId === this.serviceLevelTypeId)
                // 確保找到了會員資產
                if (serviceLevel) {
                    // 取得資產服務等級狀態
                    if (serviceLevel.discountPercentage != null && serviceLevel.discountPercentage != '') {
                        this.discountPercentage = serviceLevel.discountPercentage;
                        console.log("折扣率 discountPercentage=" + this.discountPercentage);
                    } else {
                        this.discountPercentage = 0;
                    }
                    if (serviceLevel.discountAmount != null && serviceLevel.discountAmount != '') {
                        this.discountAmount = serviceLevel.discountAmount;
                        console.log("折扣金額 discountAmount=" + this.discountAmount);
                    } else {
                        this.discountAmount = 0;
                    }
                }
            },
            waitForServiceLevelOptions() {
                return new Promise((resolve) => {
                    const interval = setInterval(() => {
                        if (this.serviceLevelOptions != null && this.serviceLevelOptions.length > 0 && this.serviceLevelTypeId != null && this.serviceLevelTypeId != '') {
                            clearInterval(interval);
                            resolve();
                        }
                    }, 10); // 每100毫秒检查一次
                });
            },
            closeDialog() {
                console.log('關閉Pop')
                this.$emit('update:reload', this.reload)
                this.$emit('closeDialog')
                // this.checkCompanyType();
            },
            async addTemplate() {
                //新增資料
                let tmp = this
                let url = 'consumer/addCustomer'
                try {
                    var info = {
                        name: this.name,
                        lastName: this.lastName,
                        title: this.title,
                        nickName: this.nickName,
                        gender: this.gender == null ? null : parseInt(this.gender, 10),
                        // age: this.age == null ? null : parseInt(this.age, 10),
                        age: this.age,
                        birthday: this.formattedBirthday == null || this.formattedBirthday == '' ? null : this.formattedBirthday,
                        phoneNumber: this.phoneNumber,
                        address: this.address,
                        remark: this.infoRemark,
                    }
                    var assets = []
                    var assetsDto = {
                        brand: this.brand,
                        assetsModelTypeId: this.assetsModelTypeId,
                        assetsColor: this.assetsColor,
                        identifier: this.identifier,
                        priceRange: this.priceRange,
                        isImported: this.isImported == null ? null : this.isImported == '0' ? false : true,
                        serviceLevelTypeId: this.serviceLevelTypeId,
                        memberNumber: this.memberNumber,
                        startDate: this.startDate,
                        endDate: this.endDate,
                        status: this.status,
                        isUse: this.isUse,
                        statusChangeDate: this.statusChangeDate,
                        purchaseAmount: this.purchaseAmount == null || this.purchaseAmount == 0 ? null : Number(this.purchaseAmount.replace(/,/g, '')),
                        discountPercentage:
                            this.discountPercentage == null || this.discountPercentage == 0 ? 0 : this.discountPercentage,
                        photo1: null,
                        photo2: null,
                        photo3: null,
                        photoBase64_1: this.photoBase64_1,
                        photoBase64_2: this.photoBase64_2,
                        photoBase64_3: this.photoBase64_3,
                        photoPath1: null,
                        photoPath2: null,
                        photoPath3: null,
                        remark: this.carRemark,
                        isActive: true,
                        contactName: this.contactName,
                        contactGender: this.contactGender == null ? null : parseInt(this.contactGender, 10),
                        contactPhoneNumber: this.contactPhoneNumber,
                    }
                    assets.push(assetsDto)
                    let parameter = {
                        userName: this.phoneNumber,
                        password: this.phoneNumber,
                        errorCount: this.errorCount,
                        isActive: this.isActive == null ? null : this.isActive == '0' ? false : true,
                        companyId: this.companyId,
                        branchId: this.branchId,
                        role: this.role == null ? null : parseInt(this.role, 10),
                        state: this.state == null ? null : parseInt(this.state, 10),
                        mail: this.mail,
                        info: info,
                        assets: assets,
                    }
                    if (!this.checkAddParameter(parameter)) {
                        console.log('新增驗證資料失敗!')
                        return
                    }
                    tmp.reload = true
                    tmp.loadingView()
                    let responseData = await tmp.$api.doPostByMsgToken(parameter, url, this.token)
                    if (responseData) {
                        // tmp.$api.openMsg("新增資料完成!", "success");
                        // 在延迟 2000 毫秒（2 秒）后执行代码
                        console.log('新增資料完成!')
                        setTimeout(function () {
                            tmp.closeDialog()
                            // tmp.$emit('init');
                        }, 1000)
                    } else {
                        //tmp.$api.openMsg("新增資料失敗!", "error");
                        console.log('新增資料失敗!')
                    }
                } catch (error) {
                    console.log('新增資料發生異常! error=' + error)
                } finally {
                    tmp.closeLoading()
                }
                tmp.closeLoading()
            },
            checkAddParameter(parameter) {
                var ststus = true
                if (parameter != null && parameter != '') {
                    // if (parameter.userName == null || !parameter.userName) {
                    // 	console.log('帳號不可為空!')
                    // 	this.$api.openMsg('帳號不可為空!', 'error')
                    // 	return false
                    // }
                    // if (parameter.password == null || !parameter.password) {
                    // 	console.log('密碼不可為空!')
                    // 	this.$api.openMsg('密碼不可為空!', 'error')
                    // 	return false
                    // } else if (!this.validatePassword()) {
                    // 	console.log('密碼設定錯誤!')
                    // 	return false
                    // }
                    if (parameter.companyId == null || !parameter.companyId) {
                        console.log('所屬公司不可為空!')
                        this.$api.openMsg('所屬公司不可為空!', 'error')
                        return false
                    }
                    if (parameter.branchId == null || !parameter.branchId) {
                        console.log('所屬分店不可為空!')
                        this.$api.openMsg('所屬分店不可為空!', 'error')
                        return false
                    }
                    console.log('角色=' + parameter.role)
                    if (parameter.role == null) {
                        console.log('角色不可為空!')
                        this.$api.openMsg('角色不可為空!', 'error')
                        return false
                    }
                    var info = parameter.info
                    if (info.nickName == null || !info.nickName) {
                        console.log('暱稱不可為空!')
                        this.$api.openMsg('暱稱不可為空!', 'error')
                        return false
                    }
                    if (info.phoneNumber == null || !info.phoneNumber) {
                        console.log('手機號碼不可為空!')
                        this.$api.openMsg('手機號碼不可為空!', 'error')
                        return false
                    }
                    var assets = parameter.assets[0]
                    if (assets.startDate != null && assets.startDate != '') {
                        if (assets.endDate == null || assets.endDate == '') {
                            console.log('會員資格起算日不為空時會員資格終止日不可為空!')
                            this.$api.openMsg('會員資格起算日不為空時會員資格終止日不可為空!', 'error')
                            return false
                        }
                        const start = new Date(assets.startDate)
                        const end = new Date(assets.endDate)
                        if (start > end) {
                            console.log('parameter.startDate=' + assets.startDate)
                            console.log('parameter.endDate=' + assets.endDate)
                            console.log('會員資格起算日及會員資格終止日設定時間異常!')
                            this.$api.openMsg('會員資格起算日及會員資格終止日設定時間異常!', 'error')
                            return false
                        }
                    }
                    if (assets.endDate != null && assets.endDate != '') {
                        if (assets.startDate == null || assets.startDate == '') {
                            console.log('會員資格終止日不為空時會員資格起算日不可為空!')
                            this.$api.openMsg('會員資格終止日不為空時會員資格起算日不可為空!', 'error')
                            return false
                        }
                    }
                    if (assets.assetsModelTypeId == null || !assets.assetsModelTypeId) {
                        console.log('資產類型不可為空!')
                        this.$api.openMsg('資產類型不可為空!', 'error')
                        return false
                    }
                    if (assets.identifier == null || !assets.identifier) {
                        console.log('資產識別碼不可為空!')
                        this.$api.openMsg('資產識別碼不可為空!', 'error')
                        return false
                    }
                    if (assets.serviceLevelTypeId == null || !assets.serviceLevelTypeId) {
                        console.log('資產服務等級不可為空!')
                        this.$api.openMsg('資產服務等級不可為空!', 'error')
                        return false
                    }
                    if (assets.contactName == null || !assets.contactName) {
                        console.log('聯絡人不可為空!')
                        this.$api.openMsg('聯絡人不可為空!', 'error')
                        return false
                    }
                    if (assets.contactPhoneNumber == null || !assets.contactPhoneNumber) {
                        console.log('聯絡人手機號碼不可為空!')
                        this.$api.openMsg('聯絡人手機號碼不可為空!', 'error')
                        return false
                    }
                } else {
                    console.log('必填資料不可為空!')
                    this.$api.openMsg('必填資料不可為空!', 'error')
                    return false
                }
                return ststus
            },
            loadingView() {
                //開啟載入特效
                this.loadingStatus = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)',
                })
            },
            closeLoading() {
                //關閉載入特效
                if (this.loadingStatus != null) {
                    this.loadingStatus.close()
                }
            },
            async searchCompanyList() {
                //查詢全部公司下拉選單資料
                let tmp = this
                let url = 'login/search/searchCompanyOptions'
                let parameter = {
                    companyId: this.companyId,
                    companyName: '',
                    isActive: true,
                }
                //取得列表資料
                let responseData = await tmp.$api.doPost(parameter, url)
                if (responseData != null) {
                    tmp.companyOptions = responseData
                    console.log('取得下拉選單資料 companyOptions=' + tmp.companyOptions)
                }
            },
            async searchBranchList() {
                //查詢全部分店下拉選單資料
                let tmp = this
                let url = 'login/search/searchBranchOptions'
                let parameter = {
                    companyId: this.companyId,
                    branchId: this.branchId,
                    branchName: '',
                    isActive: true,
                }
                //取得列表資料
                let responseData = await tmp.$api.doPostByToken(parameter, url, this.token)
                if (responseData != null) {
                    tmp.branchOptions = responseData
                    console.log('取得下拉選單資料 companyOptions=' + tmp.companyOptions)
                }
            },
            togglePasswordVisibility() {
                //檢視密碼
                this.passwordVisible = !this.passwordVisible
            },
            handleDateChange(date) {
                if (date) {
                    // 將 Date 對象轉換為字串，格式為 YYYY-MM-DD
                    this.formattedBirthday = date.toISOString().slice(0, 10)
                } else {
                    this.formattedBirthday = ''
                }
            },
            async handleFileUpload1(event) {
                let tmp = this
                console.log('開始載入檔案!')
                try {
                    const file = event.target.files[0] // 獲取上傳的文件
                    // 檢查文件擴展名
                    if (file == null || !file.name.toLowerCase().endsWith('.jpg')) {
                        tmp.$api.openMsg('僅支援上傳.jpg 文件!', 'error')
                        return
                    }
                    if (file == null || (!file.name.toLowerCase().endsWith('.jpg') && !file.name.toLowerCase().endsWith('.jpeg'))) {
                        tmp.$api.openMsg('僅支援上傳 .jpg 或 .jpeg 文件!', 'error')
                        return
                    }

                    // 壓縮圖片
                    const compressedFile = await this.compressImage(file);
                    console.log('壓縮後的文件大小=' + compressedFile.size);

                    // 检查文件大小是否超过限制
                    if (compressedFile.size > 3 * 1024 * 1024) {
                        // 1MB = 1024 * 1024 bytes
                        tmp.$api.openMsg('文件大小不能超过 3MB!', 'error')
                        this.$refs.fileInput1.value = ''
                        return
                    }
                    console.log('Filename=' + compressedFile.name)
                    // 使用Promise處理文件讀取
                    // this.byteArray = await this.readFileAsBytes(file);
                    this.photoBase64_1 = await this.readFileAsBase64(compressedFile)
                    console.log('匯入客戶資料! photoBase64_1=' + this.photoBase64_1)
                } catch (e) {
                    console.log('載入檔案發生異常! error=' + e)
                }
            },
            async handleFileUpload2(event) {
                let tmp = this
                console.log('開始載入檔案!')
                try {
                    const file = event.target.files[0] // 獲取上傳的文件
                    // 檢查文件擴展名
                    if (file == null || (!file.name.toLowerCase().endsWith('.jpg') && !file.name.toLowerCase().endsWith('.jpeg'))) {
                        tmp.$api.openMsg('僅支援上傳 .jpg 或 .jpeg 文件!', 'error')
                        return
                    }

                    // 壓縮圖片
                    const compressedFile = await this.compressImage(file);
                    console.log('壓縮後的文件大小=' + compressedFile.size);

                    // 检查文件大小是否超过限制
                    if (compressedFile.size > 3 * 1024 * 1024) {
                        // 1MB = 1024 * 1024 bytes
                        tmp.$api.openMsg('文件大小不能超过 3MB!', 'error')
                        this.$refs.fileInput2.value = ''
                        return
                    }
                    console.log('Filename=' + compressedFile.name)
                    // 使用Promise處理文件讀取
                    // this.byteArray = await this.readFileAsBytes(file);
                    this.photoBase64_2 = await this.readFileAsBase64(compressedFile)
                    console.log('匯入客戶資料! photoBase64_2=' + this.photoBase64_2)
                } catch (e) {
                    console.log('載入檔案發生異常! error=' + e)
                }
            },
            async handleFileUpload3(event) {
                let tmp = this
                console.log('開始載入檔案!')
                try {
                    const file = event.target.files[0] // 獲取上傳的文件
                    // 檢查文件擴展名
                    if (file == null || (!file.name.toLowerCase().endsWith('.jpg') && !file.name.toLowerCase().endsWith('.jpeg'))) {
                        tmp.$api.openMsg('僅支援上傳 .jpg 或 .jpeg 文件!', 'error')
                        return
                    }
                    // 壓縮圖片
                    const compressedFile = await this.compressImage(file);
                    console.log('壓縮後的文件大小=' + compressedFile.size);

                    // 检查文件大小是否超过限制
                    if (compressedFile.size > 3 * 1024 * 1024) {
                        // 1MB = 1024 * 1024 bytes
                        tmp.$api.openMsg('文件大小不能超过 3MB!', 'error')
                        this.$refs.fileInput3.value = ''
                        return
                    }
                    console.log('Filename=' + compressedFile.name)
                    // 使用Promise處理文件讀取
                    // this.byteArray = await this.readFileAsBytes(file);
                    this.photoBase64_3 = await this.readFileAsBase64(compressedFile)
                    console.log('匯入客戶資料! photoBase64_3=' + this.photoBase64_3)
                } catch (e) {
                    console.log('載入檔案發生異常! error=' + e)
                }
            },
            compressImage(file) {
                return new Promise((resolve, reject) => {
                    const img = new Image();
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        img.src = e.target.result;
                        img.onload = () => {
                            // 檢查文件大小，小於2MB的文件不壓縮
                            if (file.size <= 1 * 1024 * 1024) {
                                resolve(file); // 直接返回原始文件，不做壓縮
                                return;
                            }
                            const canvas = document.createElement('canvas');
                            const ctx = canvas.getContext('2d');
                            // 設置壓縮比例（如果文件大於2MB則壓縮）
                            const scale = Math.min(1, 1 * 1024 * 1024 / file.size);
                            canvas.width = img.width * scale;
                            canvas.height = img.height * scale;
                            // 在 canvas 上繪製圖片，根據壓縮比例縮小圖片尺寸
                            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
                            // 將 canvas 轉換成 Blob 對象（壓縮到JPEG格式）
                            canvas.toBlob((blob) => {
                                if (blob) {
                                    resolve(blob); // 返回壓縮後的 Blob
                                } else {
                                    reject(new Error('圖片壓縮失敗')); // 處理壓縮失敗情況
                                }
                            }, 'image/jpeg', 0.8); // 調整壓縮質量 (0.8 可以根據需求調整)
                        };
                        img.onerror = () => {
                            reject(new Error('圖片加載失敗'));
                        };
                    };
                    reader.onerror = (error) => {
                        reject(error); // 文件讀取失敗時的錯誤處理
                    };
                    reader.readAsDataURL(file); // 讀取文件，生成 base64 URL
                });
            },
            readFileAsBytes(file) {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader()
                    reader.onload = () => {
                        resolve(new Uint8Array(reader.result)) // 将 ArrayBuffer 转换为 Uint8Array
                    }
                    reader.onerror = (error) => {
                        reject(error)
                    }
                    reader.readAsArrayBuffer(file) // 读取文件为 ArrayBuffer
                })
            },
            readFileAsBase64(file) {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader()
                    reader.onload = () => {
                        resolve(reader.result.split(',')[1]) // 去掉前缀 'data:image/jpeg;base64,'
                    }
                    reader.onerror = (error) => {
                        reject(error)
                    }
                    reader.readAsDataURL(file) // 读取文件为 DataURL
                })
            },
            // 清空文件上传 input 元素的值
            clearFileInput() {
                this.$refs.fileInput1.value = ''
                this.$refs.fileInput2.value = ''
                this.$refs.fileInput3.value = ''
            },
            handleCompanyChange(value) {
                //公司下拉選單
                console.log('Selected company ID:', value)
                //系統權限，
                if (!this.isCompanyid) {
                    this.companyId = value
                    this.branchId = null
                    this.searchBranchList()
                }
                this.assetsModelTypeId = null
                this.serviceLevelTypeId = null
                this.assetsOptions = []
                this.serviceLevelOptions = []
                this.searchAssetsModelTypeList()
                this.searchServiceLevelTypeList()
            },
            async searchAssetsModelTypeList() {
                //依據條件查詢全部資產類型資料
                let tmp = this
                let url = 'company/search/assetsModel'
                let parameter = {
                    companyId: this.companyId,
                    assetsModel: this.assetsModel,
                }
                //取得列表資料
                let responseData = await tmp.$api.doPostByToken(parameter, url, this.token)
                if (responseData != null) {
                    tmp.assetsOptions = responseData
                    console.log('取得下拉選單資料 assetsOptions=' + tmp.assetsOptions)
                }
            },
            async searchServiceLevelTypeList() {
                //依據條件查詢全部資產服務等級資料
                let tmp = this
                let url = 'company/search/serviceLevel'
                let parameter = {
                    companyId: this.companyId,
                    serviceLevel: this.serviceLevel,
                }
                //取得列表資料
                let responseData = await tmp.$api.doPostByToken(parameter, url, this.token)
                if (responseData != null) {
                    tmp.serviceLevelOptions = responseData
                    console.log('取得下拉選單資產服務等級資料 serviceLevelOptions=', tmp.serviceLevelOptions)
                }
            },
            validateUsername() {
                // 使用正則表達式檢查用戶名是否僅包含英數字大小寫以及 "-" 和 "_"
                // this.userName = this.userName.replace(/[^a-zA-Z0-9-_]/g, '');
                this.userName = this.$api.validateInput(this.userName, 'userName')
            },
            validatePassword() {
                console.log('validatePassword password=' + this.password)
                if (this.password == null || this.password == '') {
                    return false
                }
                // 密码长度至少为8个字符
                if (this.password.length < 8) {
                    this.$api.openMsg('密碼長度至少為8個字符!', 'error')
                    console.log('密碼長度至少為8個字符')
                    return false
                }
                // 包含至少一个数字
                if (!/\d/.test(this.password)) {
                    this.$api.openMsg('密碼需包含至少一個數字!', 'error')
                    console.log('密碼需包含至少一個數字')
                    return false
                }
                // 包含至少一个小写字母
                if (!/[a-z]/.test(this.password)) {
                    this.$api.openMsg('密碼需包含至少一個小寫字母!', 'error')
                    console.log('密碼需包含至少一個小寫字母')
                    return false
                }
                // 包含至少一个大写字母
                if (!/[A-Z]/.test(this.password)) {
                    this.$api.openMsg('密碼需包含至少一個大寫字母!', 'error')
                    console.log('密碼需包含至少一個大寫字母')
                    return false
                }
                // 包含至少一个特殊字符
                if (!/[^a-zA-Z0-9]/.test(this.password)) {
                    this.$api.openMsg('密碼需包含至少一個特殊字符!', 'error')
                    console.log('密碼需包含至少一個特殊字符')
                    return false
                }
                return true
            },
            handleServiceLevelTypeChange(value) {
                //選擇資產服務等級下拉觸發
                console.log('選擇資產服務等級下拉觸發 serviceLevelTypeId=' + value
                )
                if (value == null || value == '') {
                    this.serviceLevelTypeId = null;
                    this.discountPercentage = 0;
                } else {
                    this.processDiscount();
                }
            },
            validateErrorCount() {
                //this.errorCount = this.errorCount.replace(/[^\d]/g, '');
                this.errorCount = this.$api.validateInput(this.errorCount, 'errorCount')
            },
            validatePhoneNumber() {
                this.phoneNumber = this.$api.validateInput(this.phoneNumber, 'phone')
                this.contactPhoneNumber = this.phoneNumber
            },
            validateContactPhoneNumber() {
                this.contactPhoneNumber = this.$api.validateInput(this.contactPhoneNumber, 'contactPhone')
            },
            validateAge() {
                this.age = this.$api.validateInput(this.age, 'age')
            },
            validateName() {
                this.nickName = this.$api.validateInput(this.nickName, 'name')
                this.contactName = this.nickName;
            },
            validateContactName() {
                this.contactName = this.$api.validateInput(this.contactName, 'contactName')
            },
            validateIdentifier() {
                this.identifier = this.$api.validateInput(this.identifier, 'identifier')
            },
            validateEmail() {
                this.mail = this.$api.validateInput(this.mail, 'email')
            },
            formatAmount() {
                if (this.purchaseAmount != null) {
                    this.purchaseAmount = Number(this.purchaseAmount.replace(/,/g, '')).toLocaleString();
                }
            },
            clearFormat() {
                this.purchaseAmount = this.purchaseAmount.replace(/,/g, '');
            },
        },
    }
</script>

<style scoped>
    img {
        width: 50px;
        height: 50px;
        /*height: auto;*/
        margin: 10px 0px 0px 0px;
    }

    /**日期選擇器**/
    .selectInpud .el-date-editor {
        width: 100%;
    }
</style>
